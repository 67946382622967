const translations = {
  title: {
    new: 'Yeni Cüzdan',
    edit: 'Cüzdanı Düzenle'
  },
  fields: {
    name: {
      label: 'Cüzdan Adı',
      placeholder: 'Cüzdan adı girin'
    },
    initialBalance: {
      label: 'Başlangıç Bakiyesi',
      placeholder: 'Başlangıç bakiyesi girin'
    },
    currency: {
      label: 'Para Birimi',
      options: {
        try: 'TRY',
        usd: 'USD',
        eur: 'EUR',
        gbp: 'GBP'
      }
    },
    colorTheme: {
      label: 'Renk Teması',
      options: {
        indigo: 'İndigo',
        emerald: 'Zümrüt',
        amber: 'Amber'
      }
    }
  },
  sharing: {
    title: 'Cüzdanı Paylaş',
    hideOptions: 'Paylaşım Seçeneklerini Gizle',
    addMember: {
      label: 'Üye Ekle',
      placeholder: 'E-posta adresi girin'
    },
    currentMembers: 'Mevcut Üyeler',
    roles: {
      title: 'Erişim Seviyesi',
      owner: 'Sahip',
      editor: 'Düzenleyebilir',
      viewer: 'Sadece görüntüleyebilir'
    },
    actions: {
      remove: 'Kaldır',
      confirmRemove: 'Bu üyeyi kaldırmak istediğinizden emin misiniz?',
      sendInvitation: 'Davet Gönder'
    },
    messages: {
      invitationSent: 'Davet başarıyla gönderildi',
      invitationError: 'Davet gönderilirken hata oluştu',
      invalidInvitation: 'Geçersiz davet bağlantısı',
      invitationAccepted: 'Davet başarıyla kabul edildi',
      acceptError: 'Davet kabul edilirken hata oluştu',
      processingInvitation: 'Davet İşleniyor',
      pleaseWait: 'Lütfen davetiniz işlenirken bekleyin...'
    }
  },
  buttons: {} // Common translations will be merged here
};

export default translations;
