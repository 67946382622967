import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { creditCardsCollection } from '../lib/collections';
import { CreditCard } from '../types/creditCard';

export const createCreditCard = async (
  userId: string,
  data: Omit<CreditCard, 'id'>
): Promise<string> => {
  const creditCardData = {
    ...data,
    userId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(creditCardsCollection, creditCardData);
  return docRef.id;
};

export const getUserCreditCards = async (userId: string): Promise<CreditCard[]> => {
  const q = query(
    creditCardsCollection,
    where('userId', '==', userId)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as CreditCard));
};

export const updateCreditCard = async (
  creditCardId: string,
  data: Partial<CreditCard>
): Promise<void> => {
  const creditCardRef = doc(creditCardsCollection, creditCardId);
  await updateDoc(creditCardRef, {
    ...data,
    updatedAt: new Date().toISOString()
  });
};

export const deleteCreditCard = async (creditCardId: string): Promise<void> => {
  await deleteDoc(doc(creditCardsCollection, creditCardId));
};
