import { FirebaseError } from 'firebase/app';
import en from '../locales/en';

export const getAuthErrorMessage = (error: unknown): string => {
  console.error('Original error:', error); // Log the original error for debugging

  // First check if it's a Firebase error
  if (error instanceof FirebaseError) {
    const errorCode = error.code;
    console.log('Firebase error code:', errorCode);

    // Direct mapping for Firebase auth errors
    const firebaseErrors: Record<string, string> = {
      'auth/email-already-in-use': en.auth.errors['auth/email-already-in-use'],
      'auth/invalid-credential': en.auth.errors['auth/invalid-credential'],
      'auth/wrong-password': en.auth.errors['auth/invalid-credential'],
      'auth/user-not-found': en.auth.errors['auth/invalid-credential'],
      'auth/invalid-email': en.auth.errors['auth/invalid-email'],
      'auth/user-disabled': en.auth.errors['auth/user-disabled'],
      'auth/too-many-requests': en.auth.errors['auth/too-many-requests'],
      'auth/operation-not-allowed': en.auth.errors['auth/operation-not-allowed'],
      'auth/network-request-failed': en.auth.errors['auth/network-request-failed'],
      'auth/popup-closed-by-user': en.auth.errors['auth/popup-closed-by-user'],
      'auth/unauthorized-domain': en.auth.errors['auth/unauthorized-domain'],
      'auth/expired-action-code': en.auth.errors['auth/expired-action-code'],
      'auth/invalid-action-code': en.auth.errors['auth/invalid-action-code'],
      'auth/weak-password': en.auth.errors['auth/weak-password']
    };

    // If we have a direct mapping for this error code, use it
    if (errorCode && firebaseErrors[errorCode]) {
      return firebaseErrors[errorCode];
    }

    // If it's a Firebase error but we don't have a specific mapping,
    // try to extract a meaningful message from the error
    return error.message || en.auth.errors.default;
  }

  // Handle custom user data errors
  if (error instanceof Error) {
    console.error('Non-Firebase error:', error.message);
    
    // Map of custom error messages
    const customErrors: Record<string, string> = {
      'User data not found': en.auth.errors['user-data-not-found'],
      'Failed to create user data': en.auth.errors['user-data-creation-failed'],
      'User document not created properly': en.auth.errors['user-data-creation-failed']
    };

    // If we have a mapping for this error message, use it
    if (customErrors[error.message]) {
      return customErrors[error.message];
    }
    
    return error.message || en.auth.errors.default;
  }
  
  // If we get here, we don't know what kind of error this is
  console.error('Unknown error type:', error);
  return en.auth.errors.default;
};

export const handleAuthError = (error: unknown): never => {
  try {
    const errorMessage = getAuthErrorMessage(error);
    
    // Log the error for debugging
    console.error('Auth error:', {
      originalError: error,
      handledMessage: errorMessage
    });
    
    // Throw a new error with the translated message
    throw new Error(errorMessage);
  } catch (e) {
    // If something goes wrong in our error handling,
    // make sure we still throw a meaningful error
    console.error('Error in error handler:', e);
    if (e instanceof Error) {
      throw e; // Re-throw if it's already an Error
    }
    throw new Error(en.auth.errors.default);
  }
};
