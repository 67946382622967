import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Wallet } from '../../types';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { getTranslations } from '../../locales/components/walletModal';
import WalletSharingSection from './WalletSharingSection';

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<Wallet, 'id'>) => void;
  initialData?: Wallet;
}

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, onClose, onSubmit, initialData }) => {
  const { language } = useLanguage();
  const { user } = useAuth();
  const translations = getTranslations(language);

  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    balance: initialData?.balance || 0,
    currency: initialData?.currency || user?.settings?.currency || 'TRY',
    color: initialData?.color || 'indigo',
    members: initialData?.members || {}
  });

  if (!isOpen || !user) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const timestamp = new Date().toISOString();
    
    const completeData: Omit<Wallet, 'id'> = {
      ...formData,
      userId: user.id,
      createdAt: initialData?.createdAt || timestamp,
      updatedAt: timestamp
    };

    onSubmit(completeData);
    onClose();
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? '' : 'pointer-events-none'}`}>
      {/* Backdrop */}
      <div 
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isOpen ? 'opacity-50' : 'opacity-0'
        }`} 
        onClick={onClose}
      />
      
      {/* Drawer */}
      <div className={`fixed inset-y-0 right-0 w-[500px] bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <form onSubmit={handleSubmit} className="h-full flex flex-col">
          {/* Header */}
          <div className="sticky top-0 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-6 py-4 flex items-center justify-between z-10">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {initialData ? translations.title.edit : translations.title.new}
            </h2>
            <button 
              type="button"
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto">
            <div className="space-y-6 p-6">
              {/* Basic Information */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {translations.fields.name.label}
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full px-3 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 text-gray-900 dark:text-white"
                    placeholder={translations.fields.name.placeholder}
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {translations.fields.initialBalance.label}
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    value={formData.balance}
                    onChange={e => setFormData(prev => ({ ...prev, balance: parseFloat(e.target.value) }))}
                    className="w-full px-3 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 text-gray-900 dark:text-white"
                    placeholder={translations.fields.initialBalance.placeholder}
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {translations.fields.currency.label}
                  </label>
                  <select
                    value={formData.currency}
                    onChange={e => setFormData(prev => ({ ...prev, currency: e.target.value }))}
                    className="w-full px-3 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 text-gray-900 dark:text-white"
                  >
                    <option value="TRY">{translations.fields.currency.options.try}</option>
                    <option value="USD">{translations.fields.currency.options.usd}</option>
                    <option value="EUR">{translations.fields.currency.options.eur}</option>
                    <option value="GBP">{translations.fields.currency.options.gbp}</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {translations.fields.colorTheme.label}
                  </label>
                  <select
                    value={formData.color}
                    onChange={e => setFormData(prev => ({ ...prev, color: e.target.value }))}
                    className="w-full px-3 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 text-gray-900 dark:text-white"
                  >
                    <option value="indigo">{translations.fields.colorTheme.options.indigo}</option>
                    <option value="emerald">{translations.fields.colorTheme.options.emerald}</option>
                    <option value="amber">{translations.fields.colorTheme.options.amber}</option>
                  </select>
                </div>
              </div>

              {/* Sharing Section */}
              {initialData && (
                <div className="pt-6 border-t border-gray-200 dark:border-gray-700">
                  <WalletSharingSection wallet={initialData} />
                </div>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <div className="sticky bottom-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 px-6 py-4 flex justify-end z-10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
            >
              {translations.buttons.cancel}
            </button>
            <button
              type="submit"
              className="ml-3 px-4 py-2 bg-indigo-600 text-white rounded-lg text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400"
            >
              {initialData ? translations.buttons.save : translations.buttons.add}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WalletModal;
