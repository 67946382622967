export const translations = {
  title: {
    new: 'Add New Subscription',
    edit: 'Edit Subscription'
  },
  search: {
    placeholder: 'Search services...'
  },
  categories: {
    all: 'All',
    entertainment: 'Entertainment',
    productivity: 'Productivity',
    business: 'Business',
    education: 'Education',
    lifestyle: 'Lifestyle',
    other: 'Other'
  },
  customSubscription: {
    button: 'Add Custom Subscription',
    name: 'Service Name',
    description: 'Description'
  },
  fields: {
    amount: 'Amount',
    currency: {
      label: 'Currency',
      options: {
        usd: 'USD - US Dollar',
        eur: 'EUR - Euro',
        gbp: 'GBP - British Pound',
        try: 'TRY - Turkish Lira'
      }
    },
    billingCycle: {
      label: 'Billing Cycle',
      options: {
        monthly: 'Monthly',
        yearly: 'Yearly'
      }
    },
    nextBillingDate: 'Next Billing Date',
    creditCard: {
      label: 'Credit Card',
      placeholder: 'Select a credit card'
    },
    wallet: {
      label: 'Wallet',
      placeholder: 'Select a wallet'
    },
    expenseCategories: {
      label: 'Expense Categories',
      placeholder: 'Select expense categories'
    }
  },
  buttons: {
    cancel: 'Cancel',
    save: 'Save Changes',
    add: 'Add Subscription'
  }
};
