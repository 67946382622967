import React, { createContext, useContext, useState, useEffect } from 'react';
import { Label } from '../types';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';
import * as labelService from '../services/labels';

type LabelInput = Pick<Label, 'name' | 'color'>;

interface LabelContextType {
  labels: Label[];
  isLoading: boolean;
  error: string | null;
  addLabel: (label: LabelInput) => Promise<string>;
  updateLabel: (id: string, label: Partial<LabelInput>) => Promise<void>;
  deleteLabel: (id: string) => Promise<void>;
  incrementLabelUsage: (id: string) => Promise<void>;
  decrementLabelUsage: (id: string) => Promise<void>;
  findOrCreateLabel: (name: string) => Promise<string>;
}

const LabelContext = createContext<LabelContextType | undefined>(undefined);

export const useLabels = () => {
  const context = useContext(LabelContext);
  if (!context) throw new Error('useLabels must be used within LabelProvider');
  return context;
};

export const LabelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [labels, setLabels] = useState<Label[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const { showNotification } = useNotification();

  useEffect(() => {
    const loadLabels = async () => {
      if (!user) {
        setLabels([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const userLabels = await labelService.getUserLabels(user.id);
        setLabels(userLabels);
      } catch (err) {
        console.error('Error loading labels:', err);
        setError(err instanceof Error ? err.message : 'Failed to load labels');
        showNotification('error', 'Failed to load labels');
      } finally {
        setIsLoading(false);
      }
    };

    loadLabels();
  }, [user, showNotification]);

  const addLabel = async (labelData: LabelInput) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const timestamp = new Date().toISOString();
      const completeData = {
        ...labelData,
        userId: user.id,
        createdAt: timestamp,
        updatedAt: timestamp
      };

      const labelId = await labelService.createLabel(user.id, completeData);
      const newLabel = {
        id: labelId,
        ...completeData,
        transactionCount: 0
      };
      setLabels(current => [...current, newLabel]);
      showNotification('success', 'Label added successfully');
      return labelId;
    } catch (error) {
      console.error('Error adding label:', error);
      showNotification('error', 'Failed to add label');
      throw error;
    }
  };

  const updateLabel = async (id: string, updates: Partial<LabelInput>) => {
    try {
      const timestamp = new Date().toISOString();
      const updateData = {
        ...updates,
        updatedAt: timestamp
      };

      await labelService.updateLabel(id, updateData);
      setLabels(current =>
        current.map(label =>
          label.id === id ? { ...label, ...updates, updatedAt: timestamp } : label
        )
      );
      showNotification('success', 'Label updated successfully');
    } catch (error) {
      console.error('Error updating label:', error);
      showNotification('error', 'Failed to update label');
      throw error;
    }
  };

  const deleteLabel = async (id: string) => {
    try {
      await labelService.deleteLabel(id);
      setLabels(current => current.filter(label => label.id !== id));
      showNotification('success', 'Label deleted successfully');
    } catch (error) {
      console.error('Error deleting label:', error);
      showNotification('error', 'Failed to delete label');
      throw error;
    }
  };

  const incrementLabelUsage = async (id: string) => {
    try {
      await labelService.incrementLabelUsage(id);
      setLabels(current =>
        current.map(label =>
          label.id === id
            ? { ...label, transactionCount: (label.transactionCount || 0) + 1 }
            : label
        )
      );
    } catch (error) {
      console.error('Error incrementing label usage:', error);
      showNotification('error', 'Failed to update label usage');
      throw error;
    }
  };

  const decrementLabelUsage = async (id: string) => {
    try {
      await labelService.decrementLabelUsage(id);
      setLabels(current =>
        current.map(label =>
          label.id === id
            ? { ...label, transactionCount: Math.max((label.transactionCount || 0) - 1, 0) }
            : label
        )
      );
    } catch (error) {
      console.error('Error decrementing label usage:', error);
      showNotification('error', 'Failed to update label usage');
      throw error;
    }
  };

  const findOrCreateLabel = async (name: string): Promise<string> => {
    if (!user) throw new Error('User not authenticated');

    try {
      // First, try to find an existing label
      const existingLabel = await labelService.findLabelByName(user.id, name);
      if (existingLabel) {
        return existingLabel.id;
      }

      // If no existing label found, create a new one
      return addLabel({
        name,
        color: 'indigo' // Default color
      });
    } catch (error) {
      console.error('Error finding or creating label:', error);
      showNotification('error', 'Failed to process label');
      throw error;
    }
  };

  return (
    <LabelContext.Provider value={{
      labels,
      isLoading,
      error,
      addLabel,
      updateLabel,
      deleteLabel,
      incrementLabelUsage,
      decrementLabelUsage,
      findOrCreateLabel
    }}>
      {children}
    </LabelContext.Provider>
  );
};
