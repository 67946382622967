import React, { useState, useEffect, useMemo } from 'react';
import { X, Search, Plus } from 'lucide-react';
import { Subscription, SubscriptionService, SubscriptionCategory } from '../../types/subscription';
import { useCreditCards } from '../../context/CreditCardContext';
import { useWallets } from '../../context/WalletContext';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { subscriptionList } from '../../data/subscriptions';
import { getUserCategories } from '../../services/categories';
import { Category } from '../../types/category';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<Subscription, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => void;
  initialData?: Subscription;
  isSubmitting?: boolean;
  error?: string | null;
}

type FormData = Omit<Subscription, 'id' | 'userId' | 'createdAt' | 'updatedAt'>;

const categories: readonly SubscriptionCategory[] = [
  'all',
  'entertainment',
  'productivity',
  'business',
  'education',
  'lifestyle',
  'other'
] as const;

const isValidCategory = (category: string): category is SubscriptionCategory => {
  return categories.includes(category as SubscriptionCategory);
};

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  isSubmitting = false,
  error: externalError = null
}) => {
  const { creditCards } = useCreditCards();
  const { wallets } = useWallets();
  const { t } = useLanguage();
  const { user } = useAuth();

  const getDefaultFormData = (user: any): FormData => ({
    name: '',
    description: '',
    amount: 0,
    currency: user?.settings?.currency || 'USD',
    billingCycle: 'monthly' as const,
    nextBillingDate: new Date().toISOString().split('T')[0],
    creditCardId: '',
    walletId: '',
    logo: '',
    color: 'indigo',
    isCustom: false,
    expenseCategories: []
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<SubscriptionCategory>('all');
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [hoveredService, setHoveredService] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>(getDefaultFormData(user));
  const [expenseCategories, setExpenseCategories] = useState<Category[]>([]);
  const [internalError, setInternalError] = useState<string | null>(null);
  const [defaultDescription, setDefaultDescription] = useState<string>('');
  const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);

  // Combine external and internal errors
  const error = externalError || internalError;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (!user?.id) {
          console.error('No user ID available');
          setInternalError('User not authenticated');
          return;
        }

        console.log('Fetching categories for user:', user.id);
        const categories = await getUserCategories(user.id);
        console.log('Fetched categories:', categories);
        const expenseCats = categories.filter(cat => cat.type === 'expense');
        console.log('Filtered expense categories:', expenseCats);
        setExpenseCategories(expenseCats);
        setInternalError(null);
      } catch (err) {
        console.error('Error fetching expense categories:', err);
        setInternalError('Failed to load expense categories');
      }
    };
    
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen, user]);

  // Reset form when drawer opens/closes
  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setFormData(initialData);
        setSelectedService(initialData.name);
        setIsDescriptionEdited(true); // Assume description is edited if it's initial data
      } else {
        setFormData(getDefaultFormData(user));
        setSelectedService(null);
        setIsDescriptionEdited(false);
      }
      setSearchQuery('');
      setSelectedCategory('all');
      setInternalError(null);
      setDefaultDescription('');
    }
  }, [isOpen, initialData, user]);

  const filteredServices = useMemo(() => {
    return subscriptionList.filter(service => {
      const matchesSearch = service.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || service.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [searchQuery, selectedCategory]);

  const handleCategoryChange = (category: string) => {
    if (isValidCategory(category)) {
      setSelectedCategory(category);
    }
  };

  const handleServiceSelect = (service: SubscriptionService) => {
    setSelectedService(service.name);
    setDefaultDescription(service.description || '');
    setIsDescriptionEdited(false);
    setFormData(prev => ({
      ...prev,
      name: service.name,
      description: service.description || '',
      logo: service.logo,
      color: service.color || 'indigo',
      isCustom: false
    }));
  };

  const handleDescriptionChange = (description: string) => {
    setIsDescriptionEdited(true);
    setFormData(prev => ({
      ...prev,
      description
    }));
  };

  const handleExpenseCategoryToggle = (categoryId: string) => {
    setFormData(prev => {
      const currentCategories = prev.expenseCategories || [];
      const newCategories = currentCategories.includes(categoryId)
        ? currentCategories.filter(id => id !== categoryId)
        : [...currentCategories, categoryId];
      return { ...prev, expenseCategories: newCategories };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleClose = () => {
    setFormData(getDefaultFormData(user));
    setSelectedService(null);
    setInternalError(null);
    setIsDescriptionEdited(false);
    setDefaultDescription('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={handleClose} />
      <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
        <div className="pointer-events-auto w-screen max-w-2xl">
          <div className="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
            <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {initialData ? t('components.subscriptionModal.title.edit') : t('components.subscriptionModal.title.new')}
              </h2>
              <button 
                onClick={handleClose}
                disabled={isSubmitting}
                className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 disabled:opacity-50"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto p-6">
              {error && (
                <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-400 rounded-lg">
                  {error}
                </div>
              )}

              {!selectedService && !formData.isCustom && (
                <div className="mb-6">
                  <div className="flex gap-4 mb-4">
                    <div className="relative flex-1">
                      <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        placeholder={t('components.subscriptionModal.search.placeholder')}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                      />
                    </div>
                    <select
                      value={selectedCategory}
                      onChange={e => handleCategoryChange(e.target.value)}
                      className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                    >
                      {categories.map(category => (
                        <option key={category} value={category}>
                          {t(`components.subscriptionModal.categories.${category}`)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-4">
                    {filteredServices.map(service => (
                      <button
                        key={service.name}
                        onClick={() => handleServiceSelect(service)}
                        onMouseEnter={() => setHoveredService(service.name)}
                        onMouseLeave={() => setHoveredService(null)}
                        className="relative flex flex-col items-center p-4 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors group"
                      >
                        <img src={service.logo} alt={service.name} className="w-12 h-12 mb-2 object-contain" />
                        <span className="text-sm font-medium text-gray-900 dark:text-white text-center">{service.name}</span>
                        <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                          {t(`components.subscriptionModal.categories.${service.category}`)}
                        </span>
                        {hoveredService === service.name && service.description && (
                          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                            <p className="text-white text-sm px-4 text-center">{service.description}</p>
                          </div>
                        )}
                      </button>
                    ))}
                  </div>

                  <button
                    onClick={() => setFormData(prev => ({ ...prev, isCustom: true }))}
                    className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-indigo-600 dark:text-indigo-400 bg-indigo-50 dark:bg-indigo-900/50 rounded-lg hover:bg-indigo-100 dark:hover:bg-indigo-900/30"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    {t('components.subscriptionModal.customSubscription.button')}
                  </button>
                </div>
              )}

              {(selectedService || formData.isCustom) && (
                <form onSubmit={handleSubmit} className="space-y-4">
                  {formData.isCustom && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        {t('components.subscriptionModal.customSubscription.name')}
                      </label>
                      <input
                        type="text"
                        value={formData.name}
                        onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                        required
                      />
                    </div>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {t('components.subscriptionModal.customSubscription.description')}
                      {!formData.isCustom && !isDescriptionEdited && defaultDescription && (
                        <span className="ml-2 text-xs text-gray-500">(Default)</span>
                      )}
                    </label>
                    <textarea
                      value={formData.description}
                      onChange={e => handleDescriptionChange(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                      rows={3}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        {t('components.subscriptionModal.fields.amount')}
                      </label>
                      <input
                        type="number"
                        value={formData.amount}
                        onChange={e => setFormData(prev => ({ ...prev, amount: parseFloat(e.target.value) }))}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        {t('components.subscriptionModal.fields.currency.label')}
                      </label>
                      <select
                        value={formData.currency}
                        onChange={e => setFormData(prev => ({ ...prev, currency: e.target.value }))}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                        required
                      >
                        <option value="USD">{t('components.subscriptionModal.fields.currency.options.usd')}</option>
                        <option value="EUR">{t('components.subscriptionModal.fields.currency.options.eur')}</option>
                        <option value="GBP">{t('components.subscriptionModal.fields.currency.options.gbp')}</option>
                        <option value="TRY">{t('components.subscriptionModal.fields.currency.options.try')}</option>
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        {t('components.subscriptionModal.fields.billingCycle.label')}
                      </label>
                      <select
                        value={formData.billingCycle}
                        onChange={e => setFormData(prev => ({ ...prev, billingCycle: e.target.value as 'monthly' | 'yearly' }))}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                        required
                      >
                        <option value="monthly">{t('components.subscriptionModal.fields.billingCycle.options.monthly')}</option>
                        <option value="yearly">{t('components.subscriptionModal.fields.billingCycle.options.yearly')}</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        {t('components.subscriptionModal.fields.nextBillingDate')}
                      </label>
                      <input
                        type="date"
                        value={formData.nextBillingDate}
                        onChange={e => setFormData(prev => ({ ...prev, nextBillingDate: e.target.value }))}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {t('components.subscriptionModal.fields.creditCard.label')}
                    </label>
                    <select
                      value={formData.creditCardId}
                      onChange={e => setFormData(prev => ({ ...prev, creditCardId: e.target.value }))}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                      required
                    >
                      <option value="">{t('components.subscriptionModal.fields.creditCard.placeholder')}</option>
                      {creditCards.map(card => (
                        <option key={card.id} value={card.id}>
                          {card.name} (**** {card.lastFourDigits})
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {t('components.subscriptionModal.fields.wallet.label')}
                    </label>
                    <select
                      value={formData.walletId}
                      onChange={e => setFormData(prev => ({ ...prev, walletId: e.target.value }))}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                      required
                    >
                      <option value="">{t('components.subscriptionModal.fields.wallet.placeholder')}</option>
                      {wallets.map(wallet => (
                        <option key={wallet.id} value={wallet.id}>
                          {wallet.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {t('components.subscriptionModal.fields.expenseCategories.label')}
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                      {expenseCategories.map(category => (
                        <div
                          key={category.id}
                          className={`flex items-center p-2 border rounded-lg cursor-pointer ${
                            formData.expenseCategories.includes(category.id)
                              ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900/50'
                              : 'border-gray-300 dark:border-gray-600'
                          }`}
                          onClick={() => handleExpenseCategoryToggle(category.id)}
                        >
                          <div className={`w-4 h-4 mr-2 flex-shrink-0 rounded border ${
                            formData.expenseCategories.includes(category.id)
                              ? 'bg-indigo-500 border-indigo-500'
                              : 'border-gray-300 dark:border-gray-600'
                          }`}>
                            {formData.expenseCategories.includes(category.id) && (
                              <svg className="w-4 h-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                              </svg>
                            )}
                          </div>
                          <span className="text-sm">{category.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </form>
              )}
            </div>

            {(selectedService || formData.isCustom) && (
              <div className="flex justify-end gap-3 px-6 py-4 border-t border-gray-200 dark:border-gray-700">
                <button
                  type="button"
                  onClick={handleClose}
                  disabled={isSubmitting}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
                >
                  {t('components.subscriptionModal.buttons.cancel')}
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      {t('components.subscriptionModal.buttons.submitting')}
                    </div>
                  ) : initialData ? (
                    t('components.subscriptionModal.buttons.save')
                  ) : (
                    t('components.subscriptionModal.buttons.add')
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
