export const categoryModal = {
  title: {
    new: 'Kategori Ekle',
    edit: 'Kategoriyi Düzenle'
  },
  labels: {
    icon: 'İkon',
    color: 'Renk',
    name: 'İsim',
    type: 'Tür'
  },
  types: {
    expense: 'Gider',
    income: 'Gelir'
  },
  buttons: {} // Common translations will be merged here
};
