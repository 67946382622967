const translations = {
  sharing: {
    hideOptions: 'Hide sharing options',
    shareWallet: 'Share Wallet',
    addMember: 'Add Member',
    emailPlaceholder: 'Enter email address',
    sendInvitation: 'Send Invitation',
    currentMembers: 'Current Members',
    confirmRemove: 'Are you sure you want to remove this member?',
    remove: 'Remove',
    roles: {
      owner: 'Owner',
      editor: 'Editor',
      viewer: 'Viewer'
    }
  },
  errors: {
    shareWallet: 'Failed to share wallet',
    removeMember: 'Failed to remove member'
  }
};

export default translations;
