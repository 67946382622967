export default {
  title: 'Hesabı Sil',
  message: 'Bu işlem geri alınamaz. Tüm verileriniz kalıcı olarak silinecektir. Onaylamak için lütfen "delete" yazın.',
  confirmText: 'delete',
  buttons: {
    cancel: 'İptal',
    delete: 'Hesabı Sil'
  },
  error: {
    invalidConfirmation: 'Lütfen onaylamak için "delete" yazın'
  }
};
