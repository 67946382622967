const translations = {
  title: 'Wallets',
  subtitle: 'Manage your wallets',
  new: 'New Wallet',
  allWallets: 'All Wallets',
  balance: 'Balance',
  actions: {
    edit: 'Edit',
    delete: 'Delete',
    share: 'Share'
  },
  types: {
    cash: 'Cash',
    bank: 'Bank Account',
    investment: 'Investment',
    other: 'Other'
  },
  fields: {
    name: 'Name',
    type: 'Type',
    currency: 'Currency',
    initialBalance: 'Initial Balance',
    currentBalance: 'Current Balance'
  },
  messages: {
    deleteConfirmation: 'Are you sure you want to delete this wallet?',
    deleteSuccess: 'Wallet deleted successfully',
    createSuccess: 'Wallet created successfully',
    updateSuccess: 'Wallet updated successfully'
  }
};

export default translations;
