const translations = {
  loading: 'Loading...',
  error: 'Error',
  success: 'Success',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  edit: 'Edit',
  create: 'Create',
  update: 'Update',
  submit: 'Submit',
  confirm: 'Confirm',
  selectAll: 'Select All',
  deselectAll: 'Deselect All',
  search: 'Search',
  filter: 'Filter',
  sort: 'Sort',
  actions: 'Actions',
  status: 'Status',
  type: 'Type',
  date: 'Date',
  amount: 'Amount',
  description: 'Description',
  category: 'Category',
  categories: 'Categories',
  name: 'Name',
  email: 'Email',
  password: 'Password',
  currency: 'Currency',
  balance: 'Balance',
  total: 'Total',
  settings: 'Settings',
  profile: 'Profile',
  logout: 'Logout',
  back: 'Back',
  close: 'Close',
  add: 'Add',
  remove: 'Remove',
  yes: 'Yes',
  no: 'No',
  or: 'or',
  and: 'and',
  all: 'All',
  none: 'None',
  other: 'Other',
  validation: {
    required: 'Required',
    optional: 'Optional'
  }
};

export default translations;
