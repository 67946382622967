const translations = {
  dueDate: 'Due {{date}}',
  installments: {
    format: '{{current}} / {{total}}',
    periods: {
      weekly: 'Weekly',
      monthly: 'Monthly'
    }
  },
  creditCard: 'Credit Card',
  edit: 'Edit',
  delete: 'Delete',
  confirmDelete: 'Are you sure you want to delete this transaction?',
  more: 'more'
};

export default translations;
