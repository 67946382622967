export const translations = {
  title: {
    new: 'Yeni Abonelik',
    edit: 'Aboneliği Düzenle'
  },
  search: {
    placeholder: 'Servislerde ara...'
  },
  categories: {
    all: 'Tümü',
    entertainment: 'Eğlence',
    productivity: 'Verimlilik',
    business: 'İş',
    education: 'Eğitim',
    lifestyle: 'Yaşam Tarzı',
    other: 'Diğer'
  },
  customSubscription: {
    button: 'Özel Abonelik Ekle',
    name: 'Servis Adı',
    description: 'Açıklama'
  },
  fields: {
    amount: 'Tutar',
    currency: {
      label: 'Para Birimi',
      options: {
        usd: 'USD - Amerikan Doları',
        eur: 'EUR - Euro',
        gbp: 'GBP - İngiliz Sterlini',
        try: 'TRY - Türk Lirası'
      }
    },
    billingCycle: {
      label: 'Fatura Döngüsü',
      options: {
        monthly: 'Aylık',
        yearly: 'Yıllık'
      }
    },
    nextBillingDate: 'Sonraki Fatura Tarihi',
    creditCard: {
      label: 'Kredi Kartı',
      placeholder: 'Kredi kartı seçin'
    },
    wallet: {
      label: 'Cüzdan',
      placeholder: 'Cüzdan seçin'
    },
    expenseCategories: {
      label: 'Gider Kategorileri',
      placeholder: 'Gider kategorilerini seçin'
    }
  },
  buttons: {
    cancel: 'İptal',
    save: 'Değişiklikleri Kaydet',
    add: 'Abonelik Ekle'
  }
};
