import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { CreditCard } from '../../types/creditCard';
import { useWallets } from '../../context/WalletContext';
import { useTransactions } from '../../context/TransactionContext';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { getComponentTranslations } from '../../locales';

interface CreditCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<CreditCard, 'id'>) => void;
  initialData?: CreditCard;
}

interface FormData {
  name: string;
  lastFourDigits: string;
  expiryDate: string;
  limit: number;
  balance: number;
  currency: string;
  type?: string;
  color?: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

const CreditCardModal: React.FC<CreditCardModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}): JSX.Element | null => {
  const { wallets } = useWallets();
  const { addTransaction } = useTransactions();
  const { language } = useLanguage();
  const { user } = useAuth();
  const t = getComponentTranslations(language, 'creditCardModal');

  const [formData, setFormData] = useState<FormData>(() => {
    const timestamp = new Date().toISOString();
    return {
      name: '',
      lastFourDigits: '',
      expiryDate: new Date().toISOString().split('T')[0],
      limit: 0,
      balance: 0,
      currency: user?.settings?.currency || 'USD',
      type: 'visa',
      color: 'indigo',
      userId: user?.id || '',
      createdAt: timestamp,
      updatedAt: timestamp
    };
  });

  const [selectedWalletId, setSelectedWalletId] = useState<string>('');
  const [paymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        lastFourDigits: initialData.lastFourDigits,
        expiryDate: initialData.expiryDate,
        limit: initialData.limit,
        balance: initialData.balance,
        currency: initialData.currency,
        type: initialData.type,
        color: initialData.color,
        userId: initialData.userId,
        createdAt: initialData.createdAt,
        updatedAt: initialData.updatedAt
      });
    } else if (user) {
      const timestamp = new Date().toISOString();
      setFormData({
        name: '',
        lastFourDigits: '',
        expiryDate: new Date().toISOString().split('T')[0],
        limit: 0,
        balance: 0,
        currency: user.settings?.currency || 'USD',
        type: 'visa',
        color: 'indigo',
        userId: user.id,
        createdAt: timestamp,
        updatedAt: timestamp
      });
    }
  }, [initialData, user]);

  if (!isOpen || !user) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const timestamp = new Date().toISOString();
    const updatedFormData: FormData = {
      ...formData,
      updatedAt: timestamp
    };

    if (selectedWalletId && formData.balance > 0) {
      const selectedWallet = wallets.find(w => w.id === selectedWalletId);
      if (selectedWallet) {
        await addTransaction({
          description: `Credit Card Payment - ${formData.name}`,
          amount: formData.balance,
          currency: selectedWallet.currency,
          type: 'expense',
          category: 'Credit Card Payment',
          date: paymentDate,
          walletId: selectedWalletId,
          paymentMethod: 'credit',
          creditCardId: initialData?.id,
          labels: [],
          userId: user.id,
          createdAt: timestamp,
          updatedAt: timestamp
        });

        onSubmit({
          ...updatedFormData,
          limit: formData.limit + formData.balance,
          balance: 0
        });
      }
    } else {
      onSubmit(updatedFormData);
    }
    onClose();
  };

  return (
    <>
      <div 
        className={`fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity duration-300 ease-in-out z-40 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      />
      <div 
        className={`fixed inset-y-0 right-0 w-full max-w-2xl bg-white dark:bg-gray-800 shadow-2xl transform transition-all duration-500 ease-in-out z-50 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="h-full flex flex-col">
          <div className="flex items-center justify-between px-8 py-6 border-b border-gray-200 dark:border-gray-700 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {initialData ? t?.title?.edit || 'Edit Credit Card' : t?.title?.new || 'New Credit Card'}
            </h2>
            <button 
              onClick={onClose}
              className="rounded-full p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto px-8 py-6">
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="grid grid-cols-2 gap-8">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.name?.label || 'Card Name'}
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    placeholder={t?.fields?.name?.placeholder || 'e.g. My Credit Card'}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.lastDigits?.label || 'Last 4 Digits'}
                  </label>
                  <input
                    type="text"
                    value={formData.lastFourDigits}
                    onChange={e => setFormData(prev => ({ ...prev, lastFourDigits: e.target.value.replace(/\D/g, '').slice(0, 4) }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    placeholder={t?.fields?.lastDigits?.placeholder || '1234'}
                    maxLength={4}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.expiryDate?.label || 'Expiry Date'}
                  </label>
                  <input
                    type="date"
                    value={formData.expiryDate}
                    onChange={e => setFormData(prev => ({ ...prev, expiryDate: e.target.value }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.type?.label || 'Card Type'}
                  </label>
                  <select
                    value={formData.type}
                    onChange={e => setFormData(prev => ({ ...prev, type: e.target.value }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    required
                  >
                    <option value="visa">{t?.fields?.type?.options?.visa || 'Visa'}</option>
                    <option value="mastercard">{t?.fields?.type?.options?.mastercard || 'Mastercard'}</option>
                    <option value="amex">{t?.fields?.type?.options?.amex || 'American Express'}</option>
                    <option value="other">{t?.fields?.type?.options?.other || 'Other'}</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.limit?.label || 'Credit Limit'}
                  </label>
                  <input
                    type="number"
                    value={formData.limit}
                    onChange={e => setFormData(prev => ({ ...prev, limit: parseFloat(e.target.value) }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    min="0"
                    step="0.01"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.balance?.label || 'Current Balance'}
                  </label>
                  <input
                    type="number"
                    value={formData.balance}
                    onChange={e => setFormData(prev => ({ ...prev, balance: parseFloat(e.target.value) }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    min="0"
                    step="0.01"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.currency?.label || 'Currency'}
                  </label>
                  <select
                    value={formData.currency}
                    onChange={e => setFormData(prev => ({ ...prev, currency: e.target.value }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    required
                  >
                    <option value="USD">{t?.fields?.currency?.options?.usd || 'USD'}</option>
                    <option value="EUR">{t?.fields?.currency?.options?.eur || 'EUR'}</option>
                    <option value="GBP">{t?.fields?.currency?.options?.gbp || 'GBP'}</option>
                    <option value="TRY">{t?.fields?.currency?.options?.try || 'TRY'}</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    {t?.fields?.color?.label || 'Card Color'}
                  </label>
                  <select
                    value={formData.color}
                    onChange={e => setFormData(prev => ({ ...prev, color: e.target.value }))}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                  >
                    <option value="indigo">{t?.fields?.color?.options?.indigo || 'Indigo'}</option>
                    <option value="emerald">{t?.fields?.color?.options?.emerald || 'Emerald'}</option>
                    <option value="amber">{t?.fields?.color?.options?.amber || 'Amber'}</option>
                  </select>
                </div>
              </div>

              {formData.balance > 0 && (
                <div className="grid grid-cols-2 gap-8 pt-8 border-t border-gray-200 dark:border-gray-700">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t?.payment?.date?.label || 'Payment Date'}
                    </label>
                    <input
                      type="date"
                      value={paymentDate}
                      onChange={e => setPaymentDate(e.target.value)}
                      className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t?.payment?.wallet?.label || 'Pay from Wallet'}
                    </label>
                    <select
                      value={selectedWalletId}
                      onChange={e => setSelectedWalletId(e.target.value)}
                      className="w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                    >
                      <option value="">{t?.payment?.wallet?.placeholder || 'Select a wallet'}</option>
                      {wallets.map(wallet => (
                        <option key={wallet.id} value={wallet.id}>
                          {wallet.name} ({wallet.balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: wallet.currency
                          })})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </form>
          </div>

          <div className="flex justify-end gap-4 px-8 py-6 border-t border-gray-200 dark:border-gray-700 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm">
            <button
              type="button"
              onClick={onClose}
              className="px-5 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors duration-200"
            >
              {t?.buttons?.cancel || 'Cancel'}
            </button>
            <button
              onClick={handleSubmit}
              className="px-5 py-2.5 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
            >
              {initialData ? (t?.buttons?.save || 'Save') : (t?.buttons?.add || 'Add')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardModal;
