import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import * as Icons from 'lucide-react';

interface IconSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const IconSelect: React.FC<IconSelectProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Filter out non-icon exports and get commonly used icons
  const commonIcons = [
    'Activity', 'AlertCircle', 'Archive', 'ArrowDown', 'ArrowUp', 'Award',
    'Bell', 'Book', 'Bookmark', 'Box', 'Briefcase', 'Calendar', 'Camera',
    'Car', 'Chart', 'Check', 'Circle', 'Clock', 'Cloud', 'Code', 'Coffee',
    'CreditCard', 'DollarSign', 'Download', 'Edit', 'Eye', 'File', 'Filter',
    'Flag', 'Folder', 'Gift', 'Globe', 'Heart', 'Home', 'Image', 'Inbox',
    'Key', 'Link', 'Lock', 'Mail', 'Map', 'Message', 'Music', 'Package',
    'Phone', 'Pin', 'Power', 'Settings', 'Share', 'Shield', 'Shopping',
    'Star', 'Tag', 'Target', 'Tool', 'Trash', 'Trophy', 'Upload', 'User',
    'Video', 'Wallet', 'Warning', 'Wifi', 'Zap'
  ];

  const iconNames = commonIcons.filter(name => 
    typeof Icons[name as keyof typeof Icons] === 'function'
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Get the selected icon component
  const SelectedIcon = Icons[value as keyof typeof Icons] as React.FC<any>;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-700"
      >
        <div className="flex items-center gap-2">
          {SelectedIcon && <SelectedIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" />}
          <span className="text-sm text-gray-700 dark:text-gray-200">{value}</span>
        </div>
        <ChevronDown className="h-4 w-4 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg max-h-60 overflow-auto">
          <div className="grid grid-cols-6 gap-1 p-2">
            {iconNames.map(name => {
              const IconComponent = Icons[name as keyof typeof Icons] as React.FC<any>;
              return (
                <button
                  key={name}
                  type="button"
                  onClick={() => {
                    onChange(name);
                    setIsOpen(false);
                  }}
                  className={`p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center justify-center ${
                    value === name ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400' : ''
                  }`}
                  title={name}
                >
                  <IconComponent className="h-5 w-5" />
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconSelect;