export const translations = {
  title: {
    new: 'New Transaction',
    edit: 'Edit Transaction'
  },
  sections: {
    required: {
      title: 'Required Information',
      tooltip: 'These fields are required to create a transaction'
    },
    additional: {
      title: 'Additional Details',
      optional: 'optional'
    }
  },
  fields: {
    description: {
      label: 'Description',
      placeholder: 'Enter transaction description',
      tooltip: 'Clearly describe what this transaction is for'
    },
    amount: {
      label: 'Amount',
      placeholder: 'Enter amount',
      tooltip: 'Enter the transaction amount'
    },
    type: {
      label: 'Type',
      tooltip: 'Choose whether this is income, expense, or debt',
      options: {
        income: 'Income',
        expense: 'Expense',
        debt: 'Debt'
      }
    },
    category: {
      label: 'Category',
      placeholder: 'Select category',
      tooltip: 'Categorize your transaction for better organization'
    },
    date: {
      label: 'Date',
      tooltip: 'When did this transaction occur?'
    },
    wallet: {
      label: 'Wallet',
      placeholder: 'Select wallet',
      tooltip: 'Which wallet is this transaction associated with?'
    },
    budget: {
      associatedBudget: 'Associated Budget'
    },
    creditCard: {
      label: 'Credit Card',
      placeholder: 'Select credit card',
      tooltip: 'Select a credit card if this is a card payment'
    },
    labels: {
      label: 'Labels',
      placeholder: 'Select or create labels',
      tooltip: 'Add labels to organize and filter transactions'
    },
    note: {
      label: 'Note',
      placeholder: 'Add a note about the transaction',
      tooltip: 'Add additional details about this transaction'
    },
    attachments: {
      label: 'Attachments',
      tooltip: 'Attach receipts or related documents',
      dropzone: 'Drag and drop files here or click to select',
      maxSize: 'Maximum file size: {{size}}MB'
    },
    installments: {
      label: 'Installments',
      enabled: 'Enable installments',
      count: 'Number of installments',
      period: 'Payment period',
      periods: {
        weekly: 'Weekly',
        monthly: 'Monthly',
        month: 'Month'
      },
      format: '{{count}}x {{period}}'
    },
    debtDetails: {
      personName: {
        label: 'Debtor Name',
        placeholder: 'Enter debtor name'
      },
      dueDate: {
        label: 'Due Date',
        format: 'Due: {{date}}'
      }
    },
    recurrence: {
      label: 'Recurrence',
      enabled: 'Recurring transaction',
      frequency: {
        label: 'Frequency',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly'
      },
      endDate: {
        label: 'End Date',
        never: 'Never'
      }
    },
    currency: {
      label: 'Currency',
      try: 'Turkish Lira (TRY)',
      usd: 'US Dollar (USD)',
      eur: 'Euro (EUR)',
      gbp: 'British Pound (GBP)'
    }
  },
  buttons: {
    add: 'Add Transaction',
    save: 'Save Changes',
    saveChanges: 'Save Changes',
    addTransaction: 'Add Transaction',
    cancel: 'Cancel',
    close: 'Close',
    upload: 'Upload',
    remove: 'Remove'
  }
};
