const translations = {
  title: 'Upcoming Payments',
  noSubscriptions: 'No upcoming subscription payments',
  dueIn: 'Due in {{days}} days',
  dueToday: 'Due today',
  overdue: 'Overdue by {{days}} days',
  amount: 'Amount',
  nextPayment: 'Next Payment',
  billingCycle: {
    monthly: 'Monthly',
    yearly: 'Yearly',
    weekly: 'Weekly',
    daily: 'Daily'
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    cancelled: 'Cancelled',
    pending: 'Pending'
  },
  actions: {
    viewDetails: 'View Details',
    markAsPaid: 'Mark as Paid',
    cancel: 'Cancel Subscription'
  },
  confirmations: {
    markAsPaid: 'Are you sure you want to mark this payment as paid?',
    cancel: 'Are you sure you want to cancel this subscription?'
  }
};

export default translations;
