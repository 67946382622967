export const labelModal = {
  title: {
    new: 'New Label',
    edit: 'Edit Label'
  },
  labels: {
    nameLabel: 'Label Name',
    namePlaceholder: 'Enter label name',
    colorLabel: 'Choose Color'
  },
  buttons: {
    cancel: 'Cancel',
    add: 'Add Label',
    save: 'Save Changes'
  }
};
