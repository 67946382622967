import React, { useState } from 'react';
import { Download, FileSpreadsheet, FileJson, FileText, ChevronDown } from 'lucide-react';
import { ExportFormat } from '../utils/export';
import { getTranslations } from '../locales/components/exportButton';

interface ExportButtonProps {
  onExport: (format: ExportFormat) => void;
  className?: string;
  language?: 'en' | 'tr';
}

const ExportButton: React.FC<ExportButtonProps> = ({ 
  onExport, 
  className, 
  language = 'en' 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const translations = getTranslations(language);

  const exportOptions = [
    { format: 'excel' as const, label: translations.formats.excel, icon: FileSpreadsheet },
    { format: 'json' as const, label: translations.formats.json, icon: FileJson },
    { format: 'pdf' as const, label: translations.formats.pdf, icon: FileText },
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 ${className}`}
      >
        <Download className="h-4 w-4 mr-2" />
        {translations.mainButton}
        <ChevronDown className="h-4 w-4 ml-2" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-100 dark:border-gray-700 py-1 z-10">
          {exportOptions.map(({ format, label, icon: Icon }) => (
            <button
              key={format}
              onClick={() => {
                onExport(format);
                setIsOpen(false);
              }}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <Icon className="h-4 w-4 mr-2" />
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExportButton;
