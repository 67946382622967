import React, { useState } from 'react';
import { X } from 'lucide-react';
import * as Icons from 'lucide-react';
import { Category } from '../../types';
import { getTranslations } from '../../locales/components/categoryModal';

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<Category, 'id' | 'transactionCount' | 'wallets'>) => void;
  initialData?: Category;
  language?: 'en' | 'tr';
}

const CategoryModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  initialData, 
  language = 'en' 
}: CategoryModalProps) => {
  const translations = getTranslations(language);

  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    type: initialData?.type || 'expense',
    icon: initialData?.icon || 'Circle',
    color: initialData?.color || 'gray'
  });

  const iconNames = Object.keys(Icons).filter(name => 
    name !== 'createLucideIcon' && name !== 'default'
  );

  const colors = [
    'slate', 'gray', 'zinc', 'neutral', 'stone', 'red', 'orange', 'amber',
    'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue',
    'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose'
  ];

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-900">
            {initialData ? translations.title.edit : translations.title.new}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {translations.labels.icon}
            </label>
            <div className="relative">
              <select
                value={formData.icon}
                onChange={(e) => setFormData(d => ({ ...d, icon: e.target.value }))}
                className="appearance-none w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              >
                {iconNames.map(name => {
                  const IconComponent = Icons[name as keyof typeof Icons];
                  return (
                    <option key={name} value={name} className="flex items-center py-2">
                      {name}
                    </option>
                  );
                })}
              </select>
              <div className="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                {React.createElement(Icons[formData.icon as keyof typeof Icons], { size: 16 })}
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {translations.labels.color}
            </label>
            <select
              value={formData.color}
              onChange={(e) => setFormData(d => ({ ...d, color: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              {colors.map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {translations.labels.name}
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(d => ({ ...d, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {translations.labels.type}
            </label>
            <select
              value={formData.type}
              onChange={(e) => setFormData(d => ({ ...d, type: e.target.value as 'income' | 'expense' }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="expense">{translations.types.expense}</option>
              <option value="income">{translations.types.income}</option>
            </select>
          </div>

          <div className="flex justify-end gap-3 mt-6 pt-6 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              {translations.buttons.cancel}
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {initialData 
                ? translations.buttons.save.edit 
                : translations.buttons.save.new
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryModal;
