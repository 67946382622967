import { Category } from '../types';

export const SYSTEM_USER_ID = 'system';
const timestamp = new Date().toISOString();

export const initialDefaultCategories: Omit<Category, 'id'>[] = [
  {
    name: 'Shopping',
    type: 'expense',
    icon: 'ShoppingBag',
    color: 'violet',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Bills',
    type: 'expense',
    icon: 'Receipt',
    color: 'emerald',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Education',
    type: 'expense',
    icon: 'GraduationCap',
    color: 'blue',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Entertainment',
    type: 'expense',
    icon: 'Gamepad2',
    color: 'yellow',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Salary',
    type: 'income',
    icon: 'Banknote',
    color: 'green',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Investment',
    type: 'income',
    icon: 'TrendingUp',
    color: 'blue',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  },
  {
    name: 'Other',
    type: 'income',
    icon: 'Package',
    color: 'gray',
    transactionCount: 0,
    wallets: [],
    userId: SYSTEM_USER_ID,
    createdAt: timestamp,
    updatedAt: timestamp
  }
];
