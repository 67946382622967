import { functions } from '../lib/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';

interface EmailData {
  to: string;
  subject: string;
  template: string;
  data: Record<string, any>;
}

interface EmailResponse {
  success: boolean;
  message: string;
}

export const sendEmail = async (emailData: EmailData): Promise<void> => {
  try {
    console.log('[Email] Sending email:', {
      template: emailData.template,
      to: emailData.to,
      subject: emailData.subject
    });

    const sendEmailFn = httpsCallable<EmailData, EmailResponse>(functions, 'sendEmail');
    const result = await sendEmailFn(emailData);

    if (!result.data.success) {
      throw new Error(result.data.message);
    }

    console.log('[Email] Successfully sent email:', {
      template: emailData.template,
      result: result.data
    });
  } catch (error) {
    console.error('[Email] Failed to send email:', {
      template: emailData.template,
      error
    });
    throw new Error(error instanceof Error ? error.message : 'Failed to send email');
  }
};
