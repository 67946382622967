export const planLimitModal = {
  title: 'Upgrade to Premium',
  description: 'You\'ve reached the limit for {feature} on the free plan. Upgrade to Premium to unlock:',
  features: [
    'Unlimited wallets',
    'Unlimited budgets',
    'Unlimited subscriptions',
    'Advanced reporting',
    'Data export',
    'Invite team members'
  ],
  buttons: {
    close: 'Maybe Later',
    upgrade: 'Upgrade Now'
  }
};
