export const creditCardModal = {
  title: {
    new: 'Yeni Kredi Kartı',
    edit: 'Kredi Kartını Düzenle'
  },
  fields: {
    name: {
      label: 'Kart Adı',
      placeholder: 'örn. Benim Kredi Kartım'
    },
    lastDigits: {
      label: 'Son 4 Hane',
      placeholder: '1234'
    },
    expiryDate: {
      label: 'Son Kullanma Tarihi'
    },
    type: {
      label: 'Kart Tipi',
      options: {
        visa: 'Visa',
        mastercard: 'Mastercard',
        amex: 'American Express',
        other: 'Diğer'
      }
    },
    limit: {
      label: 'Kredi Limiti'
    },
    balance: {
      label: 'Mevcut Bakiye'
    },
    currency: {
      label: 'Para Birimi',
      options: {
        usd: 'USD',
        eur: 'EUR',
        gbp: 'GBP',
        try: 'TRY'
      }
    },
    color: {
      label: 'Kart Rengi',
      options: {
        indigo: 'İndigo',
        emerald: 'Zümrüt',
        amber: 'Kehribar'
      }
    }
  },
  payment: {
    title: 'Ödeme Detayları',
    date: {
      label: 'Ödeme Tarihi'
    },
    wallet: {
      label: 'Ödeme Yapılacak Cüzdan',
      placeholder: 'Cüzdan seçin'
    }
  },
  buttons: {
    cancel: 'İptal',
    save: 'Kaydet',
    add: 'Ekle'
  }
};
