const translations = {
  title: 'Transaction Details',
  description: 'Description',
  category: 'Category',
  wallet: 'Wallet',
  date: 'Date',
  labels: 'Labels',
  note: 'Note',
  attachments: 'Attachments',
  attachment: 'Attachment {{number}}',
  paymentMethod: 'Payment Method',
  personName: 'Person Name',
  dueDate: 'Due Date',
  installments: 'Installments',
  installmentsFormat: '{{count}}x {{period}} of {{amount}}',
  types: {
    income: 'Income',
    expense: 'Expense',
    debt: 'Debt'
  },
  loading: 'Loading...',
  error: {
    generic: 'An error occurred',
    notFound: 'Transaction not found'
  }
};

export default translations;
