import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { getAuthTranslations } from '../../locales';

interface SocialSignInProps {
  className?: string;
}

const SocialSignIn: React.FC<SocialSignInProps> = ({ className = '' }) => {
  const { signInWithGoogle, signInWithFacebook, signInWithApple, signInWithMicrosoft } = useAuth();
  const { language } = useLanguage();
  const auth = getAuthTranslations(language);

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">
            {auth.socialSignIn.continueWith}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <button
          onClick={() => signInWithGoogle()}
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
            />
          </svg>
          <span className="ml-2">{auth.socialSignIn.google}</span>
        </button>

        <button
          onClick={() => signInWithFacebook()}
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
            />
          </svg>
          <span className="ml-2">{auth.socialSignIn.facebook}</span>
        </button>

        <button
          onClick={() => signInWithApple()}
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M17.05 20.28c-.98.95-2.05.88-3.08.54-1.07-.36-2.05-.35-3.16 0-1.4.46-2.14.32-3.01-.54C2.72 15.25 3.63 7.1 9.01 6.88c1.66.08 2.79 1.02 3.72 1.03.93.02 2.52-1.24 3.92-.94.67.15 2.6.67 3.81 2.52-3.36 2.11-2.8 6.03.59 7.74-.52 1.49-1.19 2.95-3 3.05zM12.03 6.3c-.13-2.85 2.18-5.3 5-5.3.26 2.7-2.38 5.36-5 5.3z"
            />
          </svg>
          <span className="ml-2">{auth.socialSignIn.apple}</span>
        </button>

        <button
          onClick={() => signInWithMicrosoft()}
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M11.4 24H0V12.6h11.4V24zM24 24H12.6V12.6H24V24zM11.4 11.4H0V0h11.4v11.4zm12.6 0H12.6V0H24v11.4z"
            />
          </svg>
          <span className="ml-2">{auth.socialSignIn.microsoft}</span>
        </button>
      </div>
    </div>
  );
};

export default SocialSignIn;
