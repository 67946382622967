import React from 'react';

interface PaymentNetworkLogoProps {
  type: string;
  className?: string;
}

const PaymentNetworkLogo: React.FC<PaymentNetworkLogoProps> = ({ type, className = "h-8" }) => {
  switch (type?.toLowerCase()) {
    case 'visa':
      return (
        <svg className={className} viewBox="0 0 750 471" fill="currentColor">
          <path d="M278.198 334.228l33.36-195.763h53.358l-33.384 195.763h-53.334zm246.11-191.54c-10.57-3.966-27.135-8.222-47.822-8.222-52.725 0-89.863 26.551-90.18 64.604-.297 28.129 26.484 43.821 46.754 53.185 20.771 9.597 27.752 15.716 27.652 24.283-.297 13.095-16.586 19.096-31.927 19.096-21.357 0-32.714-2.967-50.244-10.276l-6.876-3.112-7.48 43.824c12.463 5.466 35.508 10.199 59.438 10.445 56.089 0 92.502-26.248 92.916-66.884.199-22.27-14.016-39.216-44.801-53.188-18.65-9.056-30.072-15.099-29.951-24.269 0-8.137 9.668-16.838 30.459-16.838 17.396-.297 30.008 3.518 39.907 7.5l4.781 2.259 7.374-41.407zm142.374 192.21h46.437l-40.557-195.763h-43.536c-9.649 0-17.77 2.748-22.233 12.743l-78.722 183.02h56.026s9.157-24.015 11.249-29.35h68.487c1.298 5.716 5.284 29.35 5.284 29.35h-2.435zm-65.417-126.408c4.413-11.279 21.26-54.724 21.26-54.724-.297.496 4.379-11.321 7.074-18.669l3.606 16.879s10.218 46.729 12.352 56.514h-44.292zm-363.265-69.355l-52.24 133.496-5.567-27.13c-9.725-31.273-40.025-65.155-73.898-82.118l47.766 171.203 56.456-.065 84.004-195.386h-56.521" />
        </svg>
      );
    case 'mastercard':
      return (
        <div className="flex items-center">
          <div className="w-8 h-8 bg-[#EB001B] rounded-full opacity-90" />
          <div className="w-8 h-8 bg-[#F79E1B] rounded-full -ml-4 opacity-90 mix-blend-multiply" />
        </div>
      );
    case 'amex':
      return (
        <svg className={className} viewBox="0 0 750 471" fill="currentColor">
          <path d="M47 47h656v377H47V47zm130.512 53.784v31.834h-45.91v32.737h45.91v32.328h-45.91v32.736h45.91v32.328h-84.21V100.784h84.21zm108.163 0l-38.502 80.899 38.502 80.964h-42.092l-22.91-54.933-22.91 54.933h-42.092l38.438-80.964-38.438-80.899h42.092l22.91 54.933 22.91-54.933h42.092zm70.07 0v31.834h45.91v32.737h-45.91v32.328h45.91v32.736h-45.91v32.328h84.21V100.784h-84.21zm239.582 0l-38.502 80.899 38.502 80.964h-42.092l-22.91-54.933-22.91 54.933h-42.092l38.438-80.964-38.438-80.899h42.092l22.91 54.933 22.91-54.933h42.092z"/>
        </svg>
      );
    default:
      return <div className={`${className} text-current`}>
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="2" y="5" width="20" height="14" rx="2" />
          <line x1="2" y1="10" x2="22" y2="10" />
        </svg>
      </div>;
  }
};

export default PaymentNetworkLogo;
