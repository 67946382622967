export const header = {
  common: {
    settings: 'Settings',
    logout: 'Log Out'
  },
  languageMenu: {
    title: 'Language'
  },
  themeToggle: {
    light: 'Switch to Light Mode',
    dark: 'Switch to Dark Mode'
  },
  notifications: 'Notifications',
  userMenu: {
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Log Out'
  }
};
