const translations = {
  title: 'İşlemler',
  subtitle: 'İşlemlerinizi yönetin',
  new: 'Yeni İşlem',
  types: {
    all: 'Tüm Türler',
    income: 'Gelir',
    expense: 'Gider'
  },
  categories: {
    all: 'Tüm Kategoriler',
    income: 'Gelir Kategorileri',
    expense: 'Gider Kategorileri'
  },
  labels: {
    all: 'Tüm Etiketler',
    filter: 'Etikete Göre Filtrele'
  },
  status: {
    all: 'Tüm Durumlar',
    completed: 'Tamamlandı',
    pending: 'Beklemede',
    cancelled: 'İptal Edildi'
  },
  sortOrder: 'Sıralama',
  installments: {
    format: '{{current}} / {{total}}',
    periods: {
      monthly: 'Aylık',
      weekly: 'Haftalık',
      yearly: 'Yıllık'
    }
  },
  fields: {
    description: 'Açıklama',
    amount: 'Tutar',
    date: 'Tarih',
    category: 'Kategori',
    wallet: 'Cüzdan',
    labels: 'Etiketler',
    attachments: 'Ekler',
    notes: 'Notlar',
    creditCard: 'Kredi Kartı',
    paymentMethod: 'Ödeme Yöntemi',
    status: 'Durum'
  },
  filters: {
    title: 'Filtreler',
    type: 'İşlem Türü',
    category: 'Kategori',
    label: 'Etiket',
    wallet: 'Cüzdan'
  },
  noTransactions: 'İşlem bulunamadı'
};

export default translations;
