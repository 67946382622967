const translations = {
  welcome: 'Hoşgeldiniz',
  overview: {
    title: 'Finansal genel bakışınız',
    newTransaction: 'Yeni İşlem',
    wallets: {
      title: 'Cüzdanlarım',
      new: 'Yeni Cüzdan Ekle',
      empty: {
        title: 'Yeni Cüzdan Ekle',
        description: 'Başlamak için yeni bir cüzdan oluşturun'
      }
    }
  },
  stats: {
    income: 'Gelir',
    expenses: 'Gider',
    balance: 'Bakiye',
    transactions: 'İşlemler',
    savingsRate: 'Tasarruf Oranı',
    totalBalance: 'Toplam Bakiye',
    totalIncome: 'Toplam Gelir',
    totalExpenses: 'Toplam Gider',
    changes: {
      positive: '+%{value}',
      negative: '-%{value}'
    }
  },
  upcomingPayments: {
    title: 'Yaklaşan Ödemeler & Gelirler',
    noPayments: 'Önümüzdeki 30 gün içinde yaklaşan ödeme yok',
    creditCard: 'Kredi Kartı Ödemesi',
    installment: 'Taksit {{current}}/{{total}}',
    debt: 'Borç Ödemesi'
  }
};

export default translations;
