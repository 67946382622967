import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '../../context/ThemeContext';

interface TrendChartProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
      fill: boolean;
    }[];
  };
  title: string;
}

const TrendChart: React.FC<TrendChartProps> = ({ data, title }) => {
  const { isDark } = useTheme();

  return (
    <div className="card p-6 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">{title}</h2>
      <div className="h-[400px]">
        <Line
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                  callback: value => `$${value}`,
                  color: isDark ? '#9ca3af' : '#6b7280',
                }
              },
              x: {
                grid: {
                  color: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                  color: isDark ? '#9ca3af' : '#6b7280',
                }
              }
            },
            plugins: {
              legend: {
                position: 'top' as const,
                labels: {
                  color: isDark ? '#ffffff' : '#111827',
                }
              },
              tooltip: {
                callbacks: {
                  label: context => `${context.dataset.label}: $${context.parsed.y.toFixed(2)}`
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default TrendChart;
