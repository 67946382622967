// Exchange rates API URL
const API_URL = 'https://v6.exchangerate-api.com/v6/fdd3b227ae11101edd567987/latest/USD';

// Update interval in milliseconds (1 hour)
const UPDATE_INTERVAL = 60 * 60 * 1000;

// LocalStorage key
const STORAGE_KEY = 'exchange_rates';

// Default rates to use as fallback
const DEFAULT_RATES = {
  base: 'USD',
  rates: {
    USD: 1,
    EUR: 0.91,
    GBP: 0.79,
    TRY: 28.74,
    JPY: 147.73,
    CNY: 7.19
  },
  lastUpdated: new Date().toISOString()
};

export interface ExchangeRates {
  base: string;
  rates: { [key: string]: number };
  lastUpdated: string;
}

let lastFetchTime = 0;
let fetchPromise: Promise<ExchangeRates> | null = null;

// Get rates from localStorage
const getStoredRates = (): ExchangeRates | null => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      return JSON.parse(stored);
    }
  } catch (error) {
    console.error('Error reading from localStorage:', error);
  }
  return null;
};

// Store rates in localStorage
const storeRates = (rates: ExchangeRates) => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(rates));
  } catch (error) {
    console.error('Error storing in localStorage:', error);
  }
};

// Fetch latest exchange rates
export const fetchExchangeRates = async (): Promise<ExchangeRates> => {
  const now = Date.now();

  // If we have a pending fetch, return it
  if (fetchPromise) {
    return fetchPromise;
  }

  // Check if we have recent rates in localStorage
  const storedRates = getStoredRates();
  if (storedRates && now - new Date(storedRates.lastUpdated).getTime() < UPDATE_INTERVAL) {
    return storedRates;
  }

  // Fetch new rates
  fetchPromise = (async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      const rates = {
        base: 'USD',
        rates: {
          USD: 1,
          EUR: data.conversion_rates.EUR,
          GBP: data.conversion_rates.GBP,
          TRY: data.conversion_rates.TRY,
          JPY: data.conversion_rates.JPY,
          CNY: data.conversion_rates.CNY
        },
        lastUpdated: new Date().toISOString()
      };

      // Store in localStorage
      storeRates(rates);
      lastFetchTime = now;
      
      return rates;
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      
      // Try to get rates from localStorage
      const storedRates = getStoredRates();
      if (storedRates) {
        return storedRates;
      }

      // Fallback to default rates
      return DEFAULT_RATES;
    } finally {
      fetchPromise = null;
    }
  })();

  return fetchPromise;
};

// Subscribe to exchange rate updates
export const subscribeToExchangeRates = (callback: (rates: ExchangeRates) => void) => {
  // Set up periodic updates
  const intervalId = setInterval(() => {
    fetchExchangeRates().then(callback).catch(console.error);
  }, UPDATE_INTERVAL);

  // Initial fetch
  fetchExchangeRates().then(callback).catch(console.error);

  // Return cleanup function
  return () => {
    clearInterval(intervalId);
  };
};
