import React, { createContext, useContext, useState, useEffect } from 'react';
import { CreditCard } from '../types/creditCard';
import { useAuth } from './AuthContext';
import * as creditCardService from '../services/creditCards';

interface CreditCardContextType {
  creditCards: CreditCard[];
  isLoading: boolean;
  error: string | null;
  addCreditCard: (card: Omit<CreditCard, 'id'>) => Promise<void>;
  updateCreditCard: (id: string, card: Partial<CreditCard>) => Promise<void>;
  deleteCreditCard: (id: string) => Promise<void>;
}

const CreditCardContext = createContext<CreditCardContextType | undefined>(undefined);

export const useCreditCards = () => {
  const context = useContext(CreditCardContext);
  if (!context) throw new Error('useCreditCards must be used within CreditCardProvider');
  return context;
};

export const CreditCardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCreditCards = async () => {
      if (!user) {
        setCreditCards([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const userCreditCards = await creditCardService.getUserCreditCards(user.id);
        setCreditCards(userCreditCards);
      } catch (err) {
        console.error('Error loading credit cards:', err);
        setError(err instanceof Error ? err.message : 'Failed to load credit cards');
      } finally {
        setIsLoading(false);
      }
    };

    loadCreditCards();
  }, [user]);

  const addCreditCard = async (card: Omit<CreditCard, 'id'>) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      const cardId = await creditCardService.createCreditCard(user.id, card);
      const newCard = { ...card, id: cardId };
      setCreditCards(current => [...current, newCard]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create credit card');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateCreditCard = async (id: string, updates: Partial<CreditCard>) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      await creditCardService.updateCreditCard(id, updates);
      setCreditCards(current =>
        current.map(card =>
          card.id === id ? { ...card, ...updates } : card
        )
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update credit card');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCreditCard = async (id: string) => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      await creditCardService.deleteCreditCard(id);
      setCreditCards(current => current.filter(card => card.id !== id));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete credit card');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CreditCardContext.Provider value={{
      creditCards,
      isLoading,
      error,
      addCreditCard,
      updateCreditCard,
      deleteCreditCard
    }}>
      {children}
    </CreditCardContext.Provider>
  );
};
