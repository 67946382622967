export interface PlanLimits {
  wallets: number;
  budgets: number;
  subscriptions: number;
  transactions: number;
  inviteMembers: boolean;
  creditCards: number;
  labels: number;
  categories: number;
  reports: boolean;
  exportData: boolean;
}

export const PLAN_LIMITS: Record<'free' | 'premium', PlanLimits> = {
  free: {
    wallets: 1,
    budgets: 1,
    subscriptions: 1,
    transactions: 50,
    inviteMembers: false,
    creditCards: 1,
    labels: 3,
    categories: 5,
    reports: false,
    exportData: false
  },
  premium: {
    wallets: -1, // unlimited
    budgets: -1,
    subscriptions: -1,
    transactions: -1,
    inviteMembers: true,
    creditCards: -1,
    labels: -1,
    categories: -1,
    reports: true,
    exportData: true
  }
};

export const checkPlanLimit = (
  plan: 'free' | 'premium',
  feature: keyof PlanLimits,
  currentCount: number
): boolean => {
  const limit = PLAN_LIMITS[plan][feature];
  return limit === -1 || currentCount < limit;
};

export const getPlanLimit = (
  plan: 'free' | 'premium',
  feature: keyof PlanLimits
): number | boolean => {
  return PLAN_LIMITS[plan][feature];
};