import React from 'react';
import { CheckSquare, Square, Trash2 } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';

interface TransactionPageHeaderProps {
  selectedCount: number;
  totalCount: number;
  onSelectAll: () => void;
  onDeleteSelected: () => void;
}

const TransactionPageHeader: React.FC<TransactionPageHeaderProps> = ({
  selectedCount,
  totalCount,
  onSelectAll,
  onDeleteSelected
}) => {
  const { t } = useLanguage();

  return (
    <div className="p-4 border-b border-gray-100 dark:border-gray-700 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <button
          onClick={onSelectAll}
          className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
        >
          {selectedCount === totalCount && totalCount > 0 ? (
            <CheckSquare className="h-5 w-5" />
          ) : (
            <Square className="h-5 w-5" />
          )}
          {t('common.selectAll')}
        </button>
        {selectedCount > 0 && (
          <>
            <div className="h-4 w-px bg-gray-300 dark:bg-gray-600" />
            <button
              onClick={onDeleteSelected}
              className="flex items-center gap-2 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            >
              <Trash2 className="h-5 w-5" />
              {t('common.delete')}
              {selectedCount > 0 && (
                <span className="ml-1">({selectedCount})</span>
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionPageHeader;
