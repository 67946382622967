import { useState } from 'react';
import { ArrowUpRight, ArrowDownCircle, Clock, XCircle, CheckCircle, MoreVertical, Pencil, Trash2, Wallet, CreditCard, Edit2 } from 'lucide-react';
import { Transaction } from '../../types';
import { useTransactions } from '../../context/TransactionContext';
import { useWallets } from '../../context/WalletContext';
import { useCategories } from '../../context/CategoryContext';
import { useLabels } from '../../context/LabelContext';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { getTranslations } from '../../locales/components/transactionItem';
import TransactionModal from './TransactionModal';
import TransactionDetails from './TransactionDetails';
import LabelTag from '../labels/LabelTag';

interface TransactionItemProps extends Transaction {}

const TransactionItem: React.FC<TransactionItemProps> = (transaction) => {
  const { deleteTransaction, updateTransaction } = useTransactions();
  const { wallets } = useWallets();
  const { categories } = useCategories();
  const { labels: allLabels } = useLabels();
  const { language } = useLanguage();
  const translations = getTranslations(language);
  const { user } = useAuth();
  const [showActions, setShowActions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState<Transaction | null>(null);
  const [viewingTransaction, setViewingTransaction] = useState<Transaction | null>(null);

  const handleEdit = (formData: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) return;

    const timestamp = new Date().toISOString();
    const completeData = {
      ...formData,
      userId: user.id,
      createdAt: timestamp,
      updatedAt: timestamp,
      attachments: formData.attachments || [],
      labels: formData.labels || []
    };

    updateTransaction(transaction.id, completeData);
    setIsModalOpen(false);
  };

  const wallet = wallets.find(w => w.id === transaction.walletId);
  const category = categories.find(c => c.id === transaction.category);

  const getWalletColor = (walletId: string): string => {
    const wallet = wallets.find(w => w.id === walletId);
    return wallet?.color || 'gray';
  };

  const getWalletName = (walletId: string): string => {
    const wallet = wallets.find(w => w.id === walletId);
    return wallet?.name || 'Unknown Wallet';
  };

  const getCategoryName = (categoryId: string): string => {
    const category = categories.find(c => c.id === categoryId);
    return category?.name || categoryId;
  };

  const formatDueDate = (date: string) => {
    return translations.dueDate.replace('{{date}}', new Date(date).toLocaleDateString());
  };

  const formatInstallments = (current: number, total: number, period: string) => {
    try {
      if (!translations?.installments?.format) {
        return '';
      }

      const periodTranslation = translations.installments.periods?.[period as keyof typeof translations.installments.periods];
      if (!periodTranslation) {
        return `${current}/${total}`;
      }

      return translations.installments.format
        .replace('{{current}}', String(current))
        .replace('{{total}}', String(total))
        .replace('{{period}}', periodTranslation);
    } catch (error) {
      console.error('Error formatting installments:', error);
      return `${current}/${total}`;
    }
  };

  const getLabelInfo = (labelId: string) => {
    const label = allLabels.find(l => l.id === labelId);
    return {
      name: label?.name || labelId,
      color: label?.color || 'indigo'
    };
  };

  const formatAmount = (amount: number, transactionCurrency: string | null | undefined, walletCurrency: string | null | undefined) => {
    // Ensure we have a valid currency code, defaulting to 'TRY' if none is available
    const validCurrency = transactionCurrency || walletCurrency || 'TRY';
    
    try {
      return Math.abs(amount).toLocaleString('tr-TR', {
        style: 'currency',
        currency: validCurrency
      });
    } catch (error) {
      // If there's any error with currency formatting, fall back to a simple number format with the currency code
      return `${Math.abs(amount).toLocaleString('tr-TR')} ${validCurrency}`;
    }
  };

  return (
    <>
      <div 
        className="flex items-center justify-between p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors cursor-pointer"
        onClick={() => setViewingTransaction(transaction)}
      >
        <div className="flex items-center gap-4">
          <div className={`p-2 rounded-lg ${
            transaction.type === 'income' ? 'bg-green-100 dark:bg-green-900/30' : 'bg-red-100 dark:bg-red-900/30'
          }`}>
            {transaction.type === 'income' ? (
              <ArrowUpRight className="h-5 w-5 text-green-600 dark:text-green-400" />
            ) : (
              <ArrowDownCircle className="h-5 w-5 text-red-600 dark:text-red-400" />
            )}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <p className="font-medium text-gray-900 dark:text-white">{transaction.description}</p>
              <span className={`px-2 py-0.5 text-xs rounded-full bg-${getWalletColor(transaction.walletId)}-100 dark:bg-${getWalletColor(transaction.walletId)}-900/30 text-${getWalletColor(transaction.walletId)}-700 dark:text-${getWalletColor(transaction.walletId)}-300`}>
                {getWalletName(transaction.walletId)}
              </span>
              {transaction.paymentMethod === 'credit' && (
                <span className="px-2 py-0.5 text-xs rounded-full bg-purple-100 text-purple-700 dark:bg-purple-900/50 dark:text-purple-300 flex items-center gap-1">
                  <CreditCard className="h-3 w-3" />
                  {translations.creditCard}
                </span>
              )}
              {transaction.installments?.enabled && (
                <span className="px-2 py-0.5 text-xs rounded-full bg-blue-100 text-blue-700 dark:bg-blue-900/50 dark:text-blue-300">
                  {formatInstallments(
                    transaction.installments.current,
                    transaction.installments.count,
                    transaction.installments.period
                  )}
                </span>
              )}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {getCategoryName(transaction.category)}
            </p>
            {transaction.labels && transaction.labels.length > 0 && (
              <div className="flex gap-2 mt-1">
                {transaction.labels.slice(0, 2).map((labelId, index) => {
                  const { name, color } = getLabelInfo(labelId);
                  return (
                    <LabelTag
                      key={`label-${transaction.id}-${index}`}
                      name={name}
                      color={color}
                    />
                  );
                })}
                {transaction.labels.length > 2 && (
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    +{transaction.labels.length - 2} {translations.more}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="ml-auto flex items-center gap-4">
          <div className="text-right">
            <p className={`font-medium ${
              transaction.amount > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
            }`}>
              {transaction.amount > 0 ? '+' : ''}{formatAmount(transaction.amount, transaction.currency, wallet?.currency)}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {new Date(transaction.date).toLocaleDateString()}
            </p>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setEditingTransaction(transaction);
              setIsModalOpen(true);
            }}
            className="p-2 text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Edit2 className="h-5 w-5" />
          </button>
        </div>
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingTransaction(null);
        }}
        onSubmit={handleEdit}
        initialData={editingTransaction || undefined}
      />

      <TransactionDetails
        isOpen={!!viewingTransaction}
        onClose={() => setViewingTransaction(null)}
        transaction={viewingTransaction!}
      />
    </>
  );
};

export default TransactionItem;
