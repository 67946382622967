export const translations = {
  title: {
    new: 'Yeni İşlem',
    edit: 'İşlemi Düzenle'
  },
  sections: {
    required: {
      title: 'Gerekli Bilgiler',
      tooltip: 'Bu alanlar işlem oluşturmak için gereklidir'
    },
    additional: {
      title: 'Ek Detaylar',
      optional: 'opsiyonel'
    }
  },
  fields: {
    description: {
      label: 'Açıklama',
      placeholder: 'İşlem açıklaması girin',
      tooltip: 'Bu işlemin ne için olduğunu açıkça belirtin'
    },
    amount: {
      label: 'Tutar',
      placeholder: 'Tutar girin',
      tooltip: 'İşlem tutarını girin'
    },
    type: {
      label: 'Tür',
      tooltip: 'Bunun gelir, gider veya borç olduğunu seçin',
      options: {
        income: 'Gelir',
        expense: 'Gider',
        debt: 'Borç'
      }
    },
    category: {
      label: 'Kategori',
      placeholder: 'Kategori seçin',
      tooltip: 'Daha iyi organizasyon için işleminizi kategorize edin'
    },
    date: {
      label: 'Tarih',
      tooltip: 'Bu işlem ne zaman gerçekleşti?'
    },
    wallet: {
      label: 'Cüzdan',
      placeholder: 'Cüzdan seçin',
      tooltip: 'Bu işlem hangi cüzdanla ilişkili?'
    },
    budget: {
      associatedBudget: 'İlişkili Bütçe'
    },
    creditCard: {
      label: 'Kredi Kartı',
      placeholder: 'Kredi kartı seçin',
      tooltip: 'Bu bir kart ödemesiyse kredi kartı seçin'
    },
    labels: {
      label: 'Etiketler',
      placeholder: 'Etiket seçin veya oluşturun',
      tooltip: 'İşlemleri düzenlemek ve filtrelemek için etiket ekleyin'
    },
    note: {
      label: 'Not',
      placeholder: 'İşlem hakkında not ekleyin',
      tooltip: 'Bu işlem hakkında ek detaylar ekleyin'
    },
    attachments: {
      label: 'Ekler',
      tooltip: 'Makbuz veya ilgili belgeleri ekleyin',
      dropzone: 'Dosyaları buraya sürükleyin veya seçmek için tıklayın',
      maxSize: 'Maksimum dosya boyutu: {{size}}MB'
    },
    installments: {
      label: 'Taksitler',
      enabled: 'Taksitlendirmeyi etkinleştir',
      count: 'Taksit sayısı',
      period: 'Ödeme periyodu',
      periods: {
        weekly: 'Haftalık',
        monthly: 'Aylık',
        month: 'Ay'
      },
      format: '{{count}}x {{period}}'
    },
    debtDetails: {
      personName: {
        label: 'Borçlu Adı',
        placeholder: 'Borçlu adını girin'
      },
      dueDate: {
        label: 'Vade Tarihi',
        format: 'Vade: {{date}}'
      }
    },
    recurrence: {
      label: 'Tekrar',
      enabled: 'Tekrarlanan işlem',
      frequency: {
        label: 'Sıklık',
        daily: 'Günlük',
        weekly: 'Haftalık',
        monthly: 'Aylık',
        yearly: 'Yıllık'
      },
      endDate: {
        label: 'Bitiş Tarihi',
        never: 'Asla'
      }
    },
    currency: {
      label: 'Para Birimi',
      try: 'Türk Lirası (TRY)',
      usd: 'Amerikan Doları (USD)',
      eur: 'Euro (EUR)',
      gbp: 'İngiliz Sterlini (GBP)'
    }
  },
  buttons: {
    add: 'İşlem Ekle',
    save: 'Değişiklikleri Kaydet',
    saveChanges: 'Değişiklikleri Kaydet',
    addTransaction: 'İşlem Ekle',
    cancel: 'İptal',
    close: 'Kapat',
    upload: 'Yükle',
    remove: 'Kaldır'
  }
};
