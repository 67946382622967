import React, { useState, useMemo } from 'react';
import { Users } from 'lucide-react';
import { Wallet, WalletRole } from '../../types/wallet';
import { useWallets } from '../../context/WalletContext';
import { useLanguage } from '../../context/LanguageContext';
import { getTranslations } from '../../locales/components/walletSharingSection';

interface WalletSharingSectionProps {
  wallet: Wallet;
}

const WalletSharingSection: React.FC<WalletSharingSectionProps> = ({ wallet }) => {
  const { shareWallet, removeMember } = useWallets();
  const { language } = useLanguage();
  const translations = getTranslations(language);
  const [showSharingSection, setShowSharingSection] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [newMemberRole, setNewMemberRole] = useState<WalletRole>('editor');

  const members = useMemo(() => {
    if (!wallet.members) return [];
    return Object.entries(wallet.members).map(([email, member]) => ({
      email,
      ...member
    }));
  }, [wallet.members]);

  const handleShare = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await shareWallet(wallet.id, newMemberEmail, newMemberRole);
      setNewMemberEmail('');
      setShowSharingSection(false);
    } catch (error) {
      console.error(translations.errors.shareWallet, error);
    }
  };

  const handleRemoveMember = async (email: string) => {
    if (window.confirm(translations.sharing.confirmRemove)) {
      try {
        await removeMember(wallet.id, email);
      } catch (error) {
        console.error(translations.errors.removeMember, error);
      }
    }
  };

  const getRoleTranslation = (role: WalletRole) => {
    return translations.sharing.roles[role];
  };

  return (
    <div className="pt-4">
      <button
        type="button"
        onClick={() => setShowSharingSection(!showSharingSection)}
        className="flex items-center gap-2 text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300"
      >
        <Users className="h-4 w-4" />
        {showSharingSection ? translations.sharing.hideOptions : translations.sharing.shareWallet}
      </button>

      {showSharingSection && (
        <div className="mt-4 space-y-4">
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
              {translations.sharing.addMember}
            </h3>
            <div className="space-y-3">
              <input
                type="email"
                value={newMemberEmail}
                onChange={e => setNewMemberEmail(e.target.value)}
                placeholder={translations.sharing.emailPlaceholder}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              />
              <select
                value={newMemberRole}
                onChange={e => setNewMemberRole(e.target.value as WalletRole)}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="editor">{translations.sharing.roles.editor}</option>
                <option value="viewer">{translations.sharing.roles.viewer}</option>
              </select>
              <button
                type="button"
                onClick={handleShare}
                className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              >
                {translations.sharing.sendInvitation}
              </button>
            </div>
          </div>

          {members.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                {translations.sharing.currentMembers}
              </h3>
              <div className="space-y-2">
                {members.map(member => (
                  <div
                    key={member.email}
                    className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
                  >
                    <div>
                      <p className="text-sm text-gray-900 dark:text-white">{member.email}</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {getRoleTranslation(member.role)} • {new Date(member.joinedAt).toLocaleDateString()}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveMember(member.email)}
                      className="text-sm text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300"
                    >
                      {translations.sharing.remove}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WalletSharingSection;
