import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Set default persistence to local
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('[Firebase] Persistence error:', error);
});

// Initialize auth state from storage
const storedPersistence = localStorage.getItem('authPersistence');
if (storedPersistence) {
  const persistenceType = storedPersistence === 'local' ? browserLocalPersistence : browserLocalPersistence;
  setPersistence(auth, persistenceType).catch((error) => {
    console.error('[Firebase] Restore persistence error:', error);
  });
}

export { app, auth, db, storage, functions };
