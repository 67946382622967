const translations = {
  login: {
    title: 'Sign in to your account',
    noAccount: "Don't have an account?",
    createAccount: 'Create a free account',
    email: 'Email address',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot your password?',
    submit: 'Sign in',
    errors: {
      invalidEmail: 'Invalid email address',
      invalidPassword: 'Invalid password',
      userNotFound: 'No account found with this email',
      tooManyAttempts: 'Too many attempts. Please try again later.'
    }
  },
  register: {
    title: 'Create your account',
    haveAccount: 'Already have an account?',
    signIn: 'Sign in',
    name: 'Full name',
    email: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    submit: 'Create account',
    errors: {
      emailInUse: 'Email already in use',
      passwordTooWeak: 'Password is too weak',
      passwordsDontMatch: 'Passwords do not match'
    }
  },
  socialSignIn: {
    continueWith: 'Or continue with',
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
    microsoft: 'Microsoft'
  },
  forgotPassword: {
    title: 'Reset your password',
    description: "Enter your email address and we'll send you a link to reset your password.",
    email: 'Email address',
    submit: 'Send reset link',
    success: 'If an account exists with this email, you will receive a password reset link.',
    errors: {
      userNotFound: 'No account found with this email',
      tooManyAttempts: 'Too many attempts. Please try again later.'
    }
  },
  resetPassword: {
    title: 'Set new password',
    password: 'New password',
    confirmPassword: 'Confirm new password',
    submit: 'Reset password',
    success: 'Your password has been reset successfully.',
    errors: {
      invalidToken: 'Invalid or expired reset token',
      passwordTooWeak: 'Password is too weak',
      passwordsDontMatch: 'Passwords do not match'
    }
  },
  verifyEmail: {
    title: 'Verify your email',
    description: 'Please check your email for a verification link.',
    resendButton: 'Resend verification email',
    sending: 'Sending...',
    success: 'Email verified successfully.',
    errors: {
      invalidToken: 'Invalid or expired verification token',
      alreadyVerified: 'Email already verified',
      tooManyAttempts: 'Too many attempts. Please try again later.'
    }
  }
};

export default translations;
