export const creditCardModal = {
  title: {
    new: 'New Credit Card',
    edit: 'Edit Credit Card'
  },
  fields: {
    name: {
      label: 'Card Name',
      placeholder: 'e.g. My Credit Card'
    },
    lastDigits: {
      label: 'Last 4 Digits',
      placeholder: '1234'
    },
    expiryDate: {
      label: 'Expiry Date'
    },
    type: {
      label: 'Card Type',
      options: {
        visa: 'Visa',
        mastercard: 'Mastercard',
        amex: 'American Express',
        other: 'Other'
      }
    },
    limit: {
      label: 'Credit Limit'
    },
    balance: {
      label: 'Current Balance'
    },
    currency: {
      label: 'Currency',
      options: {
        usd: 'USD',
        eur: 'EUR',
        gbp: 'GBP',
        try: 'TRY'
      }
    },
    color: {
      label: 'Card Color',
      options: {
        indigo: 'Indigo',
        emerald: 'Emerald',
        amber: 'Amber'
      }
    }
  },
  payment: {
    title: 'Payment Details',
    date: {
      label: 'Payment Date'
    },
    wallet: {
      label: 'Pay from Wallet',
      placeholder: 'Select a wallet'
    }
  },
  buttons: {} // Common translations will be merged here
};
