import {
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword,
  deleteUser as deleteFirebaseUser,
  User as FirebaseUser,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { User } from '../types/user';
import { handleAuthError } from '../utils/errorHandler';
import { uploadProfilePhoto, deleteProfilePhoto } from './storage';

export const sendVerificationEmail = async (user: FirebaseUser): Promise<void> => {
  try {
    await sendEmailVerification(user);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const changePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user || !user.email) throw new Error('No authenticated user');

    // Re-authenticate user before changing password
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);

    // Change password
    await updatePassword(user, newPassword);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const updateUserProfile = async (userId: string, data: Partial<User>, photoFile?: File): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    
    // Create a clean update object
    const updateData: Record<string, any> = {
      updatedAt: new Date().toISOString()
    };

    // Handle profile photo upload if provided
    if (photoFile) {
      const photoURL = await uploadProfilePhoto(userId, photoFile);
      updateData.avatar = photoURL;

      // Update Firebase Auth profile with new photo URL
      if (auth.currentUser && auth.currentUser.uid === userId) {
        await updateProfile(auth.currentUser, { photoURL });
      }
    }

    // Safely add each field to the update object
    if (data.name !== undefined) {
      updateData.name = data.name;
      // Update Firebase Auth profile if name is being updated
      if (auth.currentUser && auth.currentUser.uid === userId) {
        await updateProfile(auth.currentUser, { displayName: data.name });
      }
    }

    if (data.settings !== undefined) {
      // Handle settings as a nested object
      updateData.settings = {};
      
      if (data.settings.language !== undefined) {
        updateData.settings.language = data.settings.language;
      }
      
      if (data.settings.currency !== undefined) {
        updateData.settings.currency = data.settings.currency;
      }
      
      if (data.settings.theme !== undefined) {
        updateData.settings.theme = data.settings.theme;
      }
      
      if (data.settings.notifications !== undefined) {
        updateData.settings.notifications = data.settings.notifications;
      }
    }

    if (data.subscription !== undefined) {
      updateData.subscription = {
        plan: data.subscription.plan,
        status: data.subscription.status,
        expiresAt: data.subscription.expiresAt
      };
    }

    // If avatar is being removed
    if (data.avatar === null) {
      updateData.avatar = null;
      if (auth.currentUser && auth.currentUser.uid === userId) {
        await updateProfile(auth.currentUser, { photoURL: null });
        await deleteProfilePhoto(userId);
      }
    }

    await updateDoc(userRef, updateData);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const deleteUser = async (userId: string): Promise<void> => {
  try {
    // Delete profile photo from storage
    await deleteProfilePhoto(userId);

    // Delete user document from Firestore
    const userRef = doc(db, 'users', userId);
    await deleteDoc(userRef);
    
    // If the user is currently signed in, delete their Firebase Auth account
    if (auth.currentUser?.uid === userId) {
      await deleteFirebaseUser(auth.currentUser);
    }
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};
