import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Budget } from '../../types/budget';
import { useCategories } from '../../context/CategoryContext';
import { useBudgets } from '../../context/BudgetContext';
import { useWallets } from '../../context/WalletContext';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { getTranslations } from '../../locales/components/budgetModal';

interface BudgetModalProps {
  isOpen: boolean;
  onClose: () => void;
  budget?: Budget | null;
}

const BudgetModal: React.FC<BudgetModalProps> = ({ isOpen, onClose, budget }) => {
  const { categories } = useCategories();
  const { addBudget, updateBudget } = useBudgets();
  const { wallets } = useWallets();
  const { user } = useAuth();
  const { language } = useLanguage();
  const t = getTranslations(language);
  
  const [formData, setFormData] = useState<{
    name: string;
    amount: number;
    currency: string;
    categoryIds: string[];
    wallets: string[];
    period: Budget['period'];
    startDate: string;
    endDate: string;
  }>({
    name: '',
    amount: 0,
    currency: user?.settings?.currency || 'TRY',
    categoryIds: [],
    wallets: [],
    period: 'monthly',
    startDate: new Date().toISOString().split('T')[0],
    endDate: ''
  });

  useEffect(() => {
    if (budget) {
      setFormData({
        name: budget.name,
        amount: budget.amount,
        currency: budget.currency || user?.settings?.currency || 'TRY',
        categoryIds: budget.categoryIds || [],
        wallets: budget.wallets || [],
        period: budget.period,
        startDate: budget.startDate,
        endDate: budget.endDate || ''
      });
    } else {
      setFormData(prev => ({
        ...prev,
        name: '',
        amount: 0,
        currency: user?.settings?.currency || 'TRY',
        categoryIds: [],
        wallets: [],
        startDate: new Date().toISOString().split('T')[0],
        endDate: ''
      }));
    }
  }, [budget, user]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (budget) {
        await updateBudget(budget.id, formData);
      } else {
        await addBudget(formData);
      }
      onClose();
    } catch (error) {
      console.error('Failed to save budget:', error);
    }
  };

  const toggleWallet = (walletId: string) => {
    setFormData(prev => ({
      ...prev,
      wallets: prev.wallets.includes(walletId)
        ? prev.wallets.filter(w => w !== walletId)
        : [...prev.wallets, walletId]
    }));
  };

  const toggleCategory = (categoryId: string) => {
    setFormData(prev => ({
      ...prev,
      categoryIds: prev.categoryIds.includes(categoryId)
        ? prev.categoryIds.filter(c => c !== categoryId)
        : [...prev.categoryIds, categoryId]
    }));
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 dark:bg-black/70 transition-opacity duration-300"
        onClick={onClose}
      />
      
      {/* Drawer */}
      <div className={`absolute top-0 right-0 h-full w-full max-w-xl bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="h-full flex flex-col">
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {budget ? t.title.edit : t.title.new}
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* General Info Section */}
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-lg font-medium text-gray-900 dark:text-white">
                  <span>💰</span>
                  <span>{t.sections.generalInfo.title}</span>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" title={t.tooltips.name}>
                    {t.sections.generalInfo.fields.name.label}
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={e => setFormData(d => ({ ...d, name: e.target.value }))}
                    className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-white"
                    placeholder={t.sections.generalInfo.fields.name.placeholder}
                    required
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" title={t.tooltips.amount}>
                      {t.sections.generalInfo.fields.amount.label}
                    </label>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={formData.amount}
                      onChange={e => setFormData(d => ({ ...d, amount: parseFloat(e.target.value) }))}
                      className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-white"
                      placeholder={t.sections.generalInfo.fields.amount.placeholder}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {t.sections.generalInfo.fields.currency.label}
                    </label>
                    <input
                      type="text"
                      value={formData.currency}
                      disabled
                      className="w-full px-3 py-2 bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg text-gray-500 dark:text-gray-400"
                    />
                  </div>
                </div>
              </div>

              {/* Budget Filter Section */}
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-lg font-medium text-gray-900 dark:text-white">
                  <span>🔍</span>
                  <span>{t.sections.filter.title}</span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2" title={t.tooltips.wallets}>
                    {t.sections.filter.fields.wallets.label}
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {wallets?.map(wallet => (
                      <button
                        key={wallet.id}
                        type="button"
                        onClick={() => toggleWallet(wallet.id)}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                          formData.wallets.includes(wallet.id)
                            ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300'
                            : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                        }`}
                      >
                        {wallet.name}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2" title={t.tooltips.categories}>
                    {t.sections.filter.fields.categories.label}
                  </label>
                  <div className="flex flex-wrap gap-2">
                    <button
                      type="button"
                      onClick={() => setFormData(d => ({ ...d, categoryIds: [] }))}
                      className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                        formData.categoryIds.length === 0
                          ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300'
                          : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                      }`}
                    >
                      {t.sections.filter.fields.categories.allCategories}
                    </button>
                    {categories?.map(category => (
                      <button
                        key={category.id}
                        type="button"
                        onClick={() => toggleCategory(category.id)}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                          formData.categoryIds.includes(category.id)
                            ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300'
                            : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Budget Period Section */}
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-lg font-medium text-gray-900 dark:text-white">
                  <span>📅</span>
                  <span>{t.sections.period.title}</span>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2" title={t.tooltips.recurrence}>
                    {t.sections.period.fields.recurrence.label}
                  </label>
                  <div className="grid grid-cols-3 gap-2">
                    {['once', 'daily', 'weekly', 'biweekly', 'monthly', 'yearly'].map((period) => (
                      <button
                        key={period}
                        type="button"
                        onClick={() => setFormData(d => ({ ...d, period: period as Budget['period'] }))}
                        className={`px-4 py-2 rounded-lg text-sm font-medium capitalize transition-colors ${
                          formData.period === period
                            ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300'
                            : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                        }`}
                      >
                        {t.sections.period.fields.recurrence.options[period as keyof typeof t.sections.period.fields.recurrence.options]}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" title={t.tooltips.startDate}>
                    {t.sections.period.fields.startDate.label}
                  </label>
                  <input
                    type="date"
                    value={formData.startDate}
                    onChange={e => setFormData(d => ({ ...d, startDate: e.target.value }))}
                    className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-white"
                    placeholder={t.sections.period.fields.startDate.placeholder}
                    required
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="p-6 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
              >
                {t.buttons.cancel}
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400"
              >
                {budget ? t.buttons.save : t.buttons.create}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetModal;
