import en from './en';
import tr from './tr';

export const translations = {
  en,
  tr
};

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
