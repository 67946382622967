const translations = {
  startDate: 'Başlangıç Tarihi',
  endDate: 'Bitiş Tarihi',
  presets: {
    thisWeek: 'Bu Hafta',
    lastWeek: 'Geçen Hafta',
    thisMonth: 'Bu Ay',
    lastMonth: 'Geçen Ay',
    last3Month: 'Son 3 Ay',
    thisYear: 'Bu Yıl',
    lastYear: 'Geçen Yıl',
    allTime: 'Tüm Zamanlar'
  }
};

export default translations;
