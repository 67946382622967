import { collection, doc, writeBatch } from 'firebase/firestore';
import {
  addDoc,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  orderBy,
  where,
  getDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface PlatformCategory {
  id?: string;
  name: string;
  slug: string;
  description?: string;
  icon?: string;
  createdAt?: string;
  updatedAt?: string;
}

// Helper function to get collection reference
const getCategoriesCollection = () => collection(db, 'platform_categories');

// Initialize with default categories if empty
export const initializeCategories = async (): Promise<void> => {
  const categoriesCollection = getCategoriesCollection();
  // Check if categories already exist
  const snapshot = await getDocs(categoriesCollection);
  if (!snapshot.empty) {
    return; // Categories already exist, don't initialize
  }

  const defaultCategories = [
    { name: 'Entertainment', slug: 'entertainment', description: 'Streaming and media services' },
    { name: 'Productivity', slug: 'productivity', description: 'Tools for work and productivity' },
    { name: 'Business', slug: 'business', description: 'Business and professional services' },
    { name: 'Education', slug: 'education', description: 'Learning and educational platforms' },
    { name: 'Lifestyle', slug: 'lifestyle', description: 'Health and lifestyle services' }
  ];

  const batch = writeBatch(db);
  
  for (const category of defaultCategories) {
    const docRef = doc(categoriesCollection);
    batch.set(docRef, {
      ...category,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }

  await batch.commit();
};

export const createCategory = async (data: Omit<PlatformCategory, 'id'>): Promise<string> => {
  const categoriesCollection = getCategoriesCollection();
  const categoryData = {
    ...data,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(categoriesCollection, categoryData);
  return docRef.id;
};

export const getAllCategories = async (): Promise<PlatformCategory[]> => {
  const categoriesCollection = getCategoriesCollection();
  const q = query(categoriesCollection, orderBy('name', 'asc'));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as PlatformCategory));
};

export const updateCategory = async (
  categoryId: string,
  data: Partial<PlatformCategory>
): Promise<void> => {
  const categoriesCollection = getCategoriesCollection();
  const categoryRef = doc(categoriesCollection, categoryId);
  await updateDoc(categoryRef, {
    ...data,
    updatedAt: new Date().toISOString()
  });
};

export const deleteCategory = async (categoryId: string): Promise<void> => {
  const categoriesCollection = getCategoriesCollection();
  // Get the category to find its slug
  const categoryRef = doc(categoriesCollection, categoryId);
  const categoryDoc = await getDoc(categoryRef);
  
  if (!categoryDoc.exists()) {
    throw new Error('Category not found');
  }
  
  const categoryData = categoryDoc.data() as PlatformCategory;

  // Check if any platforms are using this category's slug
  const platformsRef = collection(db, 'subscription_platforms');
  const q = query(platformsRef, where('category', '==', categoryData.slug));
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    throw new Error('Cannot delete category: There are platforms using this category');
  }

  // If no platforms are using this category, delete it
  await deleteDoc(categoryRef);
};
