import React from 'react';
import { Transaction } from '../../types/transaction';
import TransactionModalForm from './TransactionModalForm';

interface TransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => void;
  initialData?: Transaction;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}) => {
  return (
    <TransactionModalForm
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      initialData={initialData}
    />
  );
};

export default TransactionModal;
