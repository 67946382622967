import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallets } from '../../context/WalletContext';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { Plus } from 'lucide-react';
import WalletCard from './WalletCard';
import WalletModal from './WalletModal';
import { checkPlanLimit } from '../../utils/planLimits';
import PlanLimitModal from '../PlanLimitModal';

const WalletsGrid: React.FC = () => {
  const navigate = useNavigate();
  const { wallets, addWallet } = useWallets();
  const { user } = useAuth();
  const { t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPlanLimitModal, setShowPlanLimitModal] = useState(false);

  const handleAddWalletClick = () => {
    const canAddWallet = checkPlanLimit(
      user?.subscription?.plan || 'free',
      'wallets',
      wallets.length
    );

    if (!canAddWallet) {
      setShowPlanLimitModal(true);
      return;
    }

    setIsModalOpen(true);
  };

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">
          {t('dashboard.overview.wallets.title')}
        </h2>
        <button
          onClick={handleAddWalletClick}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Plus className="h-4 w-4 mr-2" />
          {t('dashboard.overview.wallets.new')}
        </button>
      </div>

      {wallets.length === 0 ? (
        <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            {t('dashboard.overview.wallets.empty.title')}
          </h3>
          <p className="text-gray-500">
            {t('dashboard.overview.wallets.empty.description')}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {wallets.map((wallet) => (
            <WalletCard 
              key={wallet.id} 
              wallet={wallet} 
              onClick={() => navigate(`/wallets/${wallet.id}`)}
            />
          ))}
        </div>
      )}

      <WalletModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={addWallet}
      />

      <PlanLimitModal
        isOpen={showPlanLimitModal}
        onClose={() => setShowPlanLimitModal(false)}
        feature="wallets"
      />
    </div>
  );
};

export default WalletsGrid;
