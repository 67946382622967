import { TranslationModule } from '../../types';
import { SidebarTranslations } from './types';
import { translations as enTranslations } from './en';
import { translations as trTranslations } from './tr';

export const translations: TranslationModule<SidebarTranslations> = {
  en: enTranslations,
  tr: trTranslations
};

// Re-export the types
export type { SidebarTranslations };

// Helper to get translations for a specific language
export const getTranslations = (lang: 'en' | 'tr'): SidebarTranslations => {
  return translations[lang];
};

// Legacy exports for backward compatibility
export const sidebar = enTranslations;
