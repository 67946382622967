import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { getTranslations } from '../../locales/components/dateRangePicker';
import { formatDate } from '../../utils/formatters';

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onChange: (dates: { start: string; end: string }) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { language } = useLanguage();
  const translations = getTranslations(language);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const formatDateRange = (start: string, end: string) => {
    return `${formatDate(start, language)} - ${formatDate(end, language)}`;
  };

  const presets = [
    { label: translations.presets.thisWeek, getValue: () => {
      const now = new Date();
      const start = new Date(now.setDate(now.getDate() - now.getDay()));
      const end = new Date();
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.lastWeek, getValue: () => {
      const now = new Date();
      const start = new Date(now.setDate(now.getDate() - now.getDay() - 7));
      const end = new Date(now.setDate(now.getDate() + 6));
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.thisMonth, getValue: () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth(), 1);
      const end = new Date();
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.lastMonth, getValue: () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const end = new Date(now.getFullYear(), now.getMonth(), 0);
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.last3Month, getValue: () => {
      const now = new Date();
      const start = new Date(now.setMonth(now.getMonth() - 3));
      const end = new Date();
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.thisYear, getValue: () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), 0, 1);
      const end = new Date();
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.lastYear, getValue: () => {
      const now = new Date();
      const start = new Date(now.getFullYear() - 1, 0, 1);
      const end = new Date(now.getFullYear() - 1, 11, 31);
      return { start: start.toISOString().split('T')[0], end: end.toISOString().split('T')[0] };
    }},
    { label: translations.presets.allTime, getValue: () => {
      return { start: '2020-01-01', end: new Date().toISOString().split('T')[0] };
    }}
  ];

  const handlePresetClick = (preset: typeof presets[0]) => {
    const dates = preset.getValue();
    onChange(dates);
    setSelectedPreset(preset.label);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
      >
        <Calendar className="h-4 w-4" />
        <span>{selectedPreset || formatDateRange(startDate, endDate)}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-100 dark:border-gray-700 z-10">
          <div className="p-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                  {translations.startDate}
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={e => onChange({ start: e.target.value, end: endDate })}
                  className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                  {translations.endDate}
                </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={e => onChange({ start: startDate, end: e.target.value })}
                  className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                />
              </div>
            </div>

            <div className="mt-4 space-y-2">
              {presets.map(preset => (
                <button
                  key={preset.label}
                  type="button"
                  onClick={() => handlePresetClick(preset)}
                  className="w-full text-left px-3 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                >
                  {preset.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
