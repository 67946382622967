export default {
  title: 'Delete Account',
  message: 'This action cannot be undone. All your data will be permanently deleted. Please type "delete" to confirm.',
  confirmText: 'delete',
  buttons: {
    cancel: 'Cancel',
    delete: 'Delete Account'
  },
  error: {
    invalidConfirmation: 'Please type "delete" to confirm'
  }
};
