import React, { useMemo, useState } from 'react';
import { useTransactions } from '../context/TransactionContext';
import { useDateFilter } from '../context/DateFilterContext';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import StatsGrid from './stats/StatsGrid';
import WalletsGrid from './wallets/WalletsGrid';
import DateRangePicker from './reports/DateRangePicker';
import TransactionModal from './transactions/TransactionModal';
import PlanLimitModal from './PlanLimitModal';
import UpcomingPayments from './dashboard/UpcomingPayments';
import { Plus } from 'lucide-react';
import { checkPlanLimit } from '../utils/planLimits';
import { Transaction } from '../types';

type TransactionFormData = {
  description: string;
  amount: number;
  type: 'income' | 'expense' | 'debt' | 'subscription';
  category: string;
  walletId: string;
  date: string;
  labels: string[];
  note?: string;
  attachments?: string[];
  paymentMethod: 'cash' | 'credit';
  currency: string;
  creditCardId?: string;
  debtDetails?: {
    personName: string;
    dueDate: string;
  };
  installments?: {
    enabled: boolean;
    count: number;
    current: number;
    period: 'monthly' | 'weekly';
  };
};

const Dashboard: React.FC = () => {
  const { dateRange, setDateRange } = useDateFilter();
  const { transactions, addTransaction } = useTransactions();
  const { user } = useAuth();
  const { t } = useLanguage();
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [showPlanLimitModal, setShowPlanLimitModal] = useState(false);

  const filteredTransactions = useMemo(() => {
    return transactions.filter((t: Transaction) => {
      const transactionDate = new Date(t.date);
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);
      return transactionDate >= startDate && transactionDate <= endDate;
    });
  }, [transactions, dateRange]);

  const stats = useMemo(() => {
    const income = filteredTransactions
      .filter((t: Transaction) => t.type === 'income')
      .reduce((sum: number, t: Transaction) => sum + t.amount, 0);
    
    const expenses = Math.abs(
      filteredTransactions
        .filter((t: Transaction) => t.type === 'expense')
        .reduce((sum: number, t: Transaction) => sum + t.amount, 0)
    );
    
    const balance = income - expenses;
    const savingsRate = income > 0 ? ((income - expenses) / income) * 100 : 0;

    return {
      balance,
      income,
      expenses,
      savingsRate
    };
  }, [filteredTransactions]);

  const handleAddTransactionClick = () => {
    if (!user) return;

    // If user is admin or superadmin, skip plan limit check
    if (user.role === 'admin' || user.role === 'superadmin') {
      setIsTransactionModalOpen(true);
      return;
    }

    // For regular users, check plan limits
    const canAddTransaction = checkPlanLimit(
      'premium', // Since we know they're a premium user
      'transactions',
      transactions.length
    );

    if (!canAddTransaction) {
      setShowPlanLimitModal(true);
      return;
    }

    setIsTransactionModalOpen(true);
  };

  const handleSubmit = async (formData: TransactionFormData) => {
    if (!user) {
      throw new Error('User must be authenticated');
    }

    const timestamp = new Date().toISOString();

    // Ensure installments has the required 'current' property
    const installments = formData.installments ? {
      ...formData.installments,
      current: formData.installments.current || 1 // Default to 1 if not provided
    } : undefined;

    const completeData: Omit<Transaction, 'id'> = {
      ...formData,
      installments,
      userId: user.id,
      createdAt: timestamp,
      updatedAt: timestamp
    };

    try {
      await addTransaction(completeData);
      setIsTransactionModalOpen(false);
    } catch (error) {
      console.error('Failed to add transaction:', error);
      throw error; // Re-throw to let the form handle the error
    }
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('dashboard.welcome')}
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            {t('dashboard.overview.title')}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <DateRangePicker
            startDate={dateRange.start}
            endDate={dateRange.end}
            onChange={setDateRange}
          />
          <button
            onClick={handleAddTransactionClick}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Plus className="h-4 w-4 mr-2" />
            {t('dashboard.overview.newTransaction')}
          </button>
        </div>
      </div>

      <StatsGrid stats={stats} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
        <WalletsGrid />
        <UpcomingPayments />
      </div>

      <TransactionModal
        isOpen={isTransactionModalOpen}
        onClose={() => setIsTransactionModalOpen(false)}
        onSubmit={handleSubmit}
      />

      <PlanLimitModal
        isOpen={showPlanLimitModal}
        onClose={() => setShowPlanLimitModal(false)}
        feature="transactions"
      />
    </div>
  );
};

export default Dashboard;
