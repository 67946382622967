import React, { useEffect, useState } from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { ExchangeRates as ExchangeRatesType } from '../services/exchangeRates';
import { subscribeToExchangeRates, fetchExchangeRates } from '../services/exchangeRates';
import { useCurrency } from '../context/CurrencyContext';
import { useLanguage } from '../context/LanguageContext';
import { formatNumber, formatTime } from '../utils/formatters';

const ExchangeRates: React.FC = () => {
  const [rates, setRates] = useState<ExchangeRatesType | null>(null);
  const [previousRates, setPreviousRates] = useState<{[key: string]: number}>({});
  const { defaultCurrency } = useCurrency();
  const { language } = useLanguage();
  const userCurrency = defaultCurrency.toUpperCase();

  useEffect(() => {
    // Initial fetch
    fetchExchangeRates().then(initialRates => {
      setRates(initialRates);
      setPreviousRates(initialRates.rates);
    });

    // Subscribe to updates
    const unsubscribe = subscribeToExchangeRates((newRates) => {
      setPreviousRates(rates?.rates || {});
      setRates(newRates);
    });

    return () => unsubscribe();
  }, []);

  if (!rates) return null;

  // Get the rate change indicator
  const getRateChange = (fromCurrency: string, toCurrency: string) => {
    if (!previousRates[toCurrency]) return null;

    const oldRate = previousRates[toCurrency] / previousRates[fromCurrency];
    const newRate = rates.rates[toCurrency] / rates.rates[fromCurrency];
    
    if (newRate > oldRate) {
      return <ArrowUpRight className="h-3 w-3 text-green-500" />;
    } else if (newRate < oldRate) {
      return <ArrowDownRight className="h-3 w-3 text-red-500" />;
    }
    return null;
  };

  // Calculate exchange rate between two currencies
  const getExchangeRate = (fromCurrency: string, toCurrency: string): number => {
    const fromRate = rates.rates[fromCurrency];
    const toRate = rates.rates[toCurrency];
    return toRate / fromRate;
  };

  // Filter out user's current currency and get top 3 most used currencies
  const displayCurrencies = Object.keys(rates.rates)
    .filter(currency => currency !== userCurrency)
    .slice(0, 3);

  return (
    <div className="hidden lg:flex items-center space-x-4 px-4 py-2 bg-gray-50 dark:bg-gray-800 rounded-lg">
      {displayCurrencies.map(currency => {
        const rate = getExchangeRate(userCurrency, currency);
        return (
          <div key={currency} className="flex items-center space-x-1">
            <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
              1 {userCurrency} =
            </span>
            <span className="text-sm font-semibold text-gray-900 dark:text-white">
              {formatNumber(rate, language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
            </span>
            {getRateChange(userCurrency, currency)}
          </div>
        );
      })}
      {rates.lastUpdated && (
        <div className="text-xs text-gray-400 dark:text-gray-500">
          {formatTime(rates.lastUpdated, language)}
        </div>
      )}
    </div>
  );
};

export default ExchangeRates;
