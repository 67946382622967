const translations = {
  title: 'Cüzdanı Paylaş',
  hideOptions: 'Paylaşım Seçeneklerini Gizle',
  addMember: 'Üye Ekle',
  emailPlaceholder: 'E-posta adresi girin',
  currentMembers: 'Mevcut Üyeler',
  sendInvitation: 'Davet Gönder',
  remove: 'Kaldır',
  confirmRemove: 'Bu üyeyi kaldırmak istediğinizden emin misiniz?',
  roles: {
    title: 'Erişim Seviyesi',
    owner: 'Sahip',
    editor: 'Düzenleyebilir',
    viewer: 'Sadece görüntüleyebilir'
  },
  invitationSent: 'Davet başarıyla gönderildi',
  invitationError: 'Davet gönderilirken hata oluştu',
  invalidInvitation: 'Geçersiz davet bağlantısı',
  invitationAccepted: 'Davet başarıyla kabul edildi',
  acceptError: 'Davet kabul edilirken hata oluştu',
  processingInvitation: 'Davet İşleniyor',
  pleaseWait: 'Lütfen davetiniz işlenirken bekleyin...'
};

export default translations;
