import en from './en';
import tr from './tr';

export interface WalletTranslations {
  title: string;
  subtitle: string;
  new: string;
  allWallets: string;
  balance: string;
  actions: {
    edit: string;
    delete: string;
    share: string;
  };
  types: {
    cash: string;
    bank: string;
    investment: string;
    other: string;
  };
  fields: {
    name: string;
    type: string;
    currency: string;
    initialBalance: string;
    currentBalance: string;
  };
  messages: {
    deleteConfirmation: string;
    deleteSuccess: string;
    createSuccess: string;
    updateSuccess: string;
  };
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
