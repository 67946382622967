import { Budget } from '../types/budget';
import { Transaction } from '../types/transaction';
import { getTransactionsByBudget } from '../services/transactions';
import { updateBudget } from '../services/budgets';

export const recalculateBudgetSpending = async (budgetId: string): Promise<void> => {
  try {
    // Fetch all transactions for this budget
    const transactions = await getTransactionsByBudget(budgetId);
    
    // Calculate total spent amount (only for expense transactions)
    const spent = transactions.reduce((sum: number, transaction: Transaction) => {
      // Only count expense transactions
      if (transaction.type === 'expense') {
        return sum + Math.abs(transaction.amount);
      }
      return sum;
    }, 0);
    
    // Update budget with recalculated spent amount
    await updateBudget(budgetId, {
      spent,
      remaining: 0 // This will be recalculated in the updateBudget method
    });
  } catch (error) {
    console.error('Failed to recalculate budget spending:', error);
    throw error;
  }
};

export const resetAllBudgetSpendings = async (budgets: Budget[]): Promise<void> => {
  for (const budget of budgets) {
    await recalculateBudgetSpending(budget.id);
  }
};
