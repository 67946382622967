import React, { useState, useRef, useEffect } from 'react';
import { X, CreditCard, Calendar, Wallet as WalletIcon, ArrowUpCircle, ArrowDownCircle, HandCoins, Plus, Upload, Repeat, Layers, Loader2, FileText } from 'lucide-react';
import { Transaction } from '../../types/transaction';
import { useLanguage } from '../../context/LanguageContext';
import { useCreditCards } from '../../context/CreditCardContext';
import { useBudgets } from '../../context/BudgetContext';
import { getTranslations } from '../../locales/components/transactionModal';
import LabelTag from '../labels/LabelTag';
import { useTransactionForm } from './useTransactionForm';

interface TransactionModalFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => void;
  initialData?: Transaction;
}

const TransactionModalForm: React.FC<TransactionModalFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}) => {
  const { language } = useLanguage();
  const { creditCards } = useCreditCards();
  const { budgets } = useBudgets();
  const t = getTranslations(language);

  const {
    formData,
    associatedBudget,
    filteredCategories,
    wallets,
    labels,
    uploadingFiles,
    handleFieldChange,
    handleLabelAdd,
    handleLabelRemove,
    handleFileChange,
    removeAttachment,
    getTypeColor,
    getLabelSuggestions,
    handleSubmit
  } = useTransactionForm(initialData, onSubmit);

  const [labelInput, setLabelInput] = useState('');
  const [suggestions, setSuggestions] = useState<{ id: string; name: string }[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isAmountFocused, setIsAmountFocused] = useState(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Reset local state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setLabelInput('');
      setSuggestions([]);
      setShowSuggestions(false);
      setIsAmountFocused(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLabelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLabelInput(value);
    const newSuggestions = getLabelSuggestions(value);
    setSuggestions(newSuggestions);
    setShowSuggestions(true);
  };

  const handleLabelInputKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (labelInput.trim()) {
        await handleLabelAdd(labelInput.trim());
        setLabelInput('');
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }
  };

  const handleSuggestionClick = async (suggestion: { id: string; name: string }) => {
    await handleLabelAdd(suggestion.name);
    setLabelInput('');
    setSuggestions([]);
    setShowSuggestions(false);
    inputRef.current?.focus();
  };

  const getLabelName = (labelId: string) => {
    return labels.find(label => label.id === labelId)?.name || labelId;
  };

  const getIconComponent = (type: Transaction['type']) => {
    switch (type) {
      case 'income':
        return <ArrowUpCircle className="h-6 w-6 text-green-600 dark:text-green-400" />;
      case 'expense':
        return <ArrowDownCircle className="h-6 w-6 text-red-600 dark:text-red-400" />;
      case 'debt':
        return <HandCoins className="h-6 w-6 text-blue-600 dark:text-blue-400" />;
      default:
        return <ArrowDownCircle className="h-6 w-6 text-gray-600 dark:text-gray-400" />;
    }
  };

  const handleAmountFocus = () => {
    setIsAmountFocused(true);
    if (formData.amount === 0) {
      handleFieldChange('amount', '');
    }
  };

  const handleAmountBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsAmountFocused(false);
    if (e.target.value === '') {
      handleFieldChange('amount', 0);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    await handleSubmit(e);
    onClose(); // Close modal after successful submission
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? '' : 'pointer-events-none'}`}>
      {/* Backdrop */}
      <div 
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isOpen ? 'opacity-50' : 'opacity-0'
        }`} 
        onClick={onClose}
      />
      
      {/* Drawer */}
      <div className={`fixed inset-y-0 right-0 w-[500px] bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <form onSubmit={handleFormSubmit} className="h-full flex flex-col">
          {/* Header */}
          <div className="sticky top-0 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-6 py-4 flex items-center justify-between z-10">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {initialData ? t.title.edit : t.title.new}
            </h2>
            <button 
              type="button"
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          {/* Transaction Type Selector */}
          <div className="sticky top-[72px] bg-white dark:bg-gray-800 z-10 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex gap-2">
              {['expense', 'income', 'debt'].map((type) => (
                <button
                  key={type}
                  type="button"
                  onClick={() => handleFieldChange('type', type as Transaction['type'])}
                  className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors ${
                    formData.type === type
                      ? `bg-${type === 'expense' ? 'red' : type === 'income' ? 'green' : 'blue'}-100 
                         text-${type === 'expense' ? 'red' : type === 'income' ? 'green' : 'blue'}-700 
                         dark:bg-${type === 'expense' ? 'red' : type === 'income' ? 'green' : 'blue'}-900/30 
                         dark:text-${type === 'expense' ? 'red' : type === 'income' ? 'green' : 'blue'}-300`
                      : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
                  }`}
                >
                  {type === 'expense' && <ArrowDownCircle className="h-5 w-5" />}
                  {type === 'income' && <ArrowUpCircle className="h-5 w-5" />}
                  {type === 'debt' && <HandCoins className="h-5 w-5" />}
                  {t.fields.type.options[type as keyof typeof t.fields.type.options]}
                </button>
              ))}
            </div>
          </div>

          {/* Main Transaction Card */}
          <div className={`sticky top-[144px] z-10 px-6 py-4 ${getTypeColor()} transition-colors duration-200`}>
            <div className="flex flex-col space-y-4">
              <div className="flex items-start gap-4">
                <div className="p-3 rounded-full bg-white dark:bg-gray-700 shadow-sm">
                  {getIconComponent(formData.type)}
                </div>
                <div className="flex-1 min-w-0">
                  <input
                    type="text"
                    value={formData.description}
                    onChange={(e) => handleFieldChange('description', e.target.value)}
                    placeholder={t.fields.description.placeholder}
                    className="w-full text-xl font-medium bg-transparent border-0 border-b-2 border-transparent focus:border-indigo-500 focus:ring-0 dark:text-white px-0"
                    required
                  />
                  <div className="mt-2 flex items-center gap-4 text-sm text-gray-600 dark:text-gray-400">
                    <div className="flex items-center gap-1">
                      <Calendar className="h-4 w-4" />
                      <input
                        type="date"
                        value={formData.date}
                        onChange={(e) => handleFieldChange('date', e.target.value)}
                        className="bg-transparent border-0 p-0 focus:ring-0"
                        required
                      />
                    </div>
                    <div className="flex items-center gap-1">
                      <WalletIcon className="h-4 w-4" />
                      <select
                        value={formData.walletId}
                        onChange={(e) => handleFieldChange('walletId', e.target.value)}
                        className="bg-transparent border-0 p-0 focus:ring-0"
                        required
                      >
                        <option value="">{t.fields.wallet.placeholder}</option>
                        {wallets.map(wallet => (
                          <option key={wallet.id} value={wallet.id}>{wallet.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200/50 dark:border-gray-700/50 pt-4">
                <div className="flex items-center justify-end gap-2">
                  <input
                    type="number"
                    value={formData.amount}
                    onChange={(e) => handleFieldChange('amount', e.target.value === '' ? '' : parseFloat(e.target.value))}
                    onFocus={handleAmountFocus}
                    onBlur={handleAmountBlur}
                    className="text-3xl font-bold bg-transparent border-0 focus:ring-0 text-right w-48"
                    required
                  />
                  <select
                    value={formData.currency}
                    onChange={(e) => handleFieldChange('currency', e.target.value)}
                    className="text-xl bg-transparent border-0 focus:ring-0 font-medium"
                  >
                    <option value="TRY">{t.fields.currency.try}</option>
                    <option value="USD">{t.fields.currency.usd}</option>
                    <option value="EUR">{t.fields.currency.eur}</option>
                    <option value="GBP">{t.fields.currency.gbp}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto">
            <div className="space-y-6 p-6">
              {/* Category */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {t.fields.category.label}
                </label>
                <select
                  value={formData.category}
                  onChange={(e) => handleFieldChange('category', e.target.value)}
                  className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                  required
                >
                  <option value="">{t.fields.category.placeholder}</option>
                  {filteredCategories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Associated Budget Info */}
              {associatedBudget && (
                <div className="text-sm text-gray-600 dark:text-gray-400 bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                  {t.fields.budget.associatedBudget}: {budgets.find(b => b.id === associatedBudget)?.name}
                </div>
              )}

              {/* Payment Method */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Payment Method
                </label>
                <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={() => handleFieldChange('paymentMethod', 'cash')}
                    className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors ${
                      formData.paymentMethod === 'cash'
                        ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
                    }`}
                  >
                    <HandCoins className="h-5 w-5" />
                    Cash
                  </button>
                  <button
                    type="button"
                    onClick={() => handleFieldChange('paymentMethod', 'credit')}
                    className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors ${
                      formData.paymentMethod === 'credit'
                        ? 'bg-purple-100 text-purple-700 dark:bg-purple-900/30 dark:text-purple-300'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
                    }`}
                  >
                    <CreditCard className="h-5 w-5" />
                    Credit Card
                  </button>
                </div>
              </div>

              {/* Credit Card Selection */}
              {formData.paymentMethod === 'credit' && (
                <div className="space-y-2">
                  <select
                    value={formData.creditCardId || ''}
                    onChange={(e) => handleFieldChange('creditCardId', e.target.value)}
                    className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                    required
                  >
                    <option value="">{t.fields.creditCard.placeholder}</option>
                    {creditCards.map(card => (
                      <option key={card.id} value={card.id}>
                        {card.name} (*{card.lastFourDigits})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Installments Toggle */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Layers className="h-5 w-5 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t.fields.installments.label}
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleFieldChange('installments', {
                      enabled: !formData.installments?.enabled,
                      count: formData.installments?.count || 2,
                      current: 1,
                      period: formData.installments?.period || 'monthly'
                    })}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                      formData.installments?.enabled
                        ? 'bg-indigo-600'
                        : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                        formData.installments?.enabled ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
                {formData.installments?.enabled && (
                  <div className="ml-7 space-y-4">
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          {t.fields.installments.count}
                        </label>
                        <input
                          type="number"
                          min="2"
                          value={formData.installments.count}
                          onChange={(e) => handleFieldChange('installments', {
                            ...formData.installments,
                            count: parseInt(e.target.value)
                          })}
                          className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          {t.fields.installments.period}
                        </label>
                        <select
                          value={formData.installments.period}
                          onChange={(e) => handleFieldChange('installments', {
                            ...formData.installments,
                            period: e.target.value as 'weekly' | 'monthly'
                          })}
                          className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                        >
                          <option value="weekly">{t.fields.installments.periods.weekly}</option>
                          <option value="monthly">{t.fields.installments.periods.monthly}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Recurrence Toggle */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Repeat className="h-5 w-5 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t.fields.recurrence.label}
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleFieldChange('recurrence', {
                      enabled: !formData.recurrence?.enabled,
                      frequency: formData.recurrence?.frequency || 'monthly',
                      endDate: formData.recurrence?.endDate || null
                    })}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                      formData.recurrence?.enabled
                        ? 'bg-indigo-600'
                        : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                        formData.recurrence?.enabled ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
                {formData.recurrence?.enabled && (
                  <div className="ml-7 space-y-4">
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          {t.fields.recurrence.frequency.label}
                        </label>
                        <select
                          value={formData.recurrence.frequency}
                          onChange={(e) => handleFieldChange('recurrence', {
                            ...formData.recurrence,
                            frequency: e.target.value as 'daily' | 'weekly' | 'monthly' | 'yearly'
                          })}
                          className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                        >
                          <option value="daily">{t.fields.recurrence.frequency.daily}</option>
                          <option value="weekly">{t.fields.recurrence.frequency.weekly}</option>
                          <option value="monthly">{t.fields.recurrence.frequency.monthly}</option>
                          <option value="yearly">{t.fields.recurrence.frequency.yearly}</option>
                        </select>
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                          {t.fields.recurrence.endDate.label}
                        </label>
                        <input
                          type="date"
                          value={formData.recurrence.endDate || ''}
                          onChange={(e) => handleFieldChange('recurrence', {
                            ...formData.recurrence,
                            endDate: e.target.value || null
                          })}
                          className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Labels */}
              <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {t.fields.labels.label}
                </label>
                <div className="flex flex-wrap gap-2 mb-2">
                  {formData.labels.map(labelId => (
                    <div key={labelId} className="flex items-center gap-1">
                      <LabelTag name={getLabelName(labelId)} />
                      <button
                        type="button"
                        onClick={() => handleLabelRemove(labelId)}
                        className="text-gray-400 hover:text-gray-600"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 relative">
                  <div className="flex-1 relative">
                    <input
                      ref={inputRef}
                      type="text"
                      value={labelInput}
                      onChange={handleLabelInputChange}
                      onKeyPress={handleLabelInputKeyPress}
                      placeholder={t.fields.labels.placeholder}
                      className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                    />
                    {showSuggestions && suggestions.length > 0 && (
                      <div
                        ref={suggestionsRef}
                        className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg"
                      >
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.id}
                            className="px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                    onClick={() => {
                      if (labelInput.trim()) {
                        handleLabelAdd(labelInput.trim());
                        setLabelInput('');
                        setSuggestions([]);
                        setShowSuggestions(false);
                      }
                    }}
                  >
                    <Plus className="h-5 w-5" />
                  </button>
                </div>
              </div>

              {/* Attachments */}
              <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {t.fields.attachments.label}
                </label>
                
                {/* Upload Area */}
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <Upload className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        {t.fields.attachments.dropzone}
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      multiple
                      onChange={handleFileChange}
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                    />
                  </label>
                </div>

                {/* Upload Progress */}
                {uploadingFiles.length > 0 && (
                  <div className="space-y-2">
                    {uploadingFiles.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 p-2 bg-gray-50 dark:bg-gray-800 rounded-lg"
                      >
                        <Loader2 className="h-4 w-4 animate-spin text-indigo-600 dark:text-indigo-400" />
                        <span className="text-sm text-gray-600 dark:text-gray-400 flex-1 truncate">
                          {file.file.name}
                        </span>
                        <span className="text-sm text-indigo-600 dark:text-indigo-400">
                          {file.progress}%
                        </span>
                      </div>
                    ))}
                  </div>
                )}

                {/* Uploaded Files */}
                {formData.attachments && formData.attachments.length > 0 && (
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {formData.attachments.map((url, index) => {
                      const isImageFile = url.match(/\.(jpg|jpeg|png|gif|webp)$/i);
                      const fileName = url.split('/').pop() || 'File';
                      
                      return (
                        <div key={index} className="group relative">
                          <div className="aspect-square rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800 relative">
                            {isImageFile ? (
                              <img
                                src={url}
                                alt={fileName}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <div className="w-full h-full flex items-center justify-center">
                                <FileText className="h-8 w-8 text-gray-400" />
                              </div>
                            )}
                            <button
                              type="button"
                              onClick={() => removeAttachment(url)}
                              className="absolute top-2 right-2 p-1.5 rounded-full bg-red-100 text-red-600 opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-200"
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                          <p className="mt-1 text-xs text-gray-500 dark:text-gray-400 truncate">
                            {fileName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Debt Details */}
              {formData.type === 'debt' && (
                <div className="space-y-4 p-4 bg-blue-50 dark:bg-blue-900/10 rounded-lg">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t.fields.debtDetails.personName.label}
                    </label>
                    <input
                      type="text"
                      value={formData.debtDetails?.personName || ''}
                      onChange={(e) => handleFieldChange('debtDetails', {
                        ...formData.debtDetails,
                        personName: e.target.value
                      })}
                      placeholder={t.fields.debtDetails.personName.placeholder}
                      className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      {t.fields.debtDetails.dueDate.label}
                    </label>
                    <input
                      type="date"
                      value={formData.debtDetails?.dueDate || ''}
                      onChange={(e) => handleFieldChange('debtDetails', {
                        ...formData.debtDetails,
                        dueDate: e.target.value
                      })}
                      className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <div className="sticky bottom-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 px-6 py-4 flex justify-end z-10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
            >
              {t.buttons.cancel}
            </button>
            <button
              type="submit"
              disabled={uploadingFiles.length > 0}
              className={`ml-3 px-4 py-2 bg-indigo-600 text-white rounded-lg text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 ${
                uploadingFiles.length > 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {initialData ? t.buttons.saveChanges : t.buttons.addTransaction}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionModalForm;
