const translations = {
  title: 'Share Wallet',
  hideOptions: 'Hide Sharing Options',
  addMember: 'Add Member',
  emailPlaceholder: 'Enter email address',
  currentMembers: 'Current Members',
  sendInvitation: 'Send Invitation',
  remove: 'Remove',
  confirmRemove: 'Are you sure you want to remove this member?',
  roles: {
    title: 'Access Level',
    owner: 'Owner',
    editor: 'Can edit',
    viewer: 'View only'
  },
  invitationSent: 'Invitation sent successfully',
  invitationError: 'Failed to send invitation',
  invalidInvitation: 'Invalid invitation link',
  invitationAccepted: 'Invitation accepted successfully',
  acceptError: 'Failed to accept invitation',
  processingInvitation: 'Processing Invitation',
  pleaseWait: 'Please wait while we process your invitation...'
};

export default translations;
