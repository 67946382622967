const translations = {
  dueDate: 'Son ödeme tarihi: {{date}}',
  installments: {
    format: '{{current}} / {{total}}',
    periods: {
      weekly: 'Haftalık',
      monthly: 'Aylık'
    }
  },
  creditCard: 'Kredi Kartı',
  edit: 'Düzenle',
  delete: 'Sil',
  confirmDelete: 'Bu işlemi silmek istediğinizden emin misiniz?',
  more: 'daha'
};

export default translations;
