import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut, 
  browserLocalPersistence, 
  browserSessionPersistence, 
  setPersistence,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { User } from '../types/user';

const getDefaultCurrency = (): string => {
  const locale = navigator.language;
  const currencyMap: { [key: string]: string } = {
    'tr': 'TRY',  // Turkish
    'en-US': 'USD', // United States
    'en-GB': 'GBP', // United Kingdom
    'de': 'EUR',  // Germany
    'fr': 'EUR',  // France
    'es': 'EUR',  // Spain
    'it': 'EUR',  // Italy
    'ja': 'JPY',  // Japan
    'zh': 'CNY',  // China
  };
  
  // First try the full locale
  if (locale in currencyMap) {
    return currencyMap[locale];
  }
  
  // Then try just the language part
  const language = locale.split('-')[0];
  if (language in currencyMap) {
    return currencyMap[language];
  }
  
  // Default to USD if no match found
  return 'USD';
};

// Initialize providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
const microsoftProvider = new OAuthProvider('microsoft.com');

// Helper function to create or update user document
const createOrUpdateUserDoc = async (userId: string, userData: Partial<User>): Promise<User> => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  const defaultUserData: Omit<User, 'id'> = {
    email: userData.email || '',
    name: userData.name || '',
    role: 'user',
    createdAt: new Date().toISOString(),
    isEmailVerified: userData.isEmailVerified || false,
    settings: {
      language: navigator.language.startsWith('tr') ? 'tr' : 'en',
      theme: 'light',
      currency: getDefaultCurrency(),
      notifications: true
    }
  };

  if (!userDoc.exists()) {
    // Create new user document
    await setDoc(userRef, defaultUserData);
  }

  return {
    id: userId,
    ...(userDoc.exists() ? userDoc.data() : defaultUserData)
  } as User;
};

export const loginUser = async (email: string, password: string, rememberMe: boolean = true): Promise<User> => {
  try {
    // Set persistence based on rememberMe
    const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistenceType);

    // Store the persistence choice
    localStorage.setItem('authPersistence', rememberMe ? 'local' : 'session');

    // Sign in
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return await createOrUpdateUserDoc(userCredential.user.uid, {
      email: userCredential.user.email || '',
      isEmailVerified: userCredential.user.emailVerified,
      name: userCredential.user.displayName || ''
    });
  } catch (error) {
    console.error('[Auth] Login error:', error);
    throw error;
  }
};

export const registerUser = async (email: string, password: string, name: string): Promise<User> => {
  try {
    // Always use local persistence for new registrations
    await setPersistence(auth, browserLocalPersistence);
    localStorage.setItem('authPersistence', 'local');

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return await createOrUpdateUserDoc(userCredential.user.uid, {
      email,
      name,
      isEmailVerified: false
    });
  } catch (error) {
    console.error('[Auth] Registration error:', error);
    throw error;
  }
};

export const signInWithGoogle = async (): Promise<User> => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return await createOrUpdateUserDoc(result.user.uid, {
      email: result.user.email || '',
      name: result.user.displayName || '',
      isEmailVerified: result.user.emailVerified
    });
  } catch (error) {
    console.error('[Auth] Google sign-in error:', error);
    throw error;
  }
};

export const signInWithFacebook = async (): Promise<User> => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    return await createOrUpdateUserDoc(result.user.uid, {
      email: result.user.email || '',
      name: result.user.displayName || '',
      isEmailVerified: result.user.emailVerified
    });
  } catch (error) {
    console.error('[Auth] Facebook sign-in error:', error);
    throw error;
  }
};

export const signInWithApple = async (): Promise<User> => {
  try {
    const result = await signInWithPopup(auth, appleProvider);
    return await createOrUpdateUserDoc(result.user.uid, {
      email: result.user.email || '',
      name: result.user.displayName || '',
      isEmailVerified: result.user.emailVerified
    });
  } catch (error) {
    console.error('[Auth] Apple sign-in error:', error);
    throw error;
  }
};

export const signInWithMicrosoft = async (): Promise<User> => {
  try {
    const result = await signInWithPopup(auth, microsoftProvider);
    return await createOrUpdateUserDoc(result.user.uid, {
      email: result.user.email || '',
      name: result.user.displayName || '',
      isEmailVerified: result.user.emailVerified
    });
  } catch (error) {
    console.error('[Auth] Microsoft sign-in error:', error);
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    await signOut(auth);
    localStorage.removeItem('authPersistence');
    sessionStorage.removeItem('redirectUrl');
  } catch (error) {
    console.error('[Auth] Logout error:', error);
    throw error;
  }
};

export const getUserData = async (userId: string): Promise<User> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    return {
      id: userId,
      ...userDoc.data()
    } as User;
  } catch (error) {
    console.error('[Auth] Get user data error:', error);
    throw error;
  }
};
