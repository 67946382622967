export const header = {
  common: {
    settings: 'Ayarlar',
    logout: 'Çıkış Yap'
  },
  languageMenu: {
    title: 'Dil'
  },
  themeToggle: {
    light: 'Açık Moda Geç',
    dark: 'Koyu Moda Geç'
  },
  notifications: 'Bildirimler',
  userMenu: {
    profile: 'Profil',
    settings: 'Ayarlar',
    logout: 'Çıkış Yap'
  }
};
