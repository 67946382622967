import React from 'react';
import { Filter, ArrowUpDown, Wallet } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { useCategories } from '../../context/CategoryContext';
import { useLabels } from '../../context/LabelContext';
import { Category, Label } from '../../types';

interface TransactionPageFiltersProps {
  filterType: 'all' | 'income' | 'expense';
  filterCategory: string;
  filterLabel: string;
  filterWallet: string;
  sortOrder: 'asc' | 'desc';
  wallets: Array<{ id: string; name: string; color: string }>;
  onTypeChange: (type: 'all' | 'income' | 'expense') => void;
  onCategoryChange: (categoryId: string) => void;
  onLabelChange: (labelId: string) => void;
  onWalletChange: (walletId: string) => void;
  onSortOrderChange: () => void;
}

const TransactionPageFilters: React.FC<TransactionPageFiltersProps> = ({
  filterType,
  filterCategory,
  filterLabel,
  filterWallet,
  sortOrder,
  wallets,
  onTypeChange,
  onCategoryChange,
  onLabelChange,
  onWalletChange,
  onSortOrderChange
}) => {
  const { t } = useLanguage();
  const { categories } = useCategories();
  const { labels } = useLabels();

  // Filter categories based on transaction type
  const filteredCategories = React.useMemo(() => {
    if (filterType === 'all') return categories;
    return categories.filter((category: Category) => {
      if (filterType === 'income') return category.type === 'income';
      if (filterType === 'expense') return category.type === 'expense';
      return true;
    });
  }, [categories, filterType]);

  return (
    <div className="flex flex-wrap items-center gap-4">
      {/* Wallet Filter */}
      <div className="flex gap-3 overflow-x-auto">
        <button
          onClick={() => onWalletChange('all')}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-colors whitespace-nowrap ${
            filterWallet === 'all'
              ? 'bg-indigo-50 dark:bg-indigo-900/50 border-indigo-200 dark:border-indigo-700 text-indigo-700 dark:text-indigo-300'
              : 'border-gray-200 dark:border-gray-700 text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800'
          }`}
        >
          <Wallet className="h-4 w-4" />
          {t('wallets.allWallets')}
        </button>
        {wallets.map(wallet => (
          <button
            key={`wallet-filter-${wallet.id}`}
            onClick={() => onWalletChange(wallet.id)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-colors whitespace-nowrap ${
              filterWallet === wallet.id
                ? `bg-${wallet.color}-50 dark:bg-${wallet.color}-900/50 border-${wallet.color}-200 dark:border-${wallet.color}-700 text-${wallet.color}-700 dark:text-${wallet.color}-300`
                : 'border-gray-200 dark:border-gray-700 text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800'
            }`}
          >
            <Wallet className="h-4 w-4" />
            {wallet.name}
          </button>
        ))}
      </div>

      {/* Type, Category, and Label Filters */}
      <div className="flex items-center gap-2 ml-auto">
        <Filter className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        
        {/* Transaction Type Filter */}
        <select
          value={filterType}
          onChange={(e) => onTypeChange(e.target.value as 'all' | 'income' | 'expense')}
          className="text-sm border-none bg-transparent focus:ring-0 dark:text-gray-300"
        >
          <option value="all">{t('transactions.types.all')}</option>
          <option value="income">{t('transactions.types.income')}</option>
          <option value="expense">{t('transactions.types.expense')}</option>
        </select>

        {/* Category Filter */}
        <select
          value={filterCategory}
          onChange={(e) => onCategoryChange(e.target.value)}
          className="text-sm border-none bg-transparent focus:ring-0 dark:text-gray-300"
        >
          <option value="all">{t('transactions.categories.all')}</option>
          {filteredCategories.map((category: Category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        {/* Label Filter */}
        <select
          value={filterLabel}
          onChange={(e) => onLabelChange(e.target.value)}
          className="text-sm border-none bg-transparent focus:ring-0 dark:text-gray-300"
        >
          <option value="all">{t('transactions.labels.all')}</option>
          {labels.map((label: Label) => (
            <option key={label.id} value={label.id}>
              {label.name}
            </option>
          ))}
        </select>

        {/* Sort Order */}
        <button
          onClick={onSortOrderChange}
          className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
          title={t('transactions.sortOrder')}
        >
          <ArrowUpDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default TransactionPageFilters;
