export const categoryModal = {
  title: {
    new: 'Add Category',
    edit: 'Edit Category'
  },
  labels: {
    icon: 'Icon',
    color: 'Color',
    name: 'Name',
    type: 'Type'
  },
  types: {
    expense: 'Expense',
    income: 'Income'
  },
  buttons: {} // Common translations will be merged here
};
