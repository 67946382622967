const translations = {
  title: 'İşlem Detayları',
  description: 'Açıklama',
  category: 'Kategori',
  wallet: 'Cüzdan',
  date: 'Tarih',
  labels: 'Etiketler',
  note: 'Not',
  attachments: 'Ekler',
  attachment: 'Ek {{number}}',
  paymentMethod: 'Ödeme Yöntemi',
  personName: 'Kişi Adı',
  dueDate: 'Son Ödeme Tarihi',
  installments: 'Taksitler',
  installmentsFormat: '{{amount}} tutarında {{count}} x {{period}}',
  types: {
    income: 'Gelir',
    expense: 'Gider',
    debt: 'Borç'
  },
  loading: 'Yükleniyor...',
  error: {
    generic: 'Bir hata oluştu',
    notFound: 'İşlem bulunamadı'
  }
};

export default translations;
