import { TranslationModule } from '../../types';
import { TransactionModalTranslations } from './types';
import { translations as enTranslations } from './en';
import { translations as trTranslations } from './tr';
import { getTranslations as getCommonTranslations } from '../../common';

export const translations: TranslationModule<TransactionModalTranslations> = {
  en: {
    ...enTranslations,
    buttons: {
      ...enTranslations.buttons,
      cancel: getCommonTranslations('en').cancel,
      save: getCommonTranslations('en').save,
      add: getCommonTranslations('en').add
    }
  },
  tr: {
    ...trTranslations,
    buttons: {
      ...trTranslations.buttons,
      cancel: getCommonTranslations('tr').cancel,
      save: getCommonTranslations('tr').save,
      add: getCommonTranslations('tr').add
    }
  }
};

// Re-export the types
export type { TransactionModalTranslations };

// Helper to get translations for a specific language
export const getTranslations = (lang: 'en' | 'tr'): TransactionModalTranslations => {
  return translations[lang];
};
