import React from 'react';
import { Calendar } from 'lucide-react';
import { useSubscriptions } from '../../context/SubscriptionContext';
import { useLanguage } from '../../context/LanguageContext';
import { getTranslations } from '../../locales/components/upcomingSubscriptions';

const UpcomingSubscriptions = () => {
  const { getUpcomingSubscriptions } = useSubscriptions();
  const { language } = useLanguage();
  const translations = getTranslations(language);
  const upcomingSubscriptions = getUpcomingSubscriptions().slice(0, 5);

  if (upcomingSubscriptions.length === 0) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6 mb-6">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">{translations.title}</h2>
      <div className="space-y-4">
        {upcomingSubscriptions.map(subscription => {
          const daysUntil = Math.ceil(
            (new Date(subscription.nextBillingDate).getTime() - new Date().getTime()) / 
            (1000 * 60 * 60 * 24)
          );

          let dueText;
          if (daysUntil === 0) {
            dueText = translations.dueToday;
          } else if (daysUntil < 0) {
            dueText = translations.overdue.replace('{{days}}', Math.abs(daysUntil).toString());
          } else {
            dueText = translations.dueIn.replace('{{days}}', daysUntil.toString());
          }

          return (
            <div
              key={subscription.id}
              className="flex items-center justify-between"
            >
              <div className="flex items-center gap-3">
                {subscription.logo ? (
                  <img src={subscription.logo} alt={subscription.name} className="w-8 h-8 rounded" />
                ) : (
                  <div className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded flex items-center justify-center">
                    <span className="text-lg font-bold text-gray-500 dark:text-gray-400">
                      {subscription.name[0]}
                    </span>
                  </div>
                )}
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">{subscription.name}</h3>
                  <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                    <Calendar className="h-4 w-4" />
                    <span>{dueText}</span>
                  </div>
                </div>
              </div>
              <span className="font-medium text-gray-900 dark:text-white">
                {subscription.amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: subscription.currency
                })}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingSubscriptions;
