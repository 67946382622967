import React from 'react';

interface LabelTagProps {
  name: string;
  color?: string;
  className?: string;
}

const colorClasses = {
  indigo: 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900/50 dark:text-indigo-300',
  emerald: 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900/50 dark:text-emerald-300',
  amber: 'bg-amber-100 text-amber-700 dark:bg-amber-900/50 dark:text-amber-300',
  red: 'bg-red-100 text-red-700 dark:bg-red-900/50 dark:text-red-300',
  blue: 'bg-blue-100 text-blue-700 dark:bg-blue-900/50 dark:text-blue-300',
  purple: 'bg-purple-100 text-purple-700 dark:bg-purple-900/50 dark:text-purple-300',
  pink: 'bg-pink-100 text-pink-700 dark:bg-pink-900/50 dark:text-pink-300',
  green: 'bg-green-100 text-green-700 dark:bg-green-900/50 dark:text-green-300',
  yellow: 'bg-yellow-100 text-yellow-700 dark:bg-yellow-900/50 dark:text-yellow-300',
  orange: 'bg-orange-100 text-orange-700 dark:bg-orange-900/50 dark:text-orange-300',
  teal: 'bg-teal-100 text-teal-700 dark:bg-teal-900/50 dark:text-teal-300',
  cyan: 'bg-cyan-100 text-cyan-700 dark:bg-cyan-900/50 dark:text-cyan-300',
  gray: 'bg-gray-100 text-gray-700 dark:bg-gray-900/50 dark:text-gray-300'
};

const LabelTag: React.FC<LabelTagProps> = ({ name, color = 'indigo', className = '' }) => {
  const colorClass = colorClasses[color as keyof typeof colorClasses] || colorClasses.indigo;

  return (
    <span
      className={`inline-block px-2 py-0.5 text-xs rounded-full ${colorClass} ${className}`}
    >
      {name}
    </span>
  );
};

export default LabelTag;
