import { SidebarTranslations } from './types';

const translations: SidebarTranslations = {
  appName: 'CostCaddy',
  tagline: 'Finanslarınızı kolayca yönetin',
  menu: {
    overview: 'Genel Bakış',
    wallets: 'Cüzdanlar',
    transactions: 'İşlemler',
    reports: 'Raporlar',
    creditCards: 'Kredi Kartları',
    subscriptions: 'Abonelikler',
    budgets: 'Bütçeler',
    settings: 'Ayarlar',
    admin: 'Yönetici'
  },
  settings: {
    categories: 'Kategoriler',
    labels: 'Etiketler',
    inviteMembers: 'Üye Davet Et',
    account: 'Hesap'
  },
  admin: {
    users: 'Kullanıcılar',
    categories: 'Kategoriler',
    labels: 'Etiketler',
    settings: 'Ayarlar',
    subscriptions: 'Abonelikler',
    emails: 'E-postalar',
    dashboard: 'Panel'
  }
};

// Export both the new translations format and the legacy sidebar format
export { translations };
export const sidebar = translations;
