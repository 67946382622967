import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { User } from '../types/user';
import * as authService from '../services/auth';
import * as authOperations from '../services/authOperations';
import { useNotification } from './NotificationContext';
import { getAuthErrorMessage } from '../utils/errorHandler';
import { USER_ROLES } from '../constants/users';
import { collection, onSnapshot, query, where, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  users: User[];
  isLoading: boolean;
  error: string | null;
  login: (email: string, password: string, rememberMe?: boolean) => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (userId: string, data: Partial<User>) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  resetUserPassword: (email: string) => Promise<void>;
  verifyEmail: () => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithFacebook: () => Promise<void>;
  signInWithApple: () => Promise<void>;
  signInWithMicrosoft: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within AuthProvider');
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  // Handle users subscription
  const setupUsersSubscription = (userRole: string) => {
    if (userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERADMIN) {
      const usersQuery = query(collection(db, 'users'), where('role', '!=', USER_ROLES.SUPERADMIN));
      return onSnapshot(usersQuery, 
        (snapshot) => {
          const fetchedUsers = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })) as User[];
          setUsers(fetchedUsers);
        },
        (err) => {
          console.error('[Auth] Error in users subscription:', err);
          showNotification('error', 'Failed to sync users list');
        }
      );
    }
    return undefined;
  };

  // Handle auth state changes
  useEffect(() => {
    let unsubscribeUsers: (() => void) | undefined;

    const unsubscribeAuth = onAuthStateChanged(auth, 
      async (firebaseUser) => {
        try {
          if (firebaseUser) {
            const userData = await authService.getUserData(firebaseUser.uid);
            setUser(userData);
            unsubscribeUsers = setupUsersSubscription(userData.role);
          } else {
            setUser(null);
            setUsers([]);
            if (unsubscribeUsers) {
              unsubscribeUsers();
              unsubscribeUsers = undefined;
            }
          }
        } catch (err) {
          console.error('[Auth] Error fetching user data:', err);
          const errorMessage = getAuthErrorMessage(err);
          setError(errorMessage);
          showNotification('error', errorMessage);
          // If we can't get user data, sign out
          await authService.logoutUser();
          setUser(null);
        } finally {
          setIsLoading(false);
        }
      },
      (error) => {
        console.error('[Auth] Auth state change error:', error);
        const errorMessage = getAuthErrorMessage(error);
        setError(errorMessage);
        showNotification('error', errorMessage);
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribeAuth();
      if (unsubscribeUsers) {
        unsubscribeUsers();
      }
    };
  }, [showNotification]);

  const login = async (email: string, password: string, rememberMe: boolean = true) => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.loginUser(email, password, rememberMe);
      setUser(userData);
      showNotification('success', 'Successfully logged in');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (email: string, password: string, name: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.registerUser(email, password, name);
      setUser(userData);
      showNotification('success', 'Account created successfully! Please verify your email.');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.signInWithGoogle();
      setUser(userData);
      showNotification('success', 'Successfully signed in with Google');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithFacebook = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.signInWithFacebook();
      setUser(userData);
      showNotification('success', 'Successfully signed in with Facebook');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithApple = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.signInWithApple();
      setUser(userData);
      showNotification('success', 'Successfully signed in with Apple');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithMicrosoft = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userData = await authService.signInWithMicrosoft();
      setUser(userData);
      showNotification('success', 'Successfully signed in with Microsoft');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await authService.logoutUser();
      setUser(null);
      setUsers([]);
      showNotification('success', 'Successfully logged out');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (userId: string, data: Partial<User>) => {
    setIsLoading(true);
    setError(null);
    try {
      await authOperations.updateUserProfile(userId, data);
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const updatedUserData = {
        id: userId,
        ...userDoc.data()
      } as User;

      setUsers(prevUsers =>
        prevUsers.map(u => u.id === userId ? updatedUserData : u)
      );

      if (user?.id === userId) {
        setUser(updatedUserData);
      }

      showNotification('success', 'Profile updated successfully');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async (userId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await authOperations.deleteUser(userId);
      setUsers(prevUsers => prevUsers.filter(u => u.id !== userId));
      if (user?.id === userId) {
        setUser(null);
      }
      showNotification('success', 'User deleted successfully');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const resetUserPassword = async (email: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await authOperations.resetPassword(email);
      showNotification('success', 'Password reset email sent successfully');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const verifyEmail = async () => {
    if (!auth.currentUser) return;
    setIsLoading(true);
    setError(null);
    try {
      await authOperations.sendVerificationEmail(auth.currentUser);
      showNotification('success', 'Verification email sent');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    if (!auth.currentUser) return;
    setIsLoading(true);
    setError(null);
    try {
      await authOperations.sendVerificationEmail(auth.currentUser);
      showNotification('success', 'Verification email resent');
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      showNotification('error', errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    user,
    users,
    isLoading,
    error,
    login,
    register,
    logout,
    updateUser,
    deleteUser,
    resetUserPassword,
    verifyEmail,
    resendVerificationEmail,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    signInWithMicrosoft
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
