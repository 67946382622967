import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useLabels } from '../../context/LabelContext';
import { getTranslations } from '../../locales/components/labelModal';

const colors = [
  'slate', 'gray', 'zinc', 'neutral', 'stone', 'red', 'orange', 'amber',
  'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue',
  'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose'
];

interface LabelModalProps {
  isOpen: boolean;
  onClose: () => void;
  labelId: string | null;
  language?: 'en' | 'tr';
}

const LabelModal: React.FC<LabelModalProps> = ({ 
  isOpen, 
  onClose, 
  labelId, 
  language = 'en' 
}) => {
  const { labels, addLabel, updateLabel } = useLabels();
  const translations = getTranslations(language);
  const [formData, setFormData] = useState({
    name: '',
    color: 'indigo'
  });

  useEffect(() => {
    if (labelId) {
      const label = labels.find(l => l.id === labelId);
      if (label) {
        setFormData({
          name: label.name,
          color: label.color
        });
      }
    } else {
      setFormData({
        name: '',
        color: 'indigo'
      });
    }
  }, [labelId, labels]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (labelId) {
        await updateLabel(labelId, formData);
      } else {
        await addLabel(formData);
      }
      onClose();
    } catch (error) {
      console.error('Error saving label:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {labelId ? translations.title.edit : translations.title.new}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {translations.labels.nameLabel}
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={e => setFormData(d => ({ ...d, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              placeholder={translations.labels.namePlaceholder}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {translations.labels.colorLabel}
            </label>
            <div className="grid grid-cols-6 gap-2">
              {colors.map(color => (
                <button
                  key={color}
                  type="button"
                  onClick={() => setFormData(d => ({ ...d, color }))}
                  className={`w-8 h-8 rounded-full bg-${color}-500 ${
                    formData.color === color ? 'ring-2 ring-offset-2 ring-indigo-500' : ''
                  }`}
                />
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              {translations.buttons.cancel}
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {labelId ? translations.buttons.save : translations.buttons.add}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LabelModal;
