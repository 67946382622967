const translations = {
  actions: {
    share: 'Share Wallet',
    viewDetails: 'View Details'
  },
  stats: {
    income: 'Income',
    expenses: 'Expenses',
    transactions: {
      one: 'transaction',
      other: 'transactions'
    }
  },
  members: {
    count: 'Members'
  }
};

export default translations;
