const translations = {
  sharing: {
    hideOptions: 'Paylaşım seçeneklerini gizle',
    shareWallet: 'Cüzdanı Paylaş',
    addMember: 'Üye Ekle',
    emailPlaceholder: 'E-posta adresini girin',
    sendInvitation: 'Davet Gönder',
    currentMembers: 'Mevcut Üyeler',
    confirmRemove: 'Bu üyeyi kaldırmak istediğinizden emin misiniz?',
    remove: 'Kaldır',
    roles: {
      owner: 'Sahip',
      editor: 'Düzenleyici',
      viewer: 'Görüntüleyici'
    }
  },
  errors: {
    shareWallet: 'Cüzdan paylaşılamadı',
    removeMember: 'Üye kaldırılamadı'
  }
};

export default translations;
