const translations = {
  title: 'Cüzdanlar',
  subtitle: 'Cüzdanlarınızı yönetin',
  new: 'Yeni Cüzdan',
  allWallets: 'Tüm Cüzdanlar',
  balance: 'Bakiye',
  actions: {
    edit: 'Düzenle',
    delete: 'Sil',
    share: 'Paylaş'
  },
  types: {
    cash: 'Nakit',
    bank: 'Banka Hesabı',
    investment: 'Yatırım',
    other: 'Diğer'
  },
  fields: {
    name: 'İsim',
    type: 'Tür',
    currency: 'Para Birimi',
    initialBalance: 'Başlangıç Bakiyesi',
    currentBalance: 'Mevcut Bakiye'
  },
  messages: {
    deleteConfirmation: 'Bu cüzdanı silmek istediğinizden emin misiniz?',
    deleteSuccess: 'Cüzdan başarıyla silindi',
    createSuccess: 'Cüzdan başarıyla oluşturuldu',
    updateSuccess: 'Cüzdan başarıyla güncellendi'
  }
};

export default translations;
