import React, { useState } from 'react';
import { X, Mail, UserPlus, AlertCircle } from 'lucide-react';
import { Wallet, WalletMember } from '../../types';
import { useLanguage } from '../../context/LanguageContext';
import { getTranslations } from '../../locales/components/shareWalletModal';

interface ShareWalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  wallet: Wallet;
  onInvite: (email: string, role: WalletMember['role']) => Promise<void>;
  onRemoveMember: (memberId: string) => Promise<void>;
}

const ShareWalletModal: React.FC<ShareWalletModalProps> = ({
  isOpen,
  onClose,
  wallet,
  onInvite,
  onRemoveMember
}) => {
  const { language } = useLanguage();
  const translations = getTranslations(language);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<WalletMember['role']>('editor');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await onInvite(email, role);
      setEmail('');
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.invitationError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!window.confirm(translations.confirmRemove)) return;
    
    try {
      await onRemoveMember(memberId);
    } catch (err) {
      setError(err instanceof Error ? err.message : translations.invitationError);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-dark-card rounded-xl w-full max-w-lg">
        <div className="p-6 border-b border-gray-100 dark:border-dark-border flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {translations.title}
          </h2>
          <button onClick={onClose} className="text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-400">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/30 rounded-lg flex items-start">
              <AlertCircle className="h-5 w-5 text-red-400 mt-0.5 mr-3" />
              <p className="text-sm text-red-800 dark:text-red-300">{error}</p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {translations.emailPlaceholder}
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-dark-border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-card dark:text-white"
                  placeholder="colleague@example.com"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {translations.roles.title}
              </label>
              <select
                value={role}
                onChange={e => setRole(e.target.value as WalletMember['role'])}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-dark-border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-dark-card dark:text-white"
              >
                <option value="editor">{translations.roles.editor}</option>
                <option value="viewer">{translations.roles.viewer}</option>
              </select>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              {isLoading ? translations.pleaseWait : translations.sendInvitation}
            </button>
          </form>

          {wallet.members && Object.keys(wallet.members).length > 0 && (
            <div className="mt-8">
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
                {translations.currentMembers}
              </h3>
              <div className="space-y-3">
                {Object.entries(wallet.members).map(([memberId, member]) => (
                  <div
                    key={memberId}
                    className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-800 rounded-lg"
                  >
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">{memberId}</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {translations.roles[member.role as keyof typeof translations.roles]}
                      </p>
                    </div>
                    <button
                      onClick={() => handleRemoveMember(memberId)}
                      className="text-sm text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300"
                    >
                      {translations.remove}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareWalletModal;
