import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { labelsCollection } from '../lib/collections';
import { Label } from '../types';

export const createLabel = async (
  userId: string,
  data: Omit<Label, 'id' | 'transactionCount'>
): Promise<string> => {
  const labelData = {
    ...data,
    userId,
    transactionCount: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  };

  const docRef = await addDoc(labelsCollection, labelData);
  return docRef.id;
};

export const getUserLabels = async (userId: string): Promise<Label[]> => {
  const q = query(
    labelsCollection,
    where('userId', '==', userId)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Label));
};

export const updateLabel = async (
  labelId: string,
  data: Partial<Label>
): Promise<void> => {
  const labelRef = doc(labelsCollection, labelId);
  await updateDoc(labelRef, {
    ...data,
    updatedAt: new Date().toISOString()
  });
};

export const deleteLabel = async (labelId: string): Promise<void> => {
  await deleteDoc(doc(labelsCollection, labelId));
};

export const incrementLabelUsage = async (labelId: string): Promise<void> => {
  const labelRef = doc(labelsCollection, labelId);
  const labelDoc = await getDoc(labelRef);
  
  if (!labelDoc.exists()) {
    throw new Error('Label not found');
  }

  const labelData = labelDoc.data() as Label;

  await updateDoc(labelRef, {
    transactionCount: (labelData.transactionCount || 0) + 1,
    updatedAt: new Date().toISOString()
  });
};

export const decrementLabelUsage = async (labelId: string): Promise<void> => {
  const labelRef = doc(labelsCollection, labelId);
  const labelDoc = await getDoc(labelRef);
  
  if (!labelDoc.exists()) {
    throw new Error('Label not found');
  }

  const labelData = labelDoc.data() as Label;

  await updateDoc(labelRef, {
    transactionCount: Math.max((labelData.transactionCount || 0) - 1, 0),
    updatedAt: new Date().toISOString()
  });
};

export const findLabelByName = async (userId: string, name: string): Promise<Label | null> => {
  const q = query(
    labelsCollection,
    where('userId', '==', userId),
    where('name', '==', name)
  );

  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }

  const doc = querySnapshot.docs[0];
  return {
    id: doc.id,
    ...doc.data()
  } as Label;
};
