import en from './en';
import tr from './tr';

export type DeleteAccountTranslations = {
  title: string;
  message: string;
  confirmText: string;
  buttons: {
    cancel: string;
    delete: string;
  };
  error: {
    invalidConfirmation: string;
  };
};

export const getTranslations = (language: 'en' | 'tr' = 'en'): DeleteAccountTranslations => {
  const translations = {
    en,
    tr
  };

  return translations[language];
};
