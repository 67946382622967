import React from 'react';
import { X, Crown } from 'lucide-react';
import { getTranslations } from '../locales/components/planLimitModal';

interface PlanLimitModalProps {
  isOpen: boolean;
  onClose: () => void;
  feature: string;
  language?: 'en' | 'tr';
}

const PlanLimitModal: React.FC<PlanLimitModalProps> = ({ 
  isOpen, 
  onClose, 
  feature, 
  language = 'en' 
}) => {
  if (!isOpen) return null;

  const translations = getTranslations(language);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-4">
          <div className="p-2 bg-amber-100 dark:bg-amber-900/50 rounded-lg">
            <Crown className="h-6 w-6 text-amber-600 dark:text-amber-400" />
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
          {translations.title}
        </h2>
        
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {translations.description.replace('{feature}', feature)}
        </p>

        <ul className="space-y-2 mb-6">
          {translations.features.map((featureText, index) => (
            <li key={index} className="flex items-center text-gray-700 dark:text-gray-300">
              <Crown className="h-4 w-4 text-amber-600 dark:text-amber-400 mr-2" />
              {featureText}
            </li>
          ))}
        </ul>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            {translations.buttons.close}
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            {translations.buttons.upgrade}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanLimitModal;
