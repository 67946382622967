export const labelModal = {
  title: {
    new: 'Yeni Etiket',
    edit: 'Etiketi Düzenle'
  },
  labels: {
    nameLabel: 'Etiket Adı',
    namePlaceholder: 'Etiket adını girin',
    colorLabel: 'Renk Seçin'
  },
  buttons: {
    cancel: 'İptal',
    add: 'Etiket Ekle',
    save: 'Değişiklikleri Kaydet'
  }
};
