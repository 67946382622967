import React from 'react';
import { getDashboardTranslations } from '../../locales';
import { useCurrency } from '../../context/CurrencyContext';
import { useLanguage } from '../../context/LanguageContext';
import { formatNumber } from '../../utils/formatters';

interface StatsGridProps {
  stats: {
    balance: number;
    income: number;
    expenses: number;
    savingsRate: number;
  };
}

const StatsGrid: React.FC<StatsGridProps> = ({ stats }) => {
  const { language } = useLanguage();
  const translations = getDashboardTranslations(language);
  const { formatAmount } = useCurrency();

  // Format percentage with locale-aware number formatting
  const formatPercentage = (value: number): string => {
    return formatNumber(value, language, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) + '%';
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">{translations.stats.totalBalance}</p>
        <p className="text-2xl font-bold text-gray-900 dark:text-white">
          {formatAmount(stats.balance)}
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">{translations.stats.totalIncome}</p>
        <p className="text-2xl font-bold text-green-600 dark:text-green-400">
          {formatAmount(stats.income)}
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">{translations.stats.totalExpenses}</p>
        <p className="text-2xl font-bold text-red-600 dark:text-red-400">
          {formatAmount(stats.expenses)}
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700">
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">{translations.stats.savingsRate}</p>
        <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
          {formatPercentage(stats.savingsRate)}
        </p>
      </div>
    </div>
  );
};

export default StatsGrid;
