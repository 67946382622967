const translations = {
  title: {
    new: 'New Wallet',
    edit: 'Edit Wallet'
  },
  fields: {
    name: {
      label: 'Wallet Name',
      placeholder: 'Enter wallet name'
    },
    initialBalance: {
      label: 'Initial Balance',
      placeholder: 'Enter initial balance'
    },
    currency: {
      label: 'Currency',
      options: {
        try: 'TRY',
        usd: 'USD',
        eur: 'EUR',
        gbp: 'GBP'
      }
    },
    colorTheme: {
      label: 'Color Theme',
      options: {
        indigo: 'Indigo',
        emerald: 'Emerald',
        amber: 'Amber'
      }
    }
  },
  sharing: {
    title: 'Share Wallet',
    hideOptions: 'Hide Sharing Options',
    addMember: {
      label: 'Add Member',
      placeholder: 'Enter email address'
    },
    currentMembers: 'Current Members',
    roles: {
      title: 'Access Level',
      owner: 'Owner',
      editor: 'Can edit',
      viewer: 'View only'
    },
    actions: {
      remove: 'Remove',
      confirmRemove: 'Are you sure you want to remove this member?',
      sendInvitation: 'Send Invitation'
    },
    messages: {
      invitationSent: 'Invitation sent successfully',
      invitationError: 'Failed to send invitation',
      invalidInvitation: 'Invalid invitation link',
      invitationAccepted: 'Invitation accepted successfully',
      acceptError: 'Failed to accept invitation',
      processingInvitation: 'Processing Invitation',
      pleaseWait: 'Please wait while we process your invitation...'
    }
  },
  buttons: {} // Common translations will be merged here
};

export default translations;
