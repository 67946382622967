import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '../../context/ThemeContext';

interface DistributionChartProps {
  title: string;
  data: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  total: number;
}

const DistributionChart: React.FC<DistributionChartProps> = ({ title, data, total }) => {
  const { isDark } = useTheme();

  return (
    <div className="card p-6 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">{title}</h2>
      <div className="h-[300px] flex items-center justify-center">
        <Doughnut
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right' as const,
                labels: {
                  color: isDark ? '#ffffff' : '#111827',
                }
              },
              tooltip: {
                callbacks: {
                  label: context =>
                    `${context.label}: $${context.parsed.toFixed(2)} (${((context.parsed / total) * 100).toFixed(1)}%)`
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default DistributionChart;
