import React, { useState } from 'react';
import { Calendar, CreditCard, Wallet, Bell, Pencil, Trash2 } from 'lucide-react';
import { Subscription } from '../../types/subscription';
import { useSubscriptions } from '../../context/SubscriptionContext';
import { useCreditCards } from '../../context/CreditCardContext';
import { useWallets } from '../../context/WalletContext';
import { useLanguage } from '../../context/LanguageContext';

interface SubscriptionCardProps {
  subscription: Subscription;
  onEdit: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscription, onEdit }) => {
  const { deleteSubscription } = useSubscriptions();
  const { creditCards } = useCreditCards();
  const { wallets } = useWallets();
  const { t } = useLanguage();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const creditCard = creditCards.find(c => c.id === subscription.creditCardId);
  const wallet = wallets.find(w => w.id === subscription.walletId);

  const handleDelete = async () => {
    if (window.confirm(t('common.confirmDelete'))) {
      setIsDeleting(true);
      setError(null);
      try {
        await deleteSubscription(subscription.id);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to delete subscription');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const getDaysUntilNextBilling = () => {
    const today = new Date();
    const nextBilling = new Date(subscription.nextBillingDate);
    const diffTime = nextBilling.getTime() - today.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const daysUntilBilling = getDaysUntilNextBilling();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 p-6">
      {error && (
        <div className="mb-4 p-3 text-sm text-red-600 bg-red-50 dark:bg-red-900/50 dark:text-red-400 rounded-lg">
          {error}
        </div>
      )}
      
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          {subscription.logo ? (
            <img src={subscription.logo} alt={subscription.name} className="w-8 h-8 rounded" />
          ) : (
            <div className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded flex items-center justify-center">
              <span className="text-lg font-bold text-gray-500 dark:text-gray-400">
                {subscription.name[0]}
              </span>
            </div>
          )}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{subscription.name}</h3>
            {subscription.description && (
              <p className="text-sm text-gray-500 dark:text-gray-400">{subscription.description}</p>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={onEdit}
            disabled={isDeleting}
            className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            title={t('common.edit')}
          >
            <Pencil className="h-4 w-4" />
          </button>
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            title={t('common.delete')}
          >
            {isDeleting ? (
              <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <Trash2 className="h-4 w-4" />
            )}
          </button>
        </div>
      </div>

      <div className="space-y-3">
        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <Calendar className="h-4 w-4" />
            {t('components.subscriptionCard.nextBilling')}
          </div>
          <div className="flex items-center gap-2">
            <span className={`font-medium ${
              daysUntilBilling <= 3 ? 'text-red-600 dark:text-red-400' :
              daysUntilBilling <= 7 ? 'text-amber-600 dark:text-amber-400' :
              'text-gray-900 dark:text-white'
            }`}>
              {new Date(subscription.nextBillingDate).toLocaleDateString()}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <CreditCard className="h-4 w-4" />
            {t('components.subscriptionCard.paymentMethod')}
          </div>
          <span className="font-medium text-gray-900 dark:text-white">
            {creditCard ? `${creditCard.name} (*${creditCard.lastFourDigits})` : t('components.subscriptionCard.notSet')}
          </span>
        </div>

        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <Wallet className="h-4 w-4" />
            {t('components.subscriptionCard.wallet')}
          </div>
          <span className="font-medium text-gray-900 dark:text-white">
            {wallet?.name || t('components.subscriptionCard.notSet')}
          </span>
        </div>
      </div>

      <div className="mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {t(`components.subscriptionCard.billingCycle.${subscription.billingCycle}`)}
          </span>
          <span className="text-lg font-bold text-gray-900 dark:text-white">
            {subscription.amount.toLocaleString('en-US', {
              style: 'currency',
              currency: subscription.currency
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
