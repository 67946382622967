import { useState, useEffect, useMemo } from 'react';
import { Transaction, TransactionFormData } from '../../types/transaction';
import { useCategories } from '../../context/CategoryContext';
import { useWallets } from '../../context/WalletContext';
import { useAuth } from '../../context/AuthContext';
import { useBudgets } from '../../context/BudgetContext';
import { useLabels } from '../../context/LabelContext';
import { uploadTransactionAttachment } from '../../services/storage';

interface UploadingFile {
  file: File;
  progress: number;
  url?: string;
}

const defaultFormData: TransactionFormData = {
  description: '',
  amount: 0,
  currency: 'TRY',
  type: 'expense',
  category: '',
  walletId: '',
  budgetId: undefined,
  date: new Date().toISOString().split('T')[0],
  labels: [],
  paymentMethod: 'cash',
  attachments: [],
  note: '',
  creditCardId: undefined,
  debtDetails: undefined,
  installments: undefined,
  status: 'completed',
  recurrence: undefined
};

export const useTransactionForm = (
  initialData?: Transaction,
  onSubmit?: (data: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => void
) => {
  const { categories } = useCategories();
  const { wallets } = useWallets();
  const { user } = useAuth();
  const { budgets } = useBudgets();
  const { labels, findOrCreateLabel } = useLabels();

  const [associatedBudget, setAssociatedBudget] = useState<string | null>(null);
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const [formData, setFormData] = useState<TransactionFormData>({
    ...defaultFormData,
    currency: user?.settings?.currency || 'TRY'
  });

  // Update currency when wallet changes
  useEffect(() => {
    if (formData.walletId) {
      const selectedWallet = wallets.find(w => w.id === formData.walletId);
      if (selectedWallet?.currency) {
        setFormData(prev => ({
          ...prev,
          currency: selectedWallet.currency
        }));
      }
    }
  }, [formData.walletId, wallets]);

  // Filter categories based on transaction type
  const filteredCategories = useMemo(() => {
    if (formData.type === 'debt') {
      // For debt, show both income and expense categories
      return categories;
    }
    return categories.filter(category => category.type === formData.type);
  }, [categories, formData.type]);

  // Reset form when initialData changes
  useEffect(() => {
    if (initialData) {
      setFormData({
        description: initialData.description,
        amount: initialData.amount,
        currency: initialData.currency || user?.settings?.currency || 'TRY',
        type: initialData.type,
        category: initialData.category,
        walletId: initialData.walletId,
        budgetId: initialData.budgetId,
        date: initialData.date,
        labels: initialData.labels || [],
        paymentMethod: initialData.paymentMethod,
        attachments: initialData.attachments || [],
        note: initialData.note || '',
        creditCardId: initialData.creditCardId,
        debtDetails: initialData.debtDetails,
        installments: initialData.installments,
        status: initialData.status || 'completed',
        recurrence: initialData.recurrence
      });
    } else {
      // Reset to default values when initialData is null
      setFormData({
        ...defaultFormData,
        currency: user?.settings?.currency || 'TRY',
        date: new Date().toISOString().split('T')[0]
      });
      setUploadingFiles([]); // Clear any uploading files
    }
  }, [initialData, user]);

  // Reset category when transaction type changes
  useEffect(() => {
    if (formData.category) {
      const categoryStillValid = filteredCategories.some(cat => cat.id === formData.category);
      if (!categoryStillValid) {
        setFormData(prev => ({ ...prev, category: '' }));
      }
    }
  }, [formData.type, formData.category, filteredCategories]);

  useEffect(() => {
    if (formData.walletId || formData.category) {
      const matchingBudget = budgets.find(budget => 
        budget.wallets.includes(formData.walletId) && // Only match if wallet is explicitly included
        budget.categoryIds.includes(formData.category)
      );

      setAssociatedBudget(matchingBudget?.id || null);
      setFormData(prev => ({
        ...prev,
        budgetId: matchingBudget?.id
      }));
    } else {
      setAssociatedBudget(null);
      setFormData(prev => ({
        ...prev,
        budgetId: undefined
      }));
    }
  }, [formData.walletId, formData.category, budgets]);

  const handleFieldChange = (field: keyof TransactionFormData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleLabelAdd = async (labelName: string) => {
    const labelId = await findOrCreateLabel(labelName);
    if (!formData.labels.includes(labelId)) {
      handleFieldChange('labels', [...formData.labels, labelId]);
    }
  };

  const handleLabelRemove = (labelId: string) => {
    handleFieldChange('labels', formData.labels.filter(l => l !== labelId));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!user || !files.length) return;

    // Create temporary transaction ID if we're creating a new transaction
    const tempTransactionId = initialData?.id || `temp-${Date.now()}`;
    
    // Add files to uploading state
    const newUploadingFiles = files.map(file => ({
      file,
      progress: 0
    }));
    setUploadingFiles(prev => [...prev, ...newUploadingFiles]);

    try {
      // Upload each file and track progress
      const uploadPromises = files.map(async (file, index) => {
        try {
          const url = await uploadTransactionAttachment(user.id, tempTransactionId, file);
          
          // Update progress and URL for this file
          setUploadingFiles(prev => prev.map((f, i) => {
            if (f.file === file) {
              return { ...f, progress: 100, url };
            }
            return f;
          }));

          return url;
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          // Remove failed upload from uploading files
          setUploadingFiles(prev => prev.filter(f => f.file !== file));
          return null;
        }
      });

      const uploadedUrls = (await Promise.all(uploadPromises)).filter(Boolean) as string[];
      
      // Update form data with the new URLs
      handleFieldChange('attachments', [...formData.attachments, ...uploadedUrls]);

      // Clear completed uploads
      setUploadingFiles(prev => prev.filter(f => !f.url));
    } catch (error) {
      console.error('Error uploading attachments:', error);
      // Clear all uploading files on error
      setUploadingFiles([]);
    }
  };

  const removeAttachment = (urlToRemove: string) => {
    handleFieldChange('attachments', formData.attachments.filter(url => url !== urlToRemove));
  };

  const getTypeColor = () => {
    switch (formData.type) {
      case 'income':
        return 'bg-green-50 dark:bg-green-900/30';
      case 'expense':
        return 'bg-red-50 dark:bg-red-900/30';
      case 'debt':
        return 'bg-blue-50 dark:bg-blue-900/30';
      default:
        return 'bg-gray-50 dark:bg-gray-900/30';
    }
  };

  const getLabelSuggestions = (input: string) => {
    if (!input) return [];
    const inputLower = input.toLowerCase();
    return labels
      .filter(label => label.name.toLowerCase().includes(inputLower))
      .map(label => ({ id: label.id, name: label.name }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User must be authenticated');
    }

    if (!formData.walletId) {
      throw new Error('Wallet must be selected');
    }

    // Verify wallet access
    const selectedWallet = wallets.find(w => w.id === formData.walletId);
    if (!selectedWallet) {
      throw new Error('Selected wallet not found');
    }

    // Check if user has access to the wallet
    const hasAccess = selectedWallet.userId === user.id || 
      (selectedWallet.members && user.id in selectedWallet.members);
    
    if (!hasAccess) {
      throw new Error('You do not have permission to create transactions in this wallet');
    }

    // Ensure we have a valid category ID
    if (!formData.category || !categories.some(cat => cat.id === formData.category)) {
      throw new Error('Valid category must be selected');
    }

    // Ensure installments has the required 'current' property
    const installments = formData.installments ? {
      ...formData.installments,
      current: formData.installments.current || 1 // Default to 1 if not provided
    } : undefined;

    // Ensure we have a valid currency
    const currency = formData.currency || selectedWallet.currency || user.settings?.currency || 'TRY';

    if (onSubmit) {
      // Ensure attachments and labels are arrays before submitting
      const submissionData = {
        ...formData,
        currency, // Use the validated currency
        attachments: formData.attachments || [],
        labels: formData.labels || [],
        installments,
        userId: user.id // Explicitly set the userId
      };
      onSubmit(submissionData);
    }
  };

  return {
    formData,
    associatedBudget,
    filteredCategories,
    wallets,
    labels,
    uploadingFiles,
    handleFieldChange,
    handleLabelAdd,
    handleLabelRemove,
    handleFileChange,
    removeAttachment,
    getTypeColor,
    getLabelSuggestions,
    handleSubmit
  };
};
