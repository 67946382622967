export const planLimitModal = {
  title: 'Premium\'a Yükselt',
  description: 'Ücretsiz planda {feature} için sınıra ulaştınız. Premium\'a yükselerek şunların kilidini açın:',
  features: [
    'Sınırsız cüzdan',
    'Sınırsız bütçe',
    'Sınırsız abonelik',
    'Gelişmiş raporlama',
    'Veri dışa aktarma',
    'Takım üyeleri davet etme'
  ],
  buttons: {
    close: 'Daha Sonra',
    upgrade: 'Şimdi Yükselt'
  }
};
