import { SubscriptionService } from '../types/subscription';

export const subscriptionList: SubscriptionService[] = [
  // Entertainment Services
  {
    name: 'Netflix',
    logo: 'https://cdn.worldvectorlogo.com/logos/netflix-4.svg',
    category: 'entertainment',
    description: 'Streaming service for movies and TV shows'
  },
  {
    name: 'HBO Max',
    logo: 'https://cdn.worldvectorlogo.com/logos/hbo-4.svg',
    category: 'entertainment',
    description: 'Premium streaming platform for HBO content'
  },
  {
    name: 'Disney+',
    logo: 'https://cdn.worldvectorlogo.com/logos/disney.svg',
    category: 'entertainment',
    description: 'Disney, Marvel, Star Wars streaming service'
  },
  {
    name: 'Apple Music',
    logo: 'https://cdn.worldvectorlogo.com/logos/apple-music-3.svg',
    category: 'entertainment',
    description: 'Music and audio streaming service'
  },
  {
    name: 'Spotify',
    logo: 'https://cdn.worldvectorlogo.com/logos/spotify-2.svg',
    category: 'entertainment',
    description: 'Music streaming platform'
  },
  {
    name: 'Amazon Prime',
    logo: 'https://raw.githubusercontent.com/detain/svg-logos/b02ee1ac30c7ff4757278337c95588b01ed0954b/svg/a/amazon-prime-video-1.svg',
    category: 'entertainment',
    description: 'Streaming and shopping subscription'
  },
  {
    name: 'YouTube Premium',
    logo: 'https://cdn.worldvectorlogo.com/logos/youtube-icon-5.svg',
    category: 'entertainment',
    description: 'Ad-free YouTube experience'
  },

  // Productivity Tools
  {
    name: 'Microsoft 365',
    logo: 'https://cdn.worldvectorlogo.com/logos/microsoft-office-2013.svg',
    category: 'productivity',
    description: 'Office suite and productivity tools'
  },
  {
    name: 'Adobe Creative Cloud',
    logo: 'https://cdn.worldvectorlogo.com/logos/adobe-creative-cloud-cc.svg',
    category: 'productivity',
    description: 'Creative software suite'
  },
  {
    name: 'Dropbox',
    logo: 'https://cdn.worldvectorlogo.com/logos/dropbox-1.svg',
    category: 'productivity',
    description: 'Cloud storage and file sharing'
  },
  {
    name: 'Notion',
    logo: 'https://cdn.worldvectorlogo.com/logos/notion-2.svg',
    category: 'productivity',
    description: 'All-in-one workspace'
  },
  {
    name: 'Grammarly',
    logo: 'https://cdn.worldvectorlogo.com/logos/grammarly-1.svg',
    category: 'productivity',
    description: 'Writing assistance and grammar checker'
  },
  {
    name: 'Canva',
    logo: 'https://cdn.worldvectorlogo.com/logos/canva-1.svg',
    category: 'productivity',
    description: 'Online design platform'
  },

  // Business Tools
  {
    name: 'Salesforce',
    logo: 'https://cdn.worldvectorlogo.com/logos/salesforce-2.svg',
    category: 'business',
    description: 'Customer relationship management'
  },
  {
    name: 'Shopify',
    logo: 'https://cdn.worldvectorlogo.com/logos/shopify.svg',
    category: 'business',
    description: 'E-commerce platform'
  },
  {
    name: 'Slack',
    logo: 'https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg',
    category: 'business',
    description: 'Business communication platform'
  },
  {
    name: 'Zoom',
    logo: 'https://cdn.worldvectorlogo.com/logos/zoom-app.svg',
    category: 'business',
    description: 'Video conferencing service'
  },
  {
    name: 'LinkedIn Premium',
    logo: 'https://cdn.worldvectorlogo.com/logos/linkedin-icon-2.svg',
    category: 'business',
    description: 'Professional networking platform'
  },

  // Education & Lifestyle
  {
    name: 'Duolingo',
    logo: 'https://cdn.worldvectorlogo.com/logos/duolingo-1.svg',
    category: 'education',
    description: 'Language learning platform'
  },
  {
    name: 'Headspace',
    logo: 'https://cdn.worldvectorlogo.com/logos/headspace-1.svg',
    category: 'lifestyle',
    description: 'Meditation and mindfulness app'
  }
];
