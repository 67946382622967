const translations = {
  title: 'Yaklaşan Ödemeler',
  noSubscriptions: 'Yaklaşan abonelik ödemesi yok',
  dueIn: '{{days}} gün içinde',
  dueToday: 'Bugün ödenecek',
  overdue: '{{days}} gün gecikmiş',
  amount: 'Tutar',
  nextPayment: 'Sonraki Ödeme',
  billingCycle: {
    monthly: 'Aylık',
    yearly: 'Yıllık',
    weekly: 'Haftalık',
    daily: 'Günlük'
  },
  status: {
    active: 'Aktif',
    inactive: 'Pasif',
    cancelled: 'İptal Edildi',
    pending: 'Beklemede'
  },
  actions: {
    viewDetails: 'Detayları Görüntüle',
    markAsPaid: 'Ödendi Olarak İşaretle',
    cancel: 'Aboneliği İptal Et'
  },
  confirmations: {
    markAsPaid: 'Bu ödemeyi ödendi olarak işaretlemek istediğinizden emin misiniz?',
    cancel: 'Bu aboneliği iptal etmek istediğinizden emin misiniz?'
  }
};

export default translations;
