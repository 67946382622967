import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as authOperations from '../services/authOperations';
import { getTranslations as getLocaleTranslations, languages, Language } from '../locales';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) throw new Error('useLanguage must be used within LanguageProvider');
  return context;
};

const detectUserLanguage = (): Language => {
  const browserLang = navigator.language.split('-')[0];
  return browserLang === 'tr' ? 'tr' : 'en';
};

// Helper function to get nested value
const getNestedValue = (obj: any, path: string[]): string | undefined => {
  let current = obj;
  for (const key of path) {
    if (current === undefined || current === null) {
      return undefined;
    }
    current = current[key];
  }
  return typeof current === 'string' ? current : undefined;
};

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [language, setLanguage] = useState<Language>(() => 
    (user?.settings?.language as Language) || detectUserLanguage()
  );

  const t = (key: string): string => {
    try {
      const parts = key.split('.');
      const allTranslations = getLocaleTranslations(language);
      const value = getNestedValue(allTranslations, parts);
      
      if (value) {
        return value;
      }

      console.warn(`Translation not found for key: ${key}`);
      return key;
    } catch (error) {
      console.error(`Error translating key: ${key}`, error);
      return key;
    }
  };

  // Update user's language preference when they change it
  useEffect(() => {
    const updateUserLanguage = async () => {
      if (user && user.settings?.language !== language) {
        try {
          await authOperations.updateUserProfile(user.id, {
            settings: {
              ...user.settings,
              language
            }
          });
        } catch (error) {
          console.error('Failed to update user language preference:', error);
        }
      }
    };

    updateUserLanguage();
  }, [language, user]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { languages };
export type { Language };
