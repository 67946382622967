import React from 'react';
import { Edit2, ArrowUpCircle, ArrowDownCircle, CreditCard, ImageIcon, FileText } from 'lucide-react';
import { Transaction } from '../../types';
import { useLanguage } from '../../context/LanguageContext';
import { useCurrency } from '../../context/CurrencyContext';
import LabelTag from '../labels/LabelTag';

interface TransactionPageListProps {
  transactions: Transaction[];
  selectedTransactions: string[];
  onSelect: (id: string) => void;
  onEdit: (transaction: Transaction) => void;
  onView: (transaction: Transaction) => void;
  onAttachmentClick: (e: React.MouseEvent, url: string) => void;
  getWalletInfo: (walletId: string) => { name: string; color: string; currency: string };
  getCategoryName: (categoryId: string) => string;
  getLabelInfo: (labelId: string) => { name: string; color: string };
}

const TransactionPageList: React.FC<TransactionPageListProps> = ({
  transactions,
  selectedTransactions,
  onSelect,
  onEdit,
  onView,
  onAttachmentClick,
  getWalletInfo,
  getCategoryName,
  getLabelInfo
}) => {
  const { t } = useLanguage();
  const { formatAmount, defaultCurrency } = useCurrency();

  const formatInstallments = (current: number, total: number) => {
    return t('transactions.installments.format')
      .replace('{{current}}', current.toString())
      .replace('{{total}}', total.toString());
  };

  const isImage = (url: string): boolean => {
    return !!url.match(/\.(jpg|jpeg|png|gif|webp)$/i);
  };

  if (transactions.length === 0) {
    return (
      <div className="p-8 text-center text-gray-500 dark:text-gray-400">
        {t('pages.transactionsPage.noTransactions')}
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-100 dark:divide-gray-700">
      {transactions.map(transaction => {
        const { name: walletName, color: walletColor, currency: walletCurrency } = getWalletInfo(transaction.walletId);
        const hasAttachments = transaction.attachments && transaction.attachments.length > 0;
        // Use transaction currency, fall back to wallet currency, then user's default currency
        const displayCurrency = transaction.currency || walletCurrency || defaultCurrency;

        return (
          <div
            key={transaction.id}
            className="flex items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors cursor-pointer"
            onClick={() => onView(transaction)}
          >
            <div className="flex items-center gap-4">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(transaction.id);
                }}
                className="text-gray-400 dark:text-gray-600 hover:text-gray-600 dark:hover:text-gray-400"
              >
                {selectedTransactions.includes(transaction.id) ? (
                  <div className="h-5 w-5 rounded border-2 border-indigo-600 bg-indigo-600" />
                ) : (
                  <div className="h-5 w-5 rounded border-2 border-gray-300 dark:border-gray-600" />
                )}
              </button>

              <div className={`p-2 rounded-lg ${
                transaction.amount > 0 ? 'bg-green-100 dark:bg-green-900/30' : 'bg-red-100 dark:bg-red-900/30'
              }`}>
                {transaction.amount > 0 ? (
                  <ArrowUpCircle className="h-5 w-5 text-green-600 dark:text-green-400" />
                ) : (
                  <ArrowDownCircle className="h-5 w-5 text-red-600 dark:text-red-400" />
                )}
              </div>

              <div>
                <div className="flex items-center gap-2">
                  <p className="font-medium text-gray-900 dark:text-white">
                    {transaction.description}
                  </p>
                  <span className={`px-2 py-0.5 text-xs rounded-full bg-${walletColor}-100 dark:bg-${walletColor}-900/30 text-${walletColor}-700 dark:text-${walletColor}-300`}>
                    {walletName}
                  </span>
                  {transaction.paymentMethod === 'credit' && (
                    <span className="px-2 py-0.5 text-xs rounded-full bg-purple-100 text-purple-700 dark:bg-purple-900/50 dark:text-purple-300 flex items-center gap-1">
                      <CreditCard className="h-3 w-3" />
                      {t('transactions.fields.creditCard')}
                    </span>
                  )}
                  {transaction.installments?.enabled && (
                    <span className="px-2 py-0.5 text-xs rounded-full bg-blue-100 text-blue-700 dark:bg-blue-900/50 dark:text-blue-300">
                      {formatInstallments(
                        transaction.installments.current || 1,
                        transaction.installments.count
                      )}
                    </span>
                  )}
                  {hasAttachments && (
                    <div className="flex gap-1">
                      {transaction.attachments!.map((url, index) => (
                        <button
                          key={`attachment-${transaction.id}-${index}`}
                          onClick={(e) => onAttachmentClick(e, url)}
                          className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                        >
                          {isImage(url) ? (
                            <ImageIcon className="h-4 w-4" />
                          ) : (
                            <FileText className="h-4 w-4" />
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {getCategoryName(transaction.category)}
                </p>
                {transaction.labels && transaction.labels.length > 0 && (
                  <div className="flex gap-2 mt-1">
                    {transaction.labels.slice(0, 2).map((labelId, index) => {
                      const { name, color } = getLabelInfo(labelId);
                      return (
                        <LabelTag
                          key={`label-${transaction.id}-${index}`}
                          name={name}
                          color={color}
                        />
                      );
                    })}
                    {transaction.labels.length > 2 && (
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        +{transaction.labels.length - 2} {t('common.more')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="ml-auto flex items-center gap-4">
              <div className="text-right">
                <p className={`font-medium ${
                  transaction.amount > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
                }`}>
                  {transaction.amount > 0 ? '+' : ''}
                  {formatAmount(Math.abs(transaction.amount), displayCurrency)}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {new Date(transaction.date).toLocaleDateString()}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(transaction);
                }}
                className="p-2 text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <Edit2 className="h-5 w-5" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransactionPageList;
