const translations = {
  startDate: 'Start Date',
  endDate: 'End Date',
  presets: {
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    last3Month: 'Last 3 Months',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    allTime: 'All Time'
  }
};

export default translations;
