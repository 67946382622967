const translations = {
  home: 'Home',
  dashboard: 'Dashboard',
  transactions: 'Transactions',
  wallets: 'Wallets',
  budgets: 'Budgets',
  reports: 'Reports',
  categories: 'Categories',
  labels: 'Labels',
  settings: 'Settings',
  account: 'Account',
  tagline: 'Track your expenses, manage your money',
  menu: {
    overview: 'Overview',
    analytics: 'Analytics',
    history: 'History',
    preferences: 'Preferences',
    help: 'Help',
    about: 'About'
  },
  breadcrumbs: {
    home: 'Home',
    back: 'Back',
    current: 'Current'
  },
  actions: {
    new: 'New',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close'
  }
};

export default translations;
