import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

interface Language {
  code: string;
  name: string;
  flag: string;
  isActive: boolean;
  translations: number;
  lastUpdated: string;
}

interface AdminLanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Language) => void;
  language?: Language | null;
}

const AdminLanguageModal: React.FC<AdminLanguageModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  language
}) => {
  const [formData, setFormData] = useState<Omit<Language, 'lastUpdated'>>({
    code: '',
    name: '',
    flag: '',
    isActive: true,
    translations: 0
  });

  useEffect(() => {
    if (language) {
      setFormData(language);
    } else {
      setFormData({
        code: '',
        name: '',
        flag: '',
        isActive: true,
        translations: 0
      });
    }
  }, [language]);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      lastUpdated: new Date().toISOString()
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {language ? 'Edit Language' : 'Add Language'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Language Name
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={e => setFormData(d => ({ ...d, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Language Code
            </label>
            <input
              type="text"
              value={formData.code}
              onChange={e => setFormData(d => ({ ...d, code: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              required
              placeholder="en"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Flag Emoji
            </label>
            <input
              type="text"
              value={formData.flag}
              onChange={e => setFormData(d => ({ ...d, flag: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              required
              placeholder="🇬🇧"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              checked={formData.isActive}
              onChange={e => setFormData(d => ({ ...d, isActive: e.target.checked }))}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900 dark:text-white">
              Active
            </label>
          </div>

          <div className="flex justify-end gap-3 mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
            >
              {language ? 'Save Changes' : 'Add Language'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLanguageModal;