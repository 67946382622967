import React, { useState } from 'react';
import { CreditCard, ArrowUpRight, ArrowDownRight, Share2, Users } from 'lucide-react';
import { Wallet, WalletRole } from '../../types';
import { useTransactions } from '../../context/TransactionContext';
import { useWallets } from '../../context/WalletContext';
import { useLanguage } from '../../context/LanguageContext';
import { useCurrency } from '../../context/CurrencyContext';
import ShareWalletModal from './ShareWalletModal';
import { getTranslations } from '../../locales/components/walletCard';

interface WalletCardProps {
  wallet: Wallet;
  onClick?: () => void;
}

const colorVariants = {
  indigo: 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900 dark:text-indigo-300',
  emerald: 'bg-emerald-100 text-emerald-600 dark:bg-emerald-900 dark:text-emerald-300',
  amber: 'bg-amber-100 text-amber-600 dark:bg-amber-900 dark:text-amber-300',
};

const WalletCard = ({ wallet, onClick }: WalletCardProps) => {
  const { transactions } = useTransactions();
  const { shareWallet, removeMember } = useWallets();
  const { language } = useLanguage();
  const { formatAmount, defaultCurrency } = useCurrency();
  const translations = getTranslations(language);
  const [showShareModal, setShowShareModal] = useState(false);
  
  // Default to 'indigo' if color is undefined or not in colorVariants
  const colorClass = colorVariants[wallet?.color as keyof typeof colorVariants] || colorVariants.indigo;

  const walletTransactions = transactions.filter(t => t.walletId === wallet.id);
  const stats = {
    income: walletTransactions.filter(t => t.amount > 0).reduce((sum, t) => sum + t.amount, 0),
    expenses: Math.abs(walletTransactions.filter(t => t.amount < 0).reduce((sum, t) => sum + t.amount, 0)),
    transactionCount: walletTransactions.length
  };

  const handleShare = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowShareModal(true);
  };

  const handleInvite = async (email: string, role: WalletRole) => {
    await shareWallet(wallet.id, email, role);
  };

  const handleRemoveMember = async (memberId: string) => {
    await removeMember(wallet.id, memberId);
  };

  // Use the wallet's currency or fall back to user's default currency
  const displayCurrency = wallet.currency || defaultCurrency;

  return (
    <>
      <div
        className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-md transition-shadow cursor-pointer"
        onClick={onClick}
      >
        <div className="flex items-center justify-between mb-4">
          <div className={`p-2 rounded-lg ${colorClass}`}>
            <CreditCard className="h-5 w-5" />
          </div>
          <div className="flex items-center gap-2">
            {wallet.members && Object.keys(wallet.members).length > 0 && (
              <div className="flex items-center px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 text-sm">
                <Users className="h-4 w-4 mr-1" />
                {Object.keys(wallet.members).length}
              </div>
            )}
            <button
              onClick={handleShare}
              className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
              title={translations.actions.share}
            >
              <Share2 className="h-4 w-4" />
            </button>
          </div>
        </div>
        
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">{wallet.name}</h3>
        <p className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          {formatAmount(wallet.balance, displayCurrency)}
        </p>

        <div className="space-y-3">
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center gap-2 text-green-600 dark:text-green-400">
              <ArrowUpRight className="h-4 w-4" />
              {translations.stats.income}
            </div>
            <span className="font-medium text-green-600 dark:text-green-400">
              {formatAmount(stats.income, displayCurrency)}
            </span>
          </div>
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
              <ArrowDownRight className="h-4 w-4" />
              {translations.stats.expenses}
            </div>
            <span className="font-medium text-red-600 dark:text-red-400">
              {formatAmount(stats.expenses, displayCurrency)}
            </span>
          </div>
        </div>

        <div className="mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-500 dark:text-gray-400">
              {`${stats.transactionCount} ${translations.stats.transactions[stats.transactionCount === 1 ? 'one' : 'other']}`}
            </span>
            <span className="text-indigo-600 dark:text-indigo-400 font-medium">
              {translations.actions.viewDetails} →
            </span>
          </div>
        </div>
      </div>

      <ShareWalletModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        wallet={wallet}
        onInvite={handleInvite}
        onRemoveMember={handleRemoveMember}
      />
    </>
  );
};

export default WalletCard;
