import React, { useMemo } from 'react';
import { useTransactions } from '../../context/TransactionContext';
import { useLanguage } from '../../context/LanguageContext';
import { useCurrency } from '../../context/CurrencyContext';
import { Transaction } from '../../types';
import { formatDate } from '../../utils/formatters';

const UpcomingPayments: React.FC = () => {
  const { transactions } = useTransactions();
  const { language, t } = useLanguage();
  const { formatAmount } = useCurrency();

  const upcomingPayments = useMemo(() => {
    const now = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

    return transactions
      .filter((transaction: Transaction) => {
        const transactionDate = new Date(transaction.date);
        
        // Only include future transactions within the next 30 days
        if (transactionDate < now || transactionDate > thirtyDaysFromNow) {
          return false;
        }

        // Include if it's a credit card payment
        if (transaction.paymentMethod === 'credit') return true;

        // Include if it has installments
        if (transaction.installments?.enabled) return true;

        // Include if it's a debt
        if (transaction.type === 'debt') return true;

        return false;
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [transactions]);

  const getTransactionLabel = (transaction: Transaction): string => {
    if (transaction.paymentMethod === 'credit') {
      return t('dashboard.upcomingPayments.creditCard');
    }
    if (transaction.installments?.enabled) {
      const template = t('dashboard.upcomingPayments.installment');
      return template
        .replace('{{current}}', transaction.installments.current.toString())
        .replace('{{total}}', transaction.installments.count.toString());
    }
    if (transaction.type === 'debt') {
      return t('dashboard.upcomingPayments.debt');
    }
    return '';
  };

  if (upcomingPayments.length === 0) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          {t('dashboard.upcomingPayments.title')}
        </h2>
        <p className="text-gray-600 dark:text-gray-400">
          {t('dashboard.upcomingPayments.noPayments')}
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
          {t('dashboard.upcomingPayments.title')}
        </h2>
        <div className="space-y-4">
          {upcomingPayments.map((transaction) => (
            <div
              key={transaction.id}
              className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
            >
              <div className="flex-1">
                <p className="font-medium text-gray-900 dark:text-white">
                  {transaction.description}
                </p>
                <div className="flex items-center mt-1">
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {formatDate(transaction.date, language)}
                  </span>
                  <span className="mx-2 text-gray-400">•</span>
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {getTransactionLabel(transaction)}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <p className={`font-semibold ${
                  transaction.type === 'income' 
                    ? 'text-green-600 dark:text-green-400' 
                    : 'text-red-600 dark:text-red-400'
                }`}>
                  {transaction.type === 'income' ? '+' : '-'}
                  {formatAmount(Math.abs(transaction.amount), transaction.currency)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpcomingPayments;
