import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CircleDollarSign, Wallet, PieChart, CreditCard, Settings, Target, Tags, User, Users, Calendar, Crown, ArrowDownUp, Shield, X } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';

interface SidebarProps {
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose }) => {
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useLanguage();
  
  const menuItems = [
    { icon: CircleDollarSign, label: t('components.sidebar.menu.overview'), path: '/' },
    { icon: Wallet, label: t('components.sidebar.menu.wallets'), path: '/wallets' },
    { icon: ArrowDownUp, label: t('components.sidebar.menu.transactions'), path: '/transactions' },
    { 
      icon: PieChart, 
      label: t('components.sidebar.menu.reports'), 
      path: '/reports',
      requiresPremium: true
    },
    { icon: CreditCard, label: t('components.sidebar.menu.creditCards'), path: '/credit-cards' },
    { icon: Calendar, label: t('components.sidebar.menu.subscriptions'), path: '/subscriptions' },
    { icon: Target, label: t('components.sidebar.menu.budgets'), path: '/budgets' },
    { 
      icon: Settings, 
      label: t('components.sidebar.menu.settings'),
      path: '/settings',
      submenu: [
        { icon: Tags, label: t('components.sidebar.settings.categories'), path: '/settings/categories' },
        { icon: Tags, label: t('components.sidebar.settings.labels'), path: '/settings/labels' },
        { 
          icon: Users, 
          label: t('components.sidebar.settings.inviteMembers'), 
          path: '/settings/invite',
          requiresPremium: true
        },
        { icon: User, label: t('components.sidebar.settings.account'), path: '/settings/account' }
      ]
    },
    // Admin section only visible to superadmin
    ...(user?.role === 'superadmin' ? [{
      icon: Shield,
      label: t('components.sidebar.menu.admin'),
      path: '/admin',
      submenu: [
        { icon: Users, label: t('components.sidebar.admin.users'), path: '/admin/users' },
        { icon: Tags, label: t('components.sidebar.admin.categories'), path: '/admin/categories' },
        { icon: Tags, label: t('components.sidebar.admin.labels'), path: '/admin/labels' },
        { icon: Settings, label: t('components.sidebar.admin.settings'), path: '/admin/settings' },
        { icon: Crown, label: t('components.sidebar.admin.subscriptions'), path: '/admin/subscriptions' },
        { icon: Calendar, label: t('components.sidebar.admin.emails'), path: '/admin/emails' },
        { icon: PieChart, label: t('components.sidebar.admin.dashboard'), path: '/admin/dashboard' }
      ]
    }] : [])
  ];

  const isActive = (path: string) => {
    if (path === '/') return location.pathname === path;
    return location.pathname.startsWith(path);
  };

  const isPremiumFeature = (requiresPremium?: boolean) => {
    return requiresPremium && user?.subscription?.plan !== 'premium';
  };

  return (
    <aside className="h-full flex flex-col">
      <div className="p-6 flex items-center justify-between">
        <Link to="/" className="flex items-center gap-2">
          <CircleDollarSign className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
          <span className="text-xl font-bold text-gray-900 dark:text-white">
            {t('components.sidebar.appName')}
          </span>
        </Link>
        <button
          onClick={onClose}
          className="lg:hidden p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      <p className="px-6 -mt-4 text-sm text-gray-500 dark:text-gray-400">
        {t('components.sidebar.tagline')}
      </p>
      
      <nav className="flex-1 px-4 space-y-1 mt-6">
        {menuItems.map((item) => (
          <div key={item.label}>
            {item.submenu ? (
              <>
                <button
                  className={`flex items-center w-full px-4 py-3 rounded-lg transition-colors group ${
                    isActive(item.path)
                      ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-300'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                  }`}
                >
                  <item.icon className={`h-5 w-5 mr-3 ${
                    isActive(item.path) ? 'text-indigo-600 dark:text-indigo-300' : 'group-hover:text-indigo-600 dark:group-hover:text-indigo-300'
                  }`} />
                  <span className="font-medium">{item.label}</span>
                </button>
                <div className="ml-12 mt-1 space-y-1">
                  {item.submenu.map(subitem => (
                    <Link
                      key={subitem.path}
                      to={subitem.path}
                      className={`flex items-center w-full px-4 py-2 rounded-lg transition-colors group ${
                        isPremiumFeature(subitem.requiresPremium)
                          ? 'opacity-50 cursor-not-allowed'
                          : isActive(subitem.path)
                          ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-300'
                          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                      }`}
                      onClick={(e) => {
                        if (isPremiumFeature(subitem.requiresPremium)) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <subitem.icon className={`h-4 w-4 mr-3 ${
                        isActive(subitem.path) ? 'text-indigo-600 dark:text-indigo-300' : 'group-hover:text-indigo-600 dark:group-hover:text-indigo-300'
                      }`} />
                      <span className="font-medium text-sm">{subitem.label}</span>
                      {isPremiumFeature(subitem.requiresPremium) && (
                        <Crown className="h-3 w-3 ml-2 text-amber-500" />
                      )}
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <Link
                to={item.path}
                className={`flex items-center w-full px-4 py-3 rounded-lg transition-colors group ${
                  isPremiumFeature(item.requiresPremium)
                    ? 'opacity-50 cursor-not-allowed'
                    : isActive(item.path)
                    ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-300'
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                }`}
                onClick={(e) => {
                  if (isPremiumFeature(item.requiresPremium)) {
                    e.preventDefault();
                  }
                }}
              >
                <item.icon className={`h-5 w-5 mr-3 ${
                  isActive(item.path) ? 'text-indigo-600 dark:text-indigo-300' : 'group-hover:text-indigo-600 dark:group-hover:text-indigo-300'
                }`} />
                <span className="font-medium">{item.label}</span>
                {isPremiumFeature(item.requiresPremium) && (
                  <Crown className="h-3 w-3 ml-2 text-amber-500" />
                )}
              </Link>
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
