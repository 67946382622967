import React, { useState } from 'react';
import { X, ArrowUpRight, ArrowDownRight, Calendar, Tag, FileText, Image as ImageIcon, File, Eye, Wallet, CreditCard } from 'lucide-react';
import { Transaction } from '../../types';
import { useWallets } from '../../context/WalletContext';
import { useCreditCards } from '../../context/CreditCardContext';
import { useCategories } from '../../context/CategoryContext';
import { useLabels } from '../../context/LabelContext';
import { useLanguage } from '../../context/LanguageContext';
import { useCurrency } from '../../context/CurrencyContext';
import { getTranslations } from '../../locales/components/transactionDetails';
import LabelTag from '../labels/LabelTag';
import ImagePreviewModal from './ImagePreviewModal';

interface TransactionDetailsProps {
  transaction: Transaction;
  isOpen: boolean;
  onClose: () => void;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ transaction, isOpen, onClose }) => {
  const { wallets } = useWallets();
  const { creditCards } = useCreditCards();
  const { categories } = useCategories();
  const { labels: allLabels } = useLabels();
  const { language } = useLanguage();
  const { formatAmount } = useCurrency();
  const translations = getTranslations(language);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  
  if (!isOpen) return null;

  const isImage = (url: string) => url.match(/\.(jpg|jpeg|png|gif|webp)$/i);
  const wallet = wallets.find(w => w.id === transaction.walletId);
  const creditCard = transaction.creditCardId ? creditCards.find(c => c.id === transaction.creditCardId) : null;
  const category = categories.find(c => c.id === transaction.category);

  // Get the currency from either the transaction or the wallet
  const displayCurrency = transaction.currency || wallet?.currency;

  const getLabelInfo = (labelId: string) => {
    const label = allLabels.find(l => l.id === labelId);
    return {
      name: label?.name || labelId,
      color: label?.color || 'indigo'
    };
  };

  const handlePreview = (url: string) => {
    if (isImage(url)) {
      setPreviewImage(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const getTypeIcon = () => {
    switch (transaction.type) {
      case 'income':
        return <ArrowUpRight className="h-6 w-6 text-green-600" />;
      case 'expense':
        return <ArrowDownRight className="h-6 w-6 text-red-600" />;
      case 'debt':
        return <ArrowUpRight className="h-6 w-6 text-amber-600" />;
      default:
        return <ArrowUpRight className="h-6 w-6 text-gray-600" />;
    }
  };

  const getTypeColor = () => {
    switch (transaction.type) {
      case 'income':
        return 'bg-green-100';
      case 'expense':
        return 'bg-red-100';
      case 'debt':
        return 'bg-amber-100';
      default:
        return 'bg-gray-100';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white dark:bg-gray-800 border-b border-gray-100 dark:border-gray-700 px-6 py-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">{translations.title}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* Header with amount and type */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className={`p-3 rounded-xl ${getTypeColor()}`}>
                {getTypeIcon()}
              </div>
              <div>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                  {transaction.amount > 0 ? '+' : ''}{formatAmount(Math.abs(transaction.amount), displayCurrency)}
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  {translations.types[transaction.type as keyof typeof translations.types]}
                </p>
              </div>
            </div>
          </div>

          {/* Main details */}
          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.description}</label>
                <p className="mt-1 text-gray-900 dark:text-white">{transaction.description}</p>
              </div>

              <div>
                <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.category}</label>
                <p className="mt-1 text-gray-900 dark:text-white">{category?.name || transaction.category}</p>
              </div>

              <div>
                <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.wallet}</label>
                {wallet && (
                  <div className="mt-1 flex items-center gap-2">
                    <Wallet className={`h-4 w-4 text-${wallet.color}-600`} />
                    <span className="text-gray-900 dark:text-white">{wallet.name}</span>
                  </div>
                )}
              </div>

              {transaction.paymentMethod === 'credit' && creditCard && (
                <div>
                  <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.paymentMethod}</label>
                  <div className="mt-1 flex items-center gap-2">
                    <CreditCard className="h-4 w-4 text-purple-600" />
                    <span className="text-gray-900 dark:text-white">
                      {creditCard.name} (*{creditCard.lastFourDigits})
                    </span>
                  </div>
                </div>
              )}

              <div>
                <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.date}</label>
                <div className="mt-1 flex items-center gap-2 text-gray-900 dark:text-white">
                  <Calendar className="h-4 w-4 text-gray-400" />
                  {new Date(transaction.date).toLocaleDateString()}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {transaction.type === 'debt' && transaction.debtDetails && (
                <>
                  <div>
                    <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.personName}</label>
                    <p className="mt-1 text-gray-900 dark:text-white">{transaction.debtDetails.personName}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.dueDate}</label>
                    <p className="mt-1 text-gray-900 dark:text-white">
                      {new Date(transaction.debtDetails.dueDate).toLocaleDateString()}
                    </p>
                  </div>
                </>
              )}

              {transaction.installments?.enabled && (
                <div>
                  <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.installments}</label>
                  <p className="mt-1 text-gray-900 dark:text-white">
                    {translations.installmentsFormat
                      .replace('{{count}}', transaction.installments.count.toString())
                      .replace('{{period}}', transaction.installments.period)
                      .replace('{{amount}}', formatAmount(transaction.amount / (transaction.installments.count || 1), displayCurrency))}
                  </p>
                </div>
              )}

              {transaction.labels && transaction.labels.length > 0 && (
                <div>
                  <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.labels}</label>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {transaction.labels.map(labelId => {
                      const { name, color } = getLabelInfo(labelId);
                      return (
                        <LabelTag
                          key={labelId}
                          name={name}
                          color={color}
                        />
                      );
                    })}
                  </div>
                </div>
              )}

              {transaction.note && (
                <div>
                  <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{translations.note}</label>
                  <div className="mt-1 flex items-start gap-2 text-gray-900 dark:text-white">
                    <FileText className="h-4 w-4 text-gray-400 mt-1" />
                    <p className="flex-1 whitespace-pre-wrap">{transaction.note}</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Attachments */}
          {transaction.attachments && transaction.attachments.length > 0 && (
            <div>
              <label className="text-sm font-medium text-gray-500 dark:text-gray-400 block mb-3">{translations.attachments}</label>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {transaction.attachments.map((url, index) => (
                  <div
                    key={index}
                    className="relative group border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden bg-gray-50 dark:bg-gray-800"
                  >
                    <div 
                      className="aspect-square w-full cursor-pointer"
                      onClick={() => handlePreview(url)}
                    >
                      <img
                        src={url}
                        alt={translations.attachment.replace('{{number}}', (index + 1).toString())}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          // If image fails to load, show file icon
                          e.currentTarget.style.display = 'none';
                          const parent = e.currentTarget.parentElement;
                          if (parent) {
                            const fileIcon = document.createElement('div');
                            fileIcon.className = 'w-full h-full flex items-center justify-center';
                            fileIcon.innerHTML = '<svg class="h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>';
                            parent.appendChild(fileIcon);
                          }
                        }}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity">
                        <Eye className="h-6 w-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <ImagePreviewModal
        isOpen={!!previewImage}
        onClose={() => setPreviewImage(null)}
        imageUrl={previewImage || ''}
      />
    </div>
  );
};

export default TransactionDetails;
