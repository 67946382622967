import en from './en';
import tr from './tr';

export interface TransactionTranslations {
  title: string;
  subtitle: string;
  new: string;
  types: {
    all: string;
    income: string;
    expense: string;
  };
  status: {
    all: string;
    completed: string;
    pending: string;
    cancelled: string;
  };
  sortOrder: string;
  installments: {
    format: string;
    periods: {
      monthly: string;
      weekly: string;
      yearly: string;
    };
  };
  fields: {
    description: string;
    amount: string;
    date: string;
    category: string;
    wallet: string;
    labels: string;
    attachments: string;
    notes: string;
    creditCard: string;
    paymentMethod: string;
    status: string;
  };
  noTransactions: string;
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
