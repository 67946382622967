import React, { useState, useEffect } from 'react';
import { X, Infinity } from 'lucide-react';
import { SubscriptionPlan } from '../../types/subscription';

interface AdminSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: SubscriptionPlan) => void;
  initialData?: SubscriptionPlan;
}

const AdminSubscriptionModal: React.FC<AdminSubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}) => {
  const [formData, setFormData] = useState<SubscriptionPlan>({
    id: initialData?.id || '',
    name: initialData?.name || '',
    description: initialData?.description || '',
    price: initialData?.price || 0,
    billingCycle: initialData?.billingCycle || 'monthly',
    features: initialData?.features || [],
    limits: {
      wallets: initialData?.limits.wallets || 1,
      budgets: initialData?.limits.budgets || 1,
      subscriptions: initialData?.limits.subscriptions || 1,
      inviteMembers: initialData?.limits.inviteMembers || false,
      allowExport: initialData?.limits.allowExport || false,
      allowReports: initialData?.limits.allowReports || false
    },
    isActive: initialData?.isActive ?? true
  });

  const [isUnlimited, setIsUnlimited] = useState({
    wallets: initialData?.limits.wallets === -1,
    budgets: initialData?.limits.budgets === -1,
    subscriptions: initialData?.limits.subscriptions === -1,
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setIsUnlimited({
        wallets: initialData.limits.wallets === -1,
        budgets: initialData.limits.budgets === -1,
        subscriptions: initialData.limits.subscriptions === -1,
      });
    }
  }, [initialData]);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      limits: {
        ...formData.limits,
        wallets: isUnlimited.wallets ? -1 : formData.limits.wallets,
        budgets: isUnlimited.budgets ? -1 : formData.limits.budgets,
        subscriptions: isUnlimited.subscriptions ? -1 : formData.limits.subscriptions,
      }
    };
    onSubmit(submissionData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {initialData ? 'Edit Subscription Plan' : 'Add Subscription Plan'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Plan Name
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Price
              </label>
              <input
                type="number"
                value={formData.price}
                onChange={e => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                min="0"
                step="0.01"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Billing Cycle
              </label>
              <select
                value={formData.billingCycle}
                onChange={e => setFormData(prev => ({ ...prev, billingCycle: e.target.value as 'monthly' | 'yearly' }))}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                required
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>

          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Plan Limits
            </label>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Wallets
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={isUnlimited.wallets ? '' : formData.limits.wallets}
                    onChange={e => setFormData(prev => ({
                      ...prev,
                      limits: { ...prev.limits, wallets: parseInt(e.target.value) }
                    }))}
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                    min="1"
                    disabled={isUnlimited.wallets}
                    required={!isUnlimited.wallets}
                  />
                  <label className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      checked={isUnlimited.wallets}
                      onChange={e => setIsUnlimited(prev => ({ ...prev, wallets: e.target.checked }))}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400">Unlimited</span>
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Budgets
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={isUnlimited.budgets ? '' : formData.limits.budgets}
                    onChange={e => setFormData(prev => ({
                      ...prev,
                      limits: { ...prev.limits, budgets: parseInt(e.target.value) }
                    }))}
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                    min="1"
                    disabled={isUnlimited.budgets}
                    required={!isUnlimited.budgets}
                  />
                  <label className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      checked={isUnlimited.budgets}
                      onChange={e => setIsUnlimited(prev => ({ ...prev, budgets: e.target.checked }))}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400">Unlimited</span>
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Subscriptions
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={isUnlimited.subscriptions ? '' : formData.limits.subscriptions}
                    onChange={e => setFormData(prev => ({
                      ...prev,
                      limits: { ...prev.limits, subscriptions: parseInt(e.target.value) }
                    }))}
                    className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
                    min="1"
                    disabled={isUnlimited.subscriptions}
                    required={!isUnlimited.subscriptions}
                  />
                  <label className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      checked={isUnlimited.subscriptions}
                      onChange={e => setIsUnlimited(prev => ({ ...prev, subscriptions: e.target.checked }))}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400">Unlimited</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.limits.allowExport}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    limits: { ...prev.limits, allowExport: e.target.checked }
                  }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  Allow Export
                </span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.limits.inviteMembers}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    limits: { ...prev.limits, inviteMembers: e.target.checked }
                  }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  Allow Member Invites
                </span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.limits.allowReports}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    limits: { ...prev.limits, allowReports: e.target.checked }
                  }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  Allow Reports
                </span>
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Features (one per line)
            </label>
            <textarea
              value={formData.features.join('\n')}
              onChange={e => setFormData(prev => ({
                ...prev,
                features: e.target.value.split('\n').filter(f => f.trim())
              }))}
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter features, one per line"
            />
          </div>

          <div className="flex justify-end gap-3 pt-6 border-t border-gray-200 dark:border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
            >
              {initialData ? 'Save Changes' : 'Add Plan'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminSubscriptionModal;