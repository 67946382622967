import { categoryModal as en } from './en';
import { categoryModal as tr } from './tr';
import { getTranslations as getCommonTranslations } from '../../common';

export type CategoryModalTranslations = typeof en;

export const translations = {
  en,
  tr
} as const;

// Type guard to check if a language is supported
export const isSupportedLanguage = (lang: string): lang is keyof typeof translations => {
  return lang in translations;
};

// Helper to get translations for a specific language
export const getTranslations = (lang: string) => {
  const common = getCommonTranslations(lang as 'en' | 'tr');
  const componentSpecific = isSupportedLanguage(lang) ? translations[lang] : translations.en;

  return {
    ...componentSpecific,
    buttons: {
      cancel: common.cancel,
      save: common.save,
      add: common.add
    }
  };
};
