import en from './en';
import tr from './tr';

export interface AuthTranslations {
  login: {
    title: string;
    noAccount: string;
    createAccount: string;
    email: string;
    password: string;
    rememberMe: string;
    forgotPassword: string;
    submit: string;
    errors: {
      invalidEmail: string;
      invalidPassword: string;
      userNotFound: string;
      tooManyAttempts: string;
    };
  };
  register: {
    title: string;
    haveAccount: string;
    signIn: string;
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    submit: string;
    errors: {
      emailInUse: string;
      passwordTooWeak: string;
      passwordsDontMatch: string;
    };
  };
  forgotPassword: {
    title: string;
    description: string;
    email: string;
    submit: string;
    success: string;
    errors: {
      userNotFound: string;
      tooManyAttempts: string;
    };
  };
  resetPassword: {
    title: string;
    password: string;
    confirmPassword: string;
    submit: string;
    success: string;
    errors: {
      invalidToken: string;
      passwordTooWeak: string;
      passwordsDontMatch: string;
    };
  };
  verifyEmail: {
    title: string;
    description: string;
    resendButton: string;
    sending: string;
    success: string;
    errors: {
      invalidToken: string;
      alreadyVerified: string;
      tooManyAttempts: string;
    };
  };
}

export const translations = {
  en,
  tr
} as const;

export const getTranslations = (language: 'en' | 'tr' = 'en') => {
  return translations[language];
};
