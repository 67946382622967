const translations = {
  actions: {
    share: 'Cüzdanı Paylaş',
    viewDetails: 'Detayları Gör'
  },
  stats: {
    income: 'Gelir',
    expenses: 'Gider',
    transactions: {
      one: 'işlem',
      other: 'işlem'
    }
  },
  members: {
    count: 'Üyeler'
  }
};

export default translations;
