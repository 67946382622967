import { SidebarTranslations } from './types';

const translations: SidebarTranslations = {
  appName: 'CostCaddy',
  tagline: 'Manage your finances with ease',
  menu: {
    overview: 'Overview',
    wallets: 'Wallets',
    transactions: 'Transactions',
    reports: 'Reports',
    creditCards: 'Credit Cards',
    subscriptions: 'Subscriptions',
    budgets: 'Budgets',
    settings: 'Settings',
    admin: 'Admin'
  },
  settings: {
    categories: 'Categories',
    labels: 'Labels',
    inviteMembers: 'Invite Members',
    account: 'Account'
  },
  admin: {
    users: 'Users',
    categories: 'Categories',
    labels: 'Labels',
    settings: 'Settings',
    subscriptions: 'Subscriptions',
    emails: 'Emails',
    dashboard: 'Dashboard'
  }
};

// Export both the new translations format and the legacy sidebar format
export { translations };
export const sidebar = translations;
